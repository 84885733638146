import { Visibility, VisibilityOff } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import Loading from 'components/molecules/Loading'
import CenterContainer from 'components/organisms/CenterContainer'
import PageWrapper, {
  AlertProp,
  defaultAlertDefn,
} from 'components/templates/PageWrapper'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from 'services/app'
import firebase from 'services/firebase'
import { getServiceAreaByPostalCode } from 'stores/auth'
import { idTokenAtom, isLoggedInAtom } from 'stores/auth'
import { addUser, getUser, pointsAtom, userAtom } from 'stores/user'
import { RegisterInputs, ServiceAreaType, User } from 'types/AuthTypes'
import { exceptionEvent, signUpEvent } from 'utils/analytics'
import { states } from 'utils/data'
import { getErrorMessage } from 'utils/fireBaseErrorCodes'
import { getRegex } from 'utils/helpers'

const ExternalAnchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  '&:hover,&:focus': { outline: 'none', textDecoration: 'underline' },
}))

const Register = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RegisterInputs>()
  const [alert, setAlert] = useState<AlertProp>(defaultAlertDefn)
  const [registerloading, setRegisterLoading] = useState<boolean>(false)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(Boolean)
  const [serviceArea, setServiceArea] = useState<{
    clientId: string
    serviceAreaId: string
  } | null>(null)
  const [, setisLoggedIn] = useAtom(isLoggedInAtom)
  const [, setUser] = useAtom(userAtom)
  const [, setPointBalance] = useAtom(pointsAtom)
  const [, setIdToken] = useAtom(idTokenAtom)
  const navigate = useNavigate()

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const onSubmit: SubmitHandler<RegisterInputs> = async data => {
    if (serviceArea) {
      setRegisterLoading(true)
      // 1. firebase register
      await firebase
        .createAccount(data.loginEmail, data.password)
        .then(async userCredential => {
          if (
            userCredential &&
            userCredential.user &&
            userCredential.user.uid
          ) {
            // 2. RB Register
            await addUser(userCredential.user.uid, {
              loginEmail: data.loginEmail,
              firstName: data.firstName,
              lastName: data.lastName,
              houseNumber: data.houseNumber,
              street: data.street,
              city: data.city,
              stateProvince: data.stateProvince,
              postalCode: data.postalCode,
              country: data.country,
              clientId: serviceArea.clientId,
              serviceAreaId: serviceArea.serviceAreaId,
              dateAgreedToTos: data.acceptance ? new Date().toISOString() : '',
            })
              .then(async () => {
                // Analytics
                signUpEvent(
                  data.stateProvince,
                  data.postalCode,
                  data.country,
                  serviceArea.clientId,
                  serviceArea.serviceAreaId,
                  'emailAndPassword'
                )

                setRegisterLoading(false)
                setUserLoading(true)

                // 3. re-authenticate required to update the token with roles and other details from API
                await firebase
                  .reauthenticate(data.password)
                  .then(async userCredentialUpdated => {
                    await userCredentialUpdated.user
                      .getIdToken()
                      .then(idToken => {
                        setisLoggedIn(true)
                        setIdToken(idToken)
                        axiosInstance.defaults.headers.common[
                          'Authorization'
                        ] = `Bearer ${idToken}`

                        // 4.Get current user details
                        getUser(userCredentialUpdated.user.uid)
                          .then((response: AxiosResponse<User>) => {
                            // Set user current point balance
                            setPointBalance(response.data.pointBalance)
                            // Update user
                            setUser(response.data)
                            setUserLoading(false)
                            navigate('/dashboard')
                          })
                          .catch(() => {
                            setUserLoading(false)
                            // IF failed in getting user info then logout the user
                            firebase.signOut()
                          })
                      })
                  })
                  .catch(error => {
                    setUserLoading(false)
                    Promise.reject(error)
                    navigate('/login')
                  })

                setAlert({
                  show: true,
                  message: 'Successfully Registered.',
                  type: 'success',
                })
              })
              .catch(error => {
                setRegisterLoading(false)
                // If adding user to DB failed then delete the added user from firebase
                firebase
                  .deleteUser()
                  .then(() => {
                    // User deleted sucessfully
                    // window.location.href = '/register'
                    // return Promise.reject(error)
                  })
                  .catch(error => Promise.reject(error))
                Promise.reject(error)
              })
          }
        })
        .catch(error => {
          setRegisterLoading(false)
          exceptionEvent(error)
          setAlert({
            show: true,
            message: error.code ? getErrorMessage(error.code) : error,
            type: 'error',
          })
        })
    }
  }

  const checkServiceAreaSupport = async (value: string): Promise<any> => {
    let valid = false
    const postalCodeRegex = new RegExp(getRegex('postalCode'))

    if (postalCodeRegex.test(value)) {
      await getServiceAreaByPostalCode(value)
        .then((response: AxiosRequestConfig<ServiceAreaType>) => {
          if (
            response?.data &&
            response?.data.clientId &&
            response?.data.serviceAreaId
          ) {
            setServiceArea({
              clientId: response.data.clientId,
              serviceAreaId: response.data.serviceAreaId,
            })
          }

          valid = true
        })
        .catch(() => {
          setServiceArea(null)
          valid = false
        })

      return valid
        ? valid
        : "Points and rewards for recycling and taking other sustainable actions are now only available for residents in partnering municipalities. For more focused eco-friendly content and products, please visit <a rel='noreferrer' href='https://www.Zerowaste.com' target='_blank'>Zerowaste.com</a>"
    }
  }

  const checkPasswordStrength = async (password: string): Promise<any> => {
    // Firebase has weak password check
    let strength = 0
    if (password.match(/[a-z]+/)) strength += 1
    if (password.match(/[A-Z]+/)) strength += 1
    if (password.match(/[0-9]+/)) strength += 1
    if (password.match(/[$@#&!]+/)) strength += 1

    return strength >= 3 ? true : 'Password is too weak'
  }

  if (registerloading || userLoading) {
    return (
      <Loading
        message={registerloading ? 'REGISTRATION IN PROGRESS...' : 'LOADING...'}
      />
    )
  }

  return (
    <PageWrapper alert={alert}>
      <CenterContainer
        back={{ text: 'back to home', path: '/' }}
        text="sign up and start saving!"
        contentCols={{ xs: 12, sm: 11, md: 7.5, lg: 5.4 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={{ xs: 4, sm: 4, md: 5 }}
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                size="small"
                autoFocus
                {...register('firstName', {
                  required: 'First Name is Required.',
                  pattern: {
                    value: getRegex('name'),
                    message: 'Invalid First Name.',
                  },
                })}
                inputProps={{
                  style: { fontSize: '0.85rem', paddingBottom: '0.9rem' },
                }}
                error={errors?.firstName ? true : false}
                helperText={errors?.firstName ? errors.firstName.message : ' '}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                size="small"
                {...register('lastName', {
                  required: 'Last Name is Required.',
                  pattern: {
                    value: getRegex('name'),
                    message: 'Invalid Last Name.',
                  },
                })}
                error={errors?.lastName ? true : false}
                helperText={errors?.lastName ? errors.lastName.message : ' '}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email Address"
                size="small"
                {...register('loginEmail', {
                  required: 'Email Address is Required.',
                  pattern: {
                    value: getRegex('email'),
                    message: 'Invalid Email Address.',
                  },
                })}
                error={errors?.loginEmail ? true : false}
                helperText={
                  errors?.loginEmail ? errors.loginEmail.message : ' '
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  htmlFor="password"
                  sx={{
                    '&:not(.MuiInputLabel-shrink):not(.Mui-focused)': {
                      top: '-0.5rem',
                    },
                  }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="password"
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  {...register('password', {
                    required: 'Password is Required.',
                    minLength: {
                      value: 6,
                      message: 'At least 6 characters required.',
                    },
                    validate: checkPasswordStrength,
                  })}
                  error={errors?.password ? true : false}
                  label="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(): void => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        color="primary"
                        sx={{ '& svg': { fontSize: '1rem' } }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  id="passsword-helper-text"
                  error={errors?.password ? true : false}
                >
                  {errors?.password ? errors.password.message : ' '}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: { xs: 2, md: 0 } }}>
              <TextField
                fullWidth
                label="House Number"
                size="small"
                type="text"
                {...register('houseNumber', {
                  required: 'House Number is Required.',
                  pattern: {
                    value: getRegex('houseNumber'),
                    message: 'Invalid House Number.',
                  },
                })}
                placeholder="Address"
                error={errors?.houseNumber ? true : false}
                helperText={
                  errors?.houseNumber ? errors.houseNumber.message : ' '
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Street"
                size="small"
                type="text"
                {...register('street', {
                  required: 'Street is Required.',
                  pattern: {
                    value: getRegex('address'),
                    message: 'Invalid Street.',
                  },
                })}
                placeholder="Street"
                error={errors?.street ? true : false}
                helperText={errors?.street ? errors.street.message : ' '}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                size="small"
                type="text"
                {...register('city', {
                  required: 'City is Required.',
                  pattern: {
                    value: getRegex('city'),
                    message: 'Invalid City.',
                  },
                })}
                placeholder="City"
                error={errors?.city ? true : false}
                helperText={errors?.city ? errors.city.message : ' '}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="State"
                size="small"
                select
                defaultValue=""
                {...register('stateProvince', {
                  required: 'State is Required.',
                })}
                placeholder="State"
                error={errors?.stateProvince ? true : false}
                helperText={
                  errors?.stateProvince ? errors.stateProvince.message : ' '
                }
              >
                {states.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Postal Code"
                type="text"
                {...register('postalCode', {
                  required: 'Postal Code is Required.',
                  pattern: {
                    value: getRegex('postalCode'),
                    message: 'Invalid Postal Code.',
                  },
                  validate: checkServiceAreaSupport,
                  onBlur: e => checkServiceAreaSupport(e.target.value),
                })}
                placeholder="XXXXX-XXXX"
                error={errors?.postalCode ? true : false}
                helperText={
                  errors?.postalCode && errors?.postalCode.type !== 'validate'
                    ? errors.postalCode.message
                    : ' '
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Country"
                size="small"
                {...register('country', {
                  value: 'USA',
                  required: 'Country is Required.',
                  pattern: {
                    value: getRegex('country'),
                    message: 'Invalid Country.',
                  },
                })}
                value="USA"
                placeholder="Country"
                error={errors?.country ? true : false}
                helperText={errors?.country ? errors.country.message : ' '}
              >
                {[
                  {
                    value: 'USA',
                    label: 'United States',
                    selected: true,
                  },
                ].map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {errors?.postalCode && errors?.postalCode.type === 'validate' ? (
              <Grid item xs={12} sx={{ pt: '0!important' }}>
                <FormHelperText
                  sx={{
                    color: 'black',
                    '& a': {
                      textDecoration: 'none',
                      color: '#008F6B',
                      '&:hover,&:focus': {
                        outline: 'none',
                        textDecoration: 'underline',
                      },
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: errors.postalCode.message
                      ? errors.postalCode.message
                      : ' ',
                  }}
                />
              </Grid>
            ) : (
              ''
            )}

            <Grid item xs={12}>
              <Grid container direction="column" spacing={0.5}>
                <Grid item>
                  <Controller
                    name="acceptance"
                    control={control}
                    defaultValue={false}
                    rules={{ required: true }}
                    render={({ field }): JSX.Element => (
                      <FormControlLabel
                        componentsProps={{
                          typography: {
                            color: errors?.acceptance ? 'error' : 'unset',
                          },
                        }}
                        control={<Checkbox {...field} color="primary" />}
                        label={
                          <Typography sx={{ fontSize: '0.95rem' }}>
                            <span>I have read and agreed to the </span>
                            <ExternalAnchor
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.rts.com/terms/"
                            >
                              terms
                            </ExternalAnchor>
                            <span> and </span>
                            <ExternalAnchor
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.rts.com/privacy/"
                            >
                              privacy policy
                            </ExternalAnchor>
                            .
                          </Typography>
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Grid item xs={12} md={3}>
                  <LoadingButton
                    fullWidth
                    loading={registerloading}
                    variant="contained"
                    type="submit"
                    sx={{ my: { xs: 2, md: 0 } }}
                  >
                    Sign Up
                  </LoadingButton>
                </Grid>
                {/* <Grid item xs={12} md={1}>
                  <Typography align="center">- OR -</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    spacing={0.5}
                    justifyContent="center"
                    direction="column"
                  >
                    <Grid item>
                      <SocialButtonGoogle
                        fullWidth
                        variant="contained"
                        onClick={signInWithGoogle}
                        startIcon={<Google />}
                        sx={{ mt: { xs: 1, md: 0 } }}
                      >
                        Sign In with Google
                      </SocialButtonGoogle>

                      <SocialButtonFacebook
                        fullWidth
                        variant="contained"
                        onClick={signInWithGoogle}
                        startIcon={<Facebook />}
                        sx={{ mt: 2 }}
                      >
                        Sign in with facebook
                      </SocialButtonFacebook>

                      <SocialButtonTwitter
                        fullWidth
                        variant="contained"
                        onClick={signInWithGoogle}
                        startIcon={<Twitter />}
                        sx={{ mt: 2 }}
                      >
                        Sign in with twitter
                      </SocialButtonTwitter>
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <Typography
                    align="center"
                    fontSize="0.7rem"
                    sx={{ mt: { xs: 2, md: 0 } }}
                  >
                    Already a member? <br />
                  </Typography>
                  <Typography align="center">
                    <Link
                      href="/login"
                      underline="none"
                      textTransform="uppercase"
                    >
                      sign In now
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CenterContainer>
    </PageWrapper>
  )
}

export default Register
