import { AxiosResponse } from 'axios'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import AppService from 'services/app'
import firebase from 'services/firebase'

const storage = createJSONStorage(() => sessionStorage)
export const idTokenAtom = atomWithStorage<string | any>(
  'idToken',
  null,
  storage
)
export const isLoggedInAtom = atomWithStorage('isLoggedIn', false, storage)

export const logout = async (path = '/', message?: string): Promise<void> => {
  firebase.signOut().then(() => {
    localStorage.clear()
    sessionStorage.clear()
    if (typeof window !== undefined)
      window.location.href = message ? `${path}?msg=${message}` : path
  })
}

// Client
export const getServiceAreaByPostalCode = async (
  params: any
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Client.getData(
      'serviceAreaByPostalCode',
      params,
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
