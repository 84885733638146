import { Alert } from '@mui/material'
import ContentCard from 'components/molecules/ContentCard'
import { BaseContentItemType } from 'types/BaseContentTypes'
import { GenericContentItemType } from 'types/ContentListTypes'
import { dateFormat } from 'utils/date'

interface Props {
  type: 'Article' | 'Flipcard' | 'Video'
  data: GenericContentItemType[]
}

const ContentTypeList = ({ type, data }: Props): JSX.Element => {
  return (
    <div className={`rb_${type}_list`}>
      {data.map(item => {
        const baseContentItem: BaseContentItemType =
          item.linkedFrom.baseContentCollection.items[0]
        return baseContentItem ? (
          <ContentCard
            type={type}
            key={item.sys.id}
            id={item.sys.id}
            featuredImage={baseContentItem.image.url}
            heading={baseContentItem.title}
            shortText={baseContentItem.abstract}
            tags={baseContentItem?.contentfulMetadata?.tags}
            whom="Admin"
            date={dateFormat(
              baseContentItem.publishDay,
              baseContentItem.publishMonth,
              baseContentItem.publishYear
            )}
            more={`${baseContentItem.slug.substr(1)}?id=${item.sys.id}`}
            points={baseContentItem.points}
          />
        ) : (
          <Alert
            key={item.sys.id}
            severity="warning"
            sx={{ mb: 2, fontSize: '0.8rem' }}
          >
            Unable to display content <strong>{item.sys.id}</strong>.
          </Alert>
        )
      })}
    </div>
  )
}

export default ContentTypeList
