import { Alert } from '@mui/material'
import ReactPlayer from 'react-player'

interface ProgressProps {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

interface VideoPlayerProps {
  videoId: string
  onReady?: (player: ReactPlayer) => void
  onError?: (error: any) => void
  onPause?: () => void
  onStart?: () => void
  onSleek?: (seconds: number) => void
  onProgress?: (state: ProgressProps) => void
  onEnded?: () => void
  onPlay?: () => void
}

const VideoPlayer = ({
  videoId,
  onReady,
  onError,
  onSleek,
  onStart,
  onEnded,
  onPlay,
  onProgress,
  onPause,
}: VideoPlayerProps): JSX.Element => {
  const URL = `http://www.youtube.com/watch?v=${videoId}`
  if (!ReactPlayer.canPlay(URL)) {
    // If url is not playable
    return <Alert severity="error">Unable to play this video.</Alert>
  }

  const handleReady = (player: ReactPlayer): void => {
    if (onReady) onReady(player)
  }

  const handleProgress = (state: ProgressProps): void => {
    if (onProgress) onProgress(state)
  }

  const handleError = (error: any): void => {
    if (onError) onError(error)
  }

  const handleSeek = (seconds: number): void => {
    if (onSleek) onSleek(seconds)
  }

  const handleStart = (): void => {
    if (onStart) onStart()
  }

  const handleEnded = (): void => {
    if (onEnded) onEnded()
  }

  const handlePlay = (): void => {
    if (onPlay) onPlay()
  }

  const handlePause = (): void => {
    if (onPause) onPause()
  }

  return (
    <ReactPlayer
      className="react-player"
      url={URL}
      width="100%"
      height="100%"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      config={{
        youtube: {
          playerVars: { showinfo: 1 },
        },
      }}
      controls={true}
      light={true}
      onReady={(player: ReactPlayer): void => handleReady(player)}
      onStart={handleStart}
      onPlay={handlePlay}
      onPause={handlePause}
      onSeek={(seconds: number): void => handleSeek(seconds)}
      onEnded={handleEnded}
      onError={(error: any): void => handleError(error)}
      onProgress={(state): void => handleProgress(state)}
    />
  )
}

export default VideoPlayer
