import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import { alpha, styled, useTheme } from '@mui/material/styles'
import congrats from 'assets/img/congrats.svg'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Img = styled('img')({
  margin: '0',
  display: 'block',
  maxWidth: '70%',
  maxHeight: '100%',
  objectFit: 'contain',
})

interface Props {
  onClose?: () => void
  onSubmit: () => void
  open: boolean
  children: ReactNode
}
const CongratsDialog = ({
  onClose,
  onSubmit,
  open = false,
  children,
}: Props): JSX.Element => {
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const handleClose = (): void => {
    setDialogOpen(false)
    if (onClose) {
      onClose()
    }
  }
  const navigate = useNavigate()

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="congrats-dialog-title"
      aria-describedby="congrats-dialog-description"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
    >
      <DialogContent
        sx={{
          p: 0,
          background: '#e4efec',
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 2,
          }}
        >
          <HighlightOffSharpIcon sx={{ fontSize: '1.25rem' }} />
        </IconButton>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Img src={congrats} alt="Congrats" />
          <Typography
            variant="body2"
            sx={{
              lineHeight: 1.5,
              textAlign: 'center',
              px: 8,
            }}
          >
            {children}
            <br />
            Click on your profile at the top right of the window to view and
            redeem your Rewards!
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          disableElevation
          onClick={onSubmit}
        >
          CONTINUE SHOPPING
        </Button>
        <Button
          type="button"
          variant="contained"
          disableElevation
          onClick={(): void => {
            navigate('/dashboard/settings')
          }}
        >
          Go to My Rewards
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CongratsDialog
