import { gql } from '@apollo/client'

export const HOME_CONTENT = gql`
  query GetHomeSlides($isSlide: Boolean!) {
    homeCollection(order: id_ASC, where: { isSlide: $isSlide }) {
      items {
        id
        title
        subtitle
        description
        image {
          url
        }
        isSlide
        links
        bckImgPosition
        alignContent
        contentBckColor
        contentShadow
        contentWidth
        contentColor
      }
    }
  }
`
