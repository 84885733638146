import AccessDenied from 'components/pages/401'
import Forbidden from 'components/pages/403'
import NotFound from 'components/pages/404'
import BulkFiles from 'components/pages/Admin/BulkFiles'
import Points from 'components/pages/Admin/Points'
import AdminRewards from 'components/pages/Admin/Rewards'
import Users from 'components/pages/Admin/Users'
import Contact from 'components/pages/Contact'
import Dashboard from 'components/pages/Dashboard'
import ContentDetails from 'components/pages/Dashboard/ContentDetailsContainer'
import ContentList from 'components/pages/Dashboard/ContentListContainer'
import EarnPoints from 'components/pages/EarnPoints/EarnPoints'
// import EcoLibrary from 'components/pages/EcoLibrary'
import Home from 'components/pages/Home'
import Login from 'components/pages/Login'
import Register from 'components/pages/Register'
import Reset from 'components/pages/Reset'
import Rewards from 'components/pages/Rewards'
import RewardDetails from 'components/pages/Rewards/RewardDetailsContainer'
import Settings from 'components/pages/Settings'
import TermsAndConditions from 'components/pages/TermsAndConditions'
import { useAtom } from 'jotai'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'
import { Role } from 'stores'
import { idTokenAtom } from 'stores/auth'
import { isUserLoadingAtom } from 'stores/user'
import 'styles/App.scss'
import { pageView } from 'utils/analytics'

const RequireAuth = ({
  children,
  roles,
}: {
  children: JSX.Element
  roles: Array<Role>
}): JSX.Element => {
  const location = useLocation()
  const [idToken] = useAtom(idTokenAtom)

  if (!idToken) {
    localStorage.clear()
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected.
    return <Navigate to="/login" state={{ from: location, redirected: true }} />
  }

  //JWT Decode
  const decodedUser: { user_role: Role } = jwt_decode(idToken)

  if (idToken && decodedUser && !roles.includes(decodedUser.user_role)) {
    return <Navigate to="/401" />
  }

  return children
}

const NoAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [idToken] = useAtom(idTokenAtom)
  const [isUserLoading] = useAtom(isUserLoadingAtom)
  if (idToken) {
    //JWT Decode
    const decodedUser: { user_role: Role } = jwt_decode(idToken)

    // If user already logged in the navigate to dashboard
    if (decodedUser && decodedUser.user_role === 'admin' && !isUserLoading) {
      // If user role is admin then navigate to admin dashboard
      return <Navigate to="/admin" />
    } else if (
      decodedUser &&
      decodedUser.user_role === 'user' &&
      !isUserLoading
    ) {
      return <Navigate to="/dashboard" />
    }
  }

  return children
}

const App = (): JSX.Element => {
  const location = useLocation()
  useEffect(() => {
    if (location) {
      pageView(location.pathname)
    }
  }, [location])

  return (
    <div className="app">
      <Routes>
        {/* unAuthenticate Routes */}
        <Route
          path="/login"
          element={
            <NoAuth>
              <Login />
            </NoAuth>
          }
        />
        <Route
          path="/register"
          element={
            <NoAuth>
              <Register />
            </NoAuth>
          }
        />
        <Route
          path="/reset"
          element={
            <NoAuth>
              <Reset />
            </NoAuth>
          }
        />
        <Route
          path="/"
          element={
            <NoAuth>
              <Home />
            </NoAuth>
          }
        />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/401" element={<AccessDenied />} />
        <Route path="/403" element={<Forbidden />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth roles={[Role.User]}>
              <>
                <Dashboard />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/learnEarn"
          element={
            <RequireAuth roles={[Role.User]}>
              <ContentList />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/earnPoints"
          element={
            <RequireAuth roles={[Role.User]}>
              <EarnPoints />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/article"
          element={
            <RequireAuth roles={[Role.User]}>
              <ContentList type="article" />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/flipcard"
          element={
            <RequireAuth roles={[Role.User]}>
              <ContentList type="flipcard" />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/video"
          element={
            <RequireAuth roles={[Role.User]}>
              <ContentList type="video" />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/:type/:id"
          element={
            <RequireAuth roles={[Role.User]}>
              <ContentDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/learnEarn/:type/:id"
          element={
            <RequireAuth roles={[Role.User]}>
              <ContentDetails />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/dashboard/categories"
          element={
            <RequireAuth roles={[Role.User]}>
                <EcoLibrary />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/dashboard/rewards"
          element={
            <RequireAuth roles={[Role.User]}>
              <Rewards />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/rewards/:id"
          element={
            <RequireAuth roles={[Role.User]}>
              <RewardDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/settings"
          element={
            <RequireAuth roles={[Role.User]}>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/contact"
          element={
            <RequireAuth roles={[Role.User]}>
              <Contact />
            </RequireAuth>
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admin"
          element={
            <RequireAuth roles={[Role.Admin]}>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/points"
          element={
            <RequireAuth roles={[Role.Admin]}>
              <Points />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/rewards"
          element={
            <RequireAuth roles={[Role.Admin]}>
              <AdminRewards />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/bulkfiles"
          element={
            <RequireAuth roles={[Role.Admin]}>
              <BulkFiles />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  )
}

export default App
