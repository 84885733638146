import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilterOptionsState,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import NotificationBar from 'components/atoms/NotificationBar'
import { format, toDate } from 'date-fns-tz'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AlertProp } from 'stores'
import { getActivity } from 'stores/activity'
import { ActivityListItemType } from 'types/UserSettingsTypes'
import { User } from 'types/admin'

const filter = createFilterOptions<ReasonOptionType>()

const dateFormatter = (params: ValueFormatterParams): string => {
  return params.value
    ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
    : 'N/A'
}

const typeFormatter = (params: ValueFormatterParams): string => {
  return params.value ? params.value.replace(/_/g, ' ') : ''
}

interface ReasonOptionType {
  inputValue?: string
  title: string
}

const reasons: readonly ReasonOptionType[] = [
  { title: 'Customer Satisfaction' },
  { title: 'Points added in error to wrong account' },
  { title: 'Reward Redemption error. Reward not fulfilled' },
  { title: 'Points not rewarded for Content' },
  { title: 'Ineligible account' },
]

interface Prop {
  open: boolean
  user: User
  setAlertMsg: AlertProp | null
  onPointsUpdate?: (points: number, reason: string) => void
  onCloseCB?: () => void
}

const EditUserDialog = ({
  open = false,
  user,
  setAlertMsg,
  onPointsUpdate,
  onCloseCB,
}: Prop): JSX.Element => {
  const [reason, setReason] = useState<ReasonOptionType | null>(null)
  const [alert, setAlert] = useState<AlertProp | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [pointBalance, setPointBalance] = useState<number>(0)
  const [pointsHistoryList, setPointsHistoryList] = useState<
    ActivityListItemType[]
  >([])
  const [rewardsHistoryList, setRewardsHistoryList] = useState<
    ActivityListItemType[]
  >([])
  const [expanded, setExpanded] = useState<string | false>('addPoints')
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{ points: number; reason: string }>()

  const handleClose = (): void => {
    setDialogOpen(false)
    if (onCloseCB) onCloseCB()
  }

  useEffect(() => {
    setDialogOpen(open)
    if (open) {
      getUserPointsHistory()
      getUserRewardsHistory()
      setPointBalance(user.pointBalance)
    }
  }, [open, user])

  useEffect(() => {
    if (setAlertMsg) {
      setAlert(setAlertMsg)
      if (setAlertMsg.type === 'success') {
        // if its a success then clear the form
        reset()
        setReason(null)
      }
    }
  }, [setAlertMsg])

  const [pointsColDefs] = useState<ColDef[]>([
    {
      field: 'title',
      filter: true,
      flex: 1,
      suppressSizeToFit: true,
    },
    {
      field: 'contentType',
      filter: true,
      suppressSizeToFit: true,
      width: 140,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'points',
      filter: true,
      width: 100,
    },
    {
      field: 'createdDate',
      filter: true,
      valueFormatter: dateFormatter,
      sort: 'desc',
    },
  ])

  const [rewardsColDefs] = useState<ColDef[]>([
    {
      field: 'title',
      filter: true,
      suppressSizeToFit: true,
      minWidth: 300,
    },
    {
      field: 'rewardType',
      filter: true,
      width: 150,
      valueFormatter: typeFormatter,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'points',
      filter: true,
      width: 100,
    },
    {
      field: 'createdDate',
      filter: true,
      valueFormatter: dateFormatter,
      sort: 'desc',
    },
    {
      headerName: 'Reward FulFillment Id',
      field: 'rewardFulFillmentId',
      filter: true,
      width: 303,
    },
    { field: 'rewardCode', filter: true, width: 150 },
    { field: 'rewardId', filter: true, width: 303 },
  ])

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  )

  const onReasonChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | ReasonOptionType | null
  ): void => {
    if (typeof newValue === 'string') {
      setReason({
        title: newValue,
      })

      setValue('reason', newValue)
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setReason({
        title: newValue.inputValue,
      })
      setValue('reason', newValue.inputValue)
    } else {
      setReason(newValue)
      setValue('reason', '')
    }
  }

  const getUserPointsHistory = (): void => {
    getActivity(
      user.userId,
      undefined,
      undefined,
      'points',
      undefined,
      undefined,
      undefined,
      true
    )
      .then(response => {
        setPointsHistoryList(response.data.pointHistoryResponses)
      })
      .catch(error => {
        if (error.response.status !== 404) {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to fetch user points history. Try again.',
          })
        }
      })
  }

  const getUserRewardsHistory = (): void => {
    getActivity(
      user.userId,
      undefined,
      undefined,
      'rewards',
      undefined,
      undefined,
      undefined,
      true
    )
      .then(response => {
        setRewardsHistoryList(response.data.pointHistoryResponses)
      })
      .catch(error => {
        if (error.response.status !== 404) {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to fetch user rewards history. Try again.',
          })
        }
      })
  }

  const onSubmit: SubmitHandler<{ points: number; reason: string }> = ({
    points,
    reason,
  }) => {
    if (onPointsUpdate) {
      onPointsUpdate(points, reason)
    }
  }

  const handleNotificationClose = (): void => {
    setAlert(null)
  }

  const getReasonOptionLabel = (option: string | ReasonOptionType): string => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    // Add "any" reason dynamically
    if (option.inputValue) {
      return option.inputValue
    }
    // Regular option
    return option.title
  }

  const filterReasonOptions = (
    options: ReasonOptionType[],
    params: FilterOptionsState<ReasonOptionType>
  ): ReasonOptionType[] => {
    const filtered = filter(options, params)

    const { inputValue } = params
    // Suggest the creation of a new value
    const isExisting = options.some(option => inputValue === option.title)
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        title: `Other "${inputValue}"`,
      })
    }

    return filtered
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="Joining Greetings"
      className="rb-joining-greet-dialog"
      maxWidth="md"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
    >
      <DialogTitle sx={{ fontSize: '1rem !important' }}>
        Edit User: {user.firstName} {user.lastName}
      </DialogTitle>
      <DialogContent>
        <Accordion
          sx={{ border: '1px solid #ccc' }}
          elevation={0}
          expanded={expanded === 'addPoints'}
          onChange={handleChange('addPoints')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Add Points</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <>
                    <TextField
                      fullWidth
                      required
                      label="Points"
                      size="small"
                      type="number"
                      {...register('points', {
                        required: 'Points is required ',
                        pattern: {
                          value: /^(?:(?:-?(?:[1-9]\d*)?))$/g,
                          message: 'Not a valid point.',
                        },
                        min: {
                          value: -pointBalance,
                          message: `Negative Points cant be less than ${pointBalance}`,
                        },
                      })}
                      inputProps={{ min: -pointBalance }}
                      placeholder="0"
                      error={errors?.points ? true : false}
                      helperText={
                        errors?.points && errors.points.message
                          ? String(errors.points.message)
                          : `Available points ${pointBalance}`
                      }
                    />
                  </>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={reason}
                    onChange={onReasonChange}
                    filterOptions={filterReasonOptions}
                    options={reasons}
                    getOptionLabel={getReasonOptionLabel}
                    renderOption={(props, option): JSX.Element => (
                      <li {...props} style={{ fontSize: '0.9rem' }}>
                        {option.title}
                      </li>
                    )}
                    freeSolo
                    renderInput={(params): JSX.Element => (
                      <TextField
                        required
                        label="Select/Add Reason"
                        {...params}
                        {...register('reason', {
                          required: 'Reason is Required ',
                        })}
                        error={errors?.reason ? true : false}
                        helperText={
                          errors?.reason
                            ? errors.reason.message
                            : 'Select from existing canned response or add new.'
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ mt: '0.3rem' }}
                  >
                    Update Points
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ border: '1px solid #ccc' }}
          elevation={0}
          expanded={expanded === 'points'}
          onChange={handleChange('points')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="points-content"
            id="points-header"
          >
            <Typography>Points History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className="ag-theme-alpine"
              style={{ width: '100%', height: 365 }}
            >
              <AgGridReact
                rowData={pointsHistoryList}
                columnDefs={pointsColDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                pagination={true}
                paginationPageSize={10}
                gridOptions={{
                  suppressCellFocus: true,
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ border: '1px solid #ccc' }}
          elevation={0}
          expanded={expanded === 'rewards'}
          onChange={handleChange('rewards')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="rewards-content"
            id="rewards-header"
          >
            <Typography>Rewards History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className="ag-theme-alpine"
              style={{ width: '100%', height: 365 }}
            >
              <AgGridReact
                rowData={rewardsHistoryList}
                columnDefs={rewardsColDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                pagination={true}
                paginationPageSize={10}
                gridOptions={{
                  suppressCellFocus: true,
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      {alert !== null ? (
        <NotificationBar
          open={alert.show}
          message={alert.message}
          type={alert.type}
          autoHideDuration={alert.autoHideDuration || null}
          onCloseCB={handleNotificationClose}
        />
      ) : null}
    </Dialog>
  )
}

export default EditUserDialog
