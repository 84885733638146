import { Box } from '@mui/material'
import NotificationBar from 'components/atoms/NotificationBar'
import Footer from 'components/molecules/Footer'
import Header from 'components/molecules/HeaderPlain'
import { ReactNode, useEffect, useState } from 'react'

export interface AlertProp {
  show: boolean
  message: string
  type: 'error' | 'success' | 'info' | 'warning'
}

export const defaultAlertDefn: AlertProp = {
  show: false,
  message: '',
  type: 'error',
}

const PageWrapper = ({
  alert,
  children,
  hasFooter = false,
  isPrimary = false,
  headerElevation = 0,
}: {
  alert?: AlertProp | undefined
  children: ReactNode
  hasFooter?: boolean
  isPrimary?: boolean
  headerElevation?: number
}): JSX.Element => {
  const [alertNotification, setAlertNotification] =
    useState<AlertProp>(defaultAlertDefn)

  const handleNotificationClose = (): void => {
    setAlertNotification(defaultAlertDefn)
  }

  useEffect(() => {
    if (alert) setAlertNotification(alert)
  }, [alert])

  return (
    <Box component="main">
      <Header isPrimary={isPrimary} elevation={headerElevation} />
      <Box component="section">
        <NotificationBar
          open={alertNotification.show}
          message={alertNotification.message}
          onCloseCB={handleNotificationClose}
          type={alertNotification.type}
        />
        {children}
      </Box>
      {hasFooter ? <Footer /> : null}
    </Box>
  )
}

export default PageWrapper
