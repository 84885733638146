import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AdminClientPointsInputs, ClientResponse } from 'types/ClientTypes'

interface Props {
  clientsList: ClientResponse[]
  onUpdate: (data: AdminClientPointsInputs) => void
}

const AdminClientPoints = ({ clientsList, onUpdate }: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AdminClientPointsInputs>({ mode: 'onChange' })

  const onSubmit: SubmitHandler<AdminClientPointsInputs> = data => {
    onUpdate(data)
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Typography
        variant="overline"
        paragraph
        sx={{ color: 'rgba(0, 0, 0, 0.6)', mb: 3 }}
      >
        Add Points to all users belonging to a Client
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <TextField
            fullWidth
            label="Client"
            size="small"
            required
            select
            defaultValue={''}
            {...register('clientId', {
              required: 'Client is Required ',
            })}
            placeholder="Client"
            error={errors?.clientId ? true : false}
            helperText={
              errors?.clientId && errors.clientId.message
                ? String(errors.clientId.message)
                : ''
            }
          >
            {clientsList.map(client => (
              <MenuItem key={client.clientId} value={client.clientId}>
                {client.clientName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2.2} lg={2}>
          <TextField
            fullWidth
            required
            label="Points"
            size="small"
            type="number"
            {...register('points', {
              required: 'Points is required ',
              min: {
                value: 1,
                message: 'Atleast 1 point is required.',
              },
            })}
            placeholder="0"
            error={errors?.points ? true : false}
            helperText={
              errors?.points && errors.points.message
                ? String(errors.points.message)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid}
            sx={{ mt: '0.3rem' }}
          >
            Add Points
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdminClientPoints
