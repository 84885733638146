import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'

interface FlipCardProps {
  flipDirection?: 'horizontal' | 'vertical'
  flipOnHover?: boolean
  flipOnClick?: boolean
  isFlipped?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onTouchStart?: () => void
  onClick?: (e: React.MouseEvent, isFlipped: boolean) => void
  children: React.ReactNode[]
  sx?: SxProps<Theme>
}

export const FlipCard = ({
  isFlipped: _isFlipped,
  flipDirection = 'horizontal',
  flipOnHover = false,
  flipOnClick = true,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
  onClick,
  children,
  sx = [],
}: FlipCardProps): JSX.Element => {
  const simpleFlag = useRef({ isTouchDevice: false })
  const [isTouchDevice, setTouchDevice] = useState(false)
  const [isFlipped, setFlipped] = useState(false)

  const handleTouchStart = (): void => {
    if (!isTouchDevice) {
      simpleFlag.current.isTouchDevice = true
      setTouchDevice(true)
    }
    if (onTouchStart) onTouchStart()
  }

  const handleMouseEnter = (): void => {
    if (flipOnHover && !simpleFlag.current.isTouchDevice) {
      setFlipped(true)
    }
    if (onMouseEnter) onMouseEnter()
  }

  const handleMouseLeave = (): void => {
    if (flipOnHover && !simpleFlag.current.isTouchDevice) {
      setFlipped(false)
    }
    if (onMouseLeave) onMouseLeave()
  }

  const handleClick = (e: React.MouseEvent): void => {
    switch (true) {
      case flipOnHover && !simpleFlag.current.isTouchDevice:
      case !flipOnClick && !flipOnHover:
        break
      default:
        setFlipped(!isFlipped)
        break
    }
    if (onClick) onClick(e, !isFlipped)
  }

  useEffect(() => {
    if (typeof _isFlipped === 'boolean' && _isFlipped !== isFlipped) {
      setFlipped(_isFlipped)
    }
  }, [_isFlipped])

  return (
    <Box
      className="flipcard-container"
      onTouchStart={(): void => handleTouchStart()}
      onMouseEnter={(): void => handleMouseEnter()}
      onMouseLeave={(): void => handleMouseLeave()}
      onClick={(e: React.MouseEvent): void => handleClick(e)}
      sx={[
        {
          width: '100%',
          height: 'auto',
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="div"
    >
      <Box
        className={`flipcard-cardContainer-wrapper ${flipDirection}`}
        component="div"
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          className={`flipcard-cardContainer ${isFlipped ? 'isActive' : ''} ${
            isTouchDevice ? 'istouchdevice' : ''
          }`}
          component="div"
          sx={{
            height: '100%',
            maxHeight: '43.75rem',
            width: '100%',
            position: 'relative',
            transition: '.6s',
            transform: 'perspective(1000px)',
            transformStyle: 'preserve-3d',
            '& .flipcard-front': {
              transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
            },
            '& .flipcard-back': {
              transform: isFlipped ? 'rotateY(0deg)' : 'rotateY(-180deg)',
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

const cardFront = {
  transform: 'rotateY(0deg)',
}

const cardBack = {
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'rotateY(-180deg)',
}

const Card = ({
  type,
  animationDuration = 600,
  sx = [],
  children,
}: {
  type: string
  animationDuration?: number
  children: React.ReactNode
  sx?: SxProps<Theme>
}): JSX.Element => {
  return (
    <Box
      className={`flipcard-card flipcard-${type}`}
      sx={[
        {
          transitionDuration: `${animationDuration / 1000}s`,
          position: 'relative',
          width: '100%',
          height: '100%',
          backfaceVisibility: 'hidden',
          transformStyle: 'preserve-3d',
        },
        ...[type === 'front' ? cardFront : cardBack],
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  )
}

export const FrontSide = ({
  animationDuration = 600,
  sx = [],
  children,
}: {
  animationDuration?: number
  children: React.ReactNode
  sx?: SxProps<Theme>
}): JSX.Element => {
  return (
    <Card animationDuration={animationDuration} type="front" sx={sx}>
      {children}
    </Card>
  )
}

export const BackSide = ({
  sx = [],
  children,
}: {
  children: React.ReactNode
  sx?: SxProps<Theme>
}): JSX.Element => {
  return (
    <Card type="back" sx={sx}>
      {children}
    </Card>
  )
}
