import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Button, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SlideBox = ({
  first = false,
  backgroundImage,
  alignItems = 'start',
  title,
  subTitle,
  description,
  contentWidth = 600,
  contentHeight = 'auto',
  contentBckColor = 'transparent',
  contentShadow = 0,
  contentColor = 'black',
  backgroundPosition,
}: any): JSX.Element => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: 'url(' + backgroundImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: { xs: backgroundPosition.xs, md: 'center' },
        pt: first ? 8 : 0,
        display: 'flex',
        justifyContent: { xs: 'center', md: alignItems },
        alignItems: 'center',
        px: { xs: 2, md: '10%' },
      }}
    >
      <Paper
        sx={{
          width: { md: contentWidth },
          height: contentHeight,
          backgroundColor: {
            md: contentBckColor,
            xs:
              contentBckColor === 'rgba(255,255,255,0)'
                ? 'rgb(255 255 255 / 70%);'
                : contentBckColor,
          },
          p: 2,
          maxHeight: '80vh',
        }}
        elevation={contentShadow}
      >
        <Typography
          component="h1"
          sx={{
            align: 'left',
            fontWeight: '600',
            fontSize: '2.6rem',
            color: '#0c1c2a',
          }}
          color={contentColor}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={{
            align: 'left',
            fontFamily: 'CircularXX',
            fontSize: '1.4em',
            fontWeight: 100,
            mt: 4,
          }}
          color={contentColor}
        >
          {subTitle}
        </Typography>

        <Typography
          variant="body2"
          align="left"
          color={contentColor}
          fontSize="0.8em"
          paragraph
        >
          {description}
        </Typography>
        <Button
          variant="contained"
          onClick={(): void => navigate('/login')}
          disableElevation
          sx={{
            backgroundColor: '#005E85 ',
            borderRadius: '2.75rem',
            color: '#F0F5FB',
            fontSize: '0.75rem',
            py: '0.4rem',
            px: '1.5rem',
            mt: 4,
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#004460',
            },
            '&:active': {
              backgroundColor: '#004460',
            },
          }}
          endIcon={<ArrowRightAltIcon />}
        >
          Get Started
        </Button>
      </Paper>
    </Box>
  )
}

export default SlideBox
