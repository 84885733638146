import EditIcon from '@mui/icons-material/Edit'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import CodeTypeUpdateDialog from 'components/molecules/CodeTypeUpdateDialog'
import MultipleCouponUpdateDialog from 'components/molecules/MultipleCouponUpdateDialog'
import SingleCouponUpdateDialog from 'components/molecules/SingleCouponUpdateDialog'
import UpdateCouponQuantityDialog from 'components/molecules/UpdateCouponQuantityDialog'
import AdminPageWrapper from 'components/templates/AdminPageWrapper'
import { format, toDate } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { alertAtom } from 'stores'
import {
  addMultipleCouponCodes,
  addSingleCouponCode,
  getAllRewards,
  updateReward,
} from 'stores/admin'
import { Reward } from 'types/admin'
import { getRegex } from 'utils/helpers'

const Rewards = (): JSX.Element => {
  const [rewardsLoading, setRewardsLoading] = useState<boolean>(false)
  const [rewardsRowData, setRewardsRowData] = useState<any[] | undefined>(
    undefined
  )
  const [rewardsInView, setRewardsInView] = useState<Reward | undefined>(
    undefined
  )
  const [, setAlert] = useAtom(alertAtom)
  const [showCodeTypeUpdateDialog, setShowCodeTypeUpdateDialog] =
    useState(false)
  const [showQuantityUpdateDialog, setShowQuantityUpdateDialog] =
    useState(false)
  const [showSingleCouponUpdateDialog, setShowSingleCouponUpdateDialog] =
    useState(false)
  const [showMultipleCouponUpdateDialog, setShowMultipleCouponUpdateDialog] =
    useState(false)

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitted, errors },
  } = useForm<{ postalCode: number }>()

  const onSubmit: SubmitHandler<{ postalCode: number }> = (data: {
    postalCode: number
  }) => {
    getRewards(data.postalCode)
  }

  const handleCodeTypeUpdateOpen = (reward: Reward): void => {
    setRewardsInView(reward)
    setShowCodeTypeUpdateDialog(true)
  }

  const handleQuantityUpdateOpen = (reward: Reward): void => {
    setRewardsInView(reward)
    setShowQuantityUpdateDialog(true)
  }

  const handleSingleCouponUpdateOpen = (reward: Reward): void => {
    setRewardsInView(reward)
    setShowSingleCouponUpdateDialog(true)
  }

  const handleMultipleCouponUpdateOpen = (reward: Reward): void => {
    setRewardsInView(reward)
    setShowMultipleCouponUpdateDialog(true)
  }

  const handleResetRewardsSearch = (): void => {
    reset()
    getRewards()
  }

  const handleCodeTypeUpdate = (
    reward: Reward,
    selectedCodeType: null | 'Generated' | 'Single' | 'Multiple'
  ): void => {
    const updatedReward = {
      ...reward,
      ...{ codeType: selectedCodeType },
    }

    updateReward(updatedReward)
      .then(() => {
        getRewards()
        handleClose()
        setAlert({
          show: true,
          type: 'success',
          message: 'Reward type update successfully.',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        handleClose()
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update reward code type. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  const handleQuantityUpdate = (reward: Reward, quantity: number): void => {
    const updatedReward = {
      ...reward,
      ...{ inventoryQuantity: quantity },
    }

    updateReward(updatedReward)
      .then(() => {
        getRewards()
        handleClose()
        setAlert({
          show: true,
          type: 'success',
          message: 'Quantity update successfully.',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        handleClose()
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update reward quantity. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  const handleSingleCouponUpdate = (
    reward: Reward,
    couponCode: string
  ): void => {
    addSingleCouponCode(reward.rewardId, couponCode)
      .then(() => {
        handleClose()
        setAlert({
          show: true,
          type: 'success',
          message: 'Coupon code updated successfully.',
          autoHideDuration: 2000,
        })
        getRewards()
      })
      .catch(() => {
        handleClose()
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update coupon code. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  const handleMultipleCouponUpdate = (reward: Reward, file: File): void => {
    const formData = new FormData()
    formData.append('File', file)

    addMultipleCouponCodes(reward.rewardId, formData)
      .then(() => {
        handleClose()
        setAlert({
          show: true,
          type: 'success',
          message: 'Coupon codes updated successfully.',
          autoHideDuration: 2000,
        })
        getRewards()
      })
      .catch(() => {
        handleClose()
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update coupon codes. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  const handleClose = (): void => {
    if (showCodeTypeUpdateDialog) setShowCodeTypeUpdateDialog(false)
    if (showQuantityUpdateDialog) setShowQuantityUpdateDialog(false)
    if (showSingleCouponUpdateDialog) setShowSingleCouponUpdateDialog(false)
    if (showMultipleCouponUpdateDialog) setShowMultipleCouponUpdateDialog(false)

    setTimeout(() => {
      setRewardsInView(undefined)
    })
  }

  const dateFormatter = (params: any): string => {
    return params.value
      ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
      : 'N/A'
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: 'Name',
      field: 'rewardName',
      sort: 'asc',
      filter: true,
      sortable: true,
      minWidth: 300,
    },
    {
      headerName: 'Partner',
      field: 'rewardPartnerName',
      filter: true,
      sortable: true,
      minWidth: 300,
    },
    {
      headerName: 'Type',
      field: 'rewardType',
      filter: true,
      sortable: true,
      width: 140,
    },
    {
      headerName: 'SKU',
      field: 'sku',
      filter: true,
      sortable: true,
      width: 90,
    },
    {
      field: 'codeType',
      filter: true,
      sortable: true,
      width: 140,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <>
            {params.value ? params.value : 'None'}
            <Tooltip title="Click to update code type" placement="right" arrow>
              <IconButton
                aria-label="Update Code Type"
                size="small"
                color="primary"
                onClick={(): void => handleCodeTypeUpdateOpen(params.data)}
                sx={{ ml: '0.2rem' }}
              >
                <EditIcon sx={{ fontSize: '1rem' }} color="action" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
    {
      headerName: 'Codes',
      field: 'codeType',
      filter: true,
      sortable: true,
      width: 110,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        if (params.value === 'Single') {
          return (
            <Tooltip title="Click to add single coupon" placement="right" arrow>
              <IconButton
                aria-label="Update single coupon"
                size="small"
                color="primary"
                onClick={(): void => handleSingleCouponUpdateOpen(params.data)}
                sx={{ ml: '0.2rem' }}
              >
                <EditIcon sx={{ fontSize: '1rem' }} color="action" />
              </IconButton>
            </Tooltip>
          )
        } else if (params.value === 'Multiple') {
          return (
            <Tooltip
              title="Click to add multiple coupons"
              placement="right"
              arrow
            >
              <IconButton
                aria-label="Update multiple coupon"
                size="small"
                color="primary"
                component="label"
                onClick={(): void =>
                  handleMultipleCouponUpdateOpen(params.data)
                }
                sx={{ ml: '0.2rem' }}
              >
                <EditIcon sx={{ fontSize: '1rem' }} color="action" />
              </IconButton>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip
              title="Coupons can only be added for Single/Multiple code types."
              placement="right"
              arrow
            >
              <span>N/A</span>
            </Tooltip>
          )
        }
      },
    },
    { field: 'points', filter: true, sortable: true, width: 90 },
    {
      headerName: 'Quantity',
      field: 'inventoryQuantity',
      filter: true,
      sortable: true,
      width: 150,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <>
            {params.value ? params.value : 0}
            <Tooltip title="Click to update quantity" placement="right" arrow>
              <IconButton
                aria-label="Update quantity"
                size="small"
                color="primary"
                onClick={(): void => handleQuantityUpdateOpen(params.data)}
                sx={{ ml: '0.2rem' }}
              >
                <EditIcon sx={{ fontSize: '1rem' }} color="action" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
    {
      field: 'createdAt',
      filter: true,
      valueFormatter: dateFormatter,
      sortable: true,
      width: 180,
    },
    {
      field: 'updatedAt',
      filter: true,
      valueFormatter: dateFormatter,
      sortable: true,
      width: 180,
    },
  ])

  const defaultColDef = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
    }),
    []
  )

  const getRewards = (postalCode: number | null = null): void => {
    setRewardsLoading(true)
    getAllRewards(postalCode)
      .then(response => {
        setRewardsRowData(response.data)
        setRewardsLoading(false)
      })
      .catch(() => {
        setRewardsLoading(false)
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to get rewards list. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  const onCellDoubleClicked = (params: CellClickedEvent<any>): void => {
    if (params) {
      setRewardsInView(params.data)
    }
  }

  return (
    <AdminPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Typography
          paragraph
          sx={{ fontWeight: '600', mb: 2, fontSize: '1rem' }}
        >
          Rewards
        </Typography>
        <Typography variant="body2" paragraph>
          To search rewards by postal code enter a valid postal code and click
          search.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} lg={2}>
              <TextField
                fullWidth
                required
                label="Postal Code"
                size="small"
                type="text"
                {...register('postalCode', {
                  required: 'Postal code is Required ',
                  pattern: {
                    value: getRegex('postalCode'),
                    message: 'Invalid Zip Code',
                  },
                })}
                placeholder="XXXXX-XXXX"
                error={errors?.postalCode ? true : false}
                helperText={
                  errors?.postalCode && errors.postalCode.message
                    ? String(errors.postalCode.message)
                    : ''
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
              }}
            >
              <LoadingButton
                size="small"
                endIcon={<LocationSearchingIcon />}
                loading={isSubmitted && rewardsLoading}
                loadingPosition="end"
                variant="outlined"
                type="submit"
                sx={{ mt: 0.5 }}
              >
                Search
              </LoadingButton>

              <Button
                type="button"
                variant="outlined"
                disabled={!isSubmitted}
                onClick={handleResetRewardsSearch}
                sx={{ mt: 0.5, ml: 1 }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
        <Box
          className="ag-theme-alpine"
          sx={{ width: '100%', height: '75vh', mt: '1rem' }}
        >
          <AgGridReact
            rowData={rewardsRowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={(): void => getRewards()}
            animateRows={true}
            pagination={true}
            paginationPageSize={15}
            onCellDoubleClicked={onCellDoubleClicked}
            gridOptions={{
              suppressCellFocus: true,
            }}
          />

          <CodeTypeUpdateDialog
            open={showCodeTypeUpdateDialog}
            reward={rewardsInView}
            onClose={handleClose}
            onUpdate={handleCodeTypeUpdate}
          />

          <UpdateCouponQuantityDialog
            open={showQuantityUpdateDialog}
            reward={rewardsInView}
            onClose={handleClose}
            onUpdate={handleQuantityUpdate}
          />

          <SingleCouponUpdateDialog
            open={showSingleCouponUpdateDialog}
            reward={rewardsInView}
            onClose={handleClose}
            onCouponAdd={handleSingleCouponUpdate}
          />

          <MultipleCouponUpdateDialog
            open={showMultipleCouponUpdateDialog}
            reward={rewardsInView}
            onClose={handleClose}
            onFileUpload={handleMultipleCouponUpdate}
          />
        </Box>
      </Container>
    </AdminPageWrapper>
  )
}

export default Rewards
