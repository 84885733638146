import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { format, toDate } from 'date-fns-tz'
import { useEffect, useState } from 'react'
import { REWARD_BY_ID } from 'services/contentful/Rewards'
import { RewardDetails } from 'types/RewardTypes'
import { ActivityListItemType } from 'types/UserSettingsTypes'

const Img = styled('img')({
  margin: '0',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  borderRadius: '.25rem',
  border: '1px solid #BDC2CA',
})

const ActivityListItem = ({
  baseContentId,
  rewardContentId,
  rewardFulFillmentId,
  rewardId,
  title,
  createdDate,
  showYear = false,
  points,
  units = 'PTS',
  type,
  rewardCode,
  rewardType,
  onPrintRewards,
}: ActivityListItemType): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [, setError] = useState(false)
  const [rewardData, setRewardData] = useState<RewardDetails | null>()
  const getDay = format(toDate(createdDate, { timeZone: 'Etc/UTC' }), 'dd')
  const getMonth = format(toDate(createdDate, { timeZone: 'Etc/UTC' }), 'MMM')
  const getYear = format(toDate(createdDate, { timeZone: 'Etc/UTC' }), 'YYY')

  const isRewards = type.toLowerCase() === 'rewards' ? true : false
  const isPoints = type.toLowerCase() === 'points' ? true : false

  if (rewardContentId) {
    const {
      loading: APILoading,
      error: APIError,
      data: data,
    } = useQuery(REWARD_BY_ID, {
      variables: { id: rewardContentId },
    })

    useEffect(() => {
      setLoading(APILoading)
      if (!APILoading) {
        if (APIError) {
          setError(true)
        } else {
          if (data) {
            setRewardData(data.rewards)
          }
        }
      }
    }, [APILoading, APIError, data])
  }

  const handlePrintReward = (): void => {
    if (onPrintRewards && rewardFulFillmentId) {
      onPrintRewards(rewardFulFillmentId)
    }
  }

  return (
    <>
      {loading ? <LinearProgress sx={{ height: '2px' }} /> : null}
      <ListItem
        id={baseContentId || rewardId || undefined}
        disableGutters
        sx={{
          mb: 0.3,
          backgroundColor: 'background.paper',
          borderRadius: 0.5,
          p: 0,
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'stretch',
          width: '100%',
        }}
      >
        <Box
          className="item-left"
          sx={{
            borderRight: '1px solid #BDC2CA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '5rem',
            my: 1,
            position: 'relative',
          }}
        >
          <Typography
            component="span"
            sx={{ fontSize: '1.3rem', fontWeight: 600, lineHeight: 1 }}
          >
            {getDay}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: '0.6rem',
              fontWeight: 700,
              lineHeight: 0.8,
              textTransform: 'uppercase',
            }}
          >
            {getMonth}
          </Typography>
          {showYear && getYear ? (
            <Typography
              component="span"
              sx={{
                fontSize: '0.8rem',
                lineHeight: 1,
                transformOrigin: '10% -10%',
                transform: 'rotate(-90deg)',
                color: '#BDC2CA',
                alignSelf: 'start',
              }}
            >
              {getYear}
            </Typography>
          ) : null}
        </Box>
        <Box
          className="item-center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            mx: 2,
            my: 3,
            width: 'calc(100% - 10rem)',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={isRewards ? 10 : 12}>
              <Typography
                paragraph
                color={isPoints || isRewards ? 'primary' : 'error'}
                sx={{
                  fontSize: '0.6rem',
                  fontWeight: 600,
                  lineHeight: 1,
                  m: 0,
                  mb: 0.5,
                }}
              >
                {isPoints ? 'Earned Points' : isRewards ? 'Rewards' : 'Unknown'}
              </Typography>
              <Typography
                paragraph
                sx={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  m: 0,
                }}
              >
                {title}
              </Typography>
            </Grid>
            {isRewards && rewardData ? (
              <Grid item xs={2}>
                {rewardData.image && rewardData.image.url ? (
                  <Img alt=" " src={rewardData.image.url} loading="lazy" />
                ) : (
                  <Typography
                    variant="small"
                    paragraph
                    sx={{
                      textAlign: 'center',
                      m: 0,
                      borderRadius: '.25rem',
                      border: '1px solid #BDC2CA',
                      p: '0.3rem',
                      userSelect: 'none',
                    }}
                  >
                    {rewardData.partner.partnerName}
                  </Typography>
                )}
              </Grid>
            ) : null}
          </Grid>
          {isRewards ? (
            <>
              {rewardData && rewardData.expirationDate ? (
                <Typography variant="small" sx={{ mt: 2 }}>
                  <>Expires: {rewardData.expirationDate}</>
                </Typography>
              ) : null}
              <Stack direction="row" spacing={1} sx={{ mt: 2, width: '100%' }}>
                {rewardData && rewardType === 'online' ? (
                  <>
                    {rewardCode ? (
                      <Box
                        sx={(theme): object => ({
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '0.7rem',
                          lineHeight: '1.7',
                          fontWeight: '600',
                          minWidth: '4rem',
                          padding: '.375rem .75rem',
                          borderRadius: '.25remx',
                          color: '#008f6b',
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1
                          ),
                          flexGrow: '1',
                        })}
                      >
                        Code: {rewardCode.toUpperCase()}
                      </Box>
                    ) : null}
                    {rewardData.redemptionUrl ? (
                      <Button
                        variant="contained"
                        disableElevation
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        href={rewardData.redemptionUrl}
                      >
                        Redeem
                      </Button>
                    ) : null}
                  </>
                ) : null}
                {rewardType === 'print_at_home' ? (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handlePrintReward}
                  >
                    Print Reward
                  </Button>
                ) : null}
                {rewardType === 'donation' ? (
                  <Typography variant="small">
                    Thank you for your donation!
                  </Typography>
                ) : null}
                {rewardType === 'mailed' ||
                rewardType === 'partner_fulfilled' ? (
                  <Typography variant="small">
                    Your Reward is on its way!
                  </Typography>
                ) : null}
                {/* <Button
                  variant="outlined"
                  disableElevation
                  onClick={onMarkAsUsed}
                >
                  Mark as used
                </Button> */}
              </Stack>
            </>
          ) : null}
        </Box>
        <Box
          className="item-right"
          sx={{
            background: '#FFECCC',
            color: '#FFA000',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '5rem',
          }}
        >
          <Typography
            paragraph
            sx={{ fontSize: '1.3rem', fontWeight: 600, lineHeight: 1, m: 0 }}
          >
            {isPoints ? '+' : isRewards ? '' : '~'}
            {points}
          </Typography>
          <Typography
            paragraph
            sx={{
              fontSize: '0.6rem',
              fontWeight: 600,
              lineHeight: 0.8,
              textTransform: 'uppercase',
              my: 0,
              ml: '1rem',
            }}
          >
            {units}
          </Typography>
        </Box>
      </ListItem>
    </>
  )
}

export default ActivityListItem
