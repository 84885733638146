import BlockIcon from '@mui/icons-material/Block'
import { Button, Card, CardContent, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'
import { logout } from 'stores/auth'

const AccessDenied = (): JSX.Element => {
  const theme = useTheme()
  const handleOnBtnClick = (): void => {
    logout('/login')
  }
  return (
    <Container
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#F5F7FA',
      }}
      maxWidth={false}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '.25rem',
          width: { xs: '90%', md: '50%' },
          minHeight: '55vh',
          boxShadow: '0px 4px 30px rgba(12, 28, 42, 0.15)',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '25rem',
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{ m: 0, p: 0 }}
            fontWeight="400"
          >
            4<BlockIcon sx={{ fontSize: 65, color: '#008f6b' }} />1
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 3, fontSize: '1.5rem', textAlign: 'center' }}
          >
            You do not have permission to access the <br />
            requested page
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ mb: 2, fontSize: '0.9rem' }}
          >
            Either go back to the previous page or login as a different user.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnBtnClick}
          >
            Login as a different user
          </Button>
          <Typography
            variant="body1"
            component="p"
            sx={{
              my: 2,
              fontSize: '0.9rem',
              '& a': {
                textDecoration: 'none',
                color: 'inherit',
                '&:hover,&:focus': {
                  color: theme.palette.primary.main,
                  outline: 'none',
                },
              },
            }}
          >
            <NavLink to="/">Back</NavLink>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}
export default AccessDenied
