import { Grid } from '@mui/material'
import CategoryCard from 'components/molecules/CategoryCard'

interface Props {
  cards: card[]
  onCardClick: (to: string) => void
}

interface card {
  bckColor: string
  color: string
  type: string
  heading: string
  description: string
  path: string
}

const CategoryCardWrapper = ({ cards, onCardClick }: Props): JSX.Element => {
  return (
    <Grid
      spacing={{ xs: 2, md: 3 }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      className="rb-category-card-wrapper"
      columns={{ xs: 1, sm: 8, md: 12 }}
    >
      {cards.map((card, index) => (
        <Grid item className="cardItem" key={index} xs={2} sm={4} md={4}>
          <CategoryCard
            backgroundColor={card.bckColor}
            color={card.color}
            type={card.type}
            heading={card.heading}
            description={card.description}
            to={card.path}
            onCardClick={onCardClick}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default CategoryCardWrapper
