import { AxiosResponse } from 'axios'
import { BulkFileListType, Reward, User } from 'types/admin'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  bulkFileList(): Promise<AxiosResponse<BulkFileListType[], any>> {
    return axiosInstance.get('/batchfiles')
  },

  downloadBulkFileByKey(params: {
    storageKey: string
  }): Promise<AxiosResponse<Blob, any>> {
    return axiosInstance.get(
      `/batchfileByStorageKey?storageKey=${params.storageKey}`,
      {
        responseType: 'blob',
      }
    )
  },

  downloadBulkFiles(): Promise<AxiosResponse<Blob, any>> {
    return axiosInstance.get('/downloadBulkFiles', {
      responseType: 'blob',
    })
  },

  allUsers(params: {
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined
  }): Promise<AxiosResponse<User[], any>> {
    return axiosInstance.get(
      `/users?firstName=${
        params.firstName ? params.firstName : null
      }&lastName=${params.lastName ? params.lastName : null}&email=${
        params.email ? params.email : null
      }&clientId=null`
    )
  },

  allRewards(params: {
    postalcode: number | undefined
  }): Promise<AxiosResponse<Reward[], any>> {
    return axiosInstance.get(`/allrewards?postalcode=${params.postalcode}`)
  },
}

const saves = {
  points(params: { userId: string; points: number; reason: string }): Promise<
    AxiosResponse<
      {
        totalPoints: number
      },
      any
    >
  > {
    return axiosInstance.post(`/users/${params.userId}/points`, {
      points: params.points,
      reason: params.reason,
    })
  },

  //Rewards
  updateReward(reward: Reward): Promise<AxiosResponse<Reward, any>> {
    return axiosInstance.put(`/rewards/${reward.rewardId}`, reward)
  },

  singleCouponCode(params: {
    rewardId: string
    couponCode: string
  }): Promise<AxiosResponse<any, any>> {
    return axiosInstance.post(
      `/rewards/${params.rewardId}/coupons/${params.couponCode}`
    )
  },

  multipleCouponCodes(params: {
    rewardId: string
    formData: FormData
  }): Promise<AxiosResponse<any, any>> {
    return axiosInstance.post(
      `/rewards/${params.rewardId}/coupons`,
      params.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
}

const deletes = {}

export default class AdminService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
