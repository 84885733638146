import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  Badge,
  Button,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import { UpdateUser } from 'types/AuthTypes'
import { states } from 'utils/data'
import { getRegex } from 'utils/helpers'

const EditAvatar = styled(Fab)(({ theme }) => ({
  minHeight: '1.5rem',
  height: '1.5rem',
  width: '1.5rem',
  border: `1px solid ${theme.palette.background.paper}`,
  boxShadow: 'none',
}))
interface Props extends UpdateUser {
  photoURL: string
  onUpdate: (data: UpdateUser) => void
}

const EditProfile = ({
  photoURL,
  firstName,
  lastName,
  loginEmail,
  houseNumber,
  street,
  city,
  stateProvince,
  postalCode,
  country,
  onUpdate,
}: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm()

  const onSubmit: SubmitHandler<any> = data => {
    onUpdate(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="overline"
        paragraph
        sx={{ color: 'rgba(0, 0, 0, 0.6)', mb: 3 }}
      >
        Account
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <EditAvatar
                color="primary"
                size="small"
                aria-label="edit"
                sx={{ display: 'none' }}
              >
                <EditIcon sx={{ fontSize: '0.9rem' }} />
              </EditAvatar>
            }
          >
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={photoURL}
              sx={{ width: '8rem', height: '8rem' }}
            />
          </Badge>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label="First Name"
            autoFocus
            color="primary"
            {...register('firstName', {
              required: 'First Name is Required.',
              pattern: {
                value: getRegex('name'),
                message: 'Invalid Last Name.',
              },
            })}
            error={errors?.firstName ? true : false}
            defaultValue={firstName}
            sx={{ fontSize: '0.6rem' }}
            helperText={
              errors?.firstName && errors.firstName.message
                ? String(errors.firstName.message)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label="Last Name"
            {...register('lastName', {
              required: 'Last Name is Required.',
              pattern: {
                value: getRegex('name'),
                message: 'Invalid Last Name.',
              },
            })}
            error={errors?.lastName ? true : false}
            defaultValue={lastName}
            helperText={
              errors?.lastName && errors.lastName.message
                ? String(errors.lastName.message)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label="Email Address"
            {...register('loginEmail', {
              required: 'Email Address is Required.',
              pattern: {
                value: getRegex('email'),
                message: 'Invalid Email Address.',
              },
            })}
            error={errors?.loginEmail ? true : false}
            defaultValue={loginEmail}
            helperText={
              errors?.loginEmail && errors.loginEmail.message
                ? String(errors.loginEmail.message)
                : dirtyFields?.loginEmail
                ? 'On change you need to relogin.'
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="overline"
            paragraph
            sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
          >
            Update Address
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="House Number"
            size="small"
            type="text"
            {...register('houseNumber', {
              required: 'House Number is Required.',
              pattern: {
                value: getRegex('houseNumber'),
                message: 'Invalid Address.',
              },
            })}
            placeholder="House Number"
            error={errors?.address ? true : false}
            defaultValue={houseNumber}
            helperText={
              errors?.houseNumber && errors.houseNumber.message
                ? String(errors.houseNumber.message)
                : ''
            }
          />{' '}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Street"
            size="small"
            type="text"
            {...register('street', {
              required: 'Street is Required.',
              pattern: {
                value: getRegex('street'),
                message: 'Invalid Street.',
              },
            })}
            placeholder="Street"
            error={errors?.street ? true : false}
            defaultValue={street}
            helperText={
              errors?.street && errors.street.message
                ? String(errors.street.message)
                : ''
            }
          />{' '}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="City"
            size="small"
            type="text"
            {...register('city', {
              required: 'City is Required.',
              pattern: {
                value: getRegex('city'),
                message: 'Invalid City.',
              },
            })}
            placeholder="City"
            error={errors?.city ? true : false}
            defaultValue={city}
            helperText={
              errors?.city && errors.city.message
                ? String(errors.city.message)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="State"
            size="small"
            select
            InputProps={{
              readOnly: true,
            }}
            defaultValue={stateProvince ?? ''}
            {...register('stateProvince', {
              required: 'State is Required ',
            })}
            placeholder="State"
            error={errors?.stateProvince ? true : false}
            helperText={
              errors?.stateProvince && errors.stateProvince.message
                ? String(errors.stateProvince.message)
                : ''
            }
          >
            {states.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Zip Code"
            size="small"
            type="text"
            InputProps={{
              readOnly: true,
            }}
            {...register('postalCode', {
              required: 'Zip Code is Required ',
              pattern: {
                value: getRegex('postalCode'),
                message: 'Invalid Zip Code',
              },
            })}
            placeholder="XXXXX-XXXX"
            error={errors?.postalCode ? true : false}
            defaultValue={postalCode}
            helperText={
              errors?.postalCode && errors.postalCode.message
                ? String(errors.postalCode.message)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            label="Country"
            size="small"
            InputProps={{
              readOnly: true,
            }}
            {...register('country', {
              value: 'US',
              required: 'Country is Required ',
              pattern: {
                value: getRegex('country'),
                message: 'Invalid Country',
              },
            })}
            defaultValue={country ?? ''}
            placeholder="Country"
            error={errors?.country ? true : false}
            helperText={
              errors?.country && errors.country.message
                ? String(errors.country.message)
                : ''
            }
          >
            {[
              {
                value: 'US',
                label: 'United States',
                selected: true,
              },
            ].map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default EditProfile
