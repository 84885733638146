import SearchIcon from '@mui/icons-material/Search'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Container,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import {
  CellClickedEvent,
  ColDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import EditUserDialog from 'components/molecules/EditUserDialog'
import AdminPageWrapper from 'components/templates/AdminPageWrapper'
import { format, toDate } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AlertProp, alertAtom } from 'stores'
import { getUsers, updatePoints } from 'stores/admin'
import { User } from 'types/admin'
import { getRegex } from 'utils/helpers'

interface FormFields {
  firstName: string
  lastName: string
  email: string
}

const Users = (): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [rowData, setRowData] = useState<User[] | undefined>(undefined)
  const [userInView, setUserInView] = useState<User | undefined>(undefined)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState('OR')
  const [, setAlert] = useAtom(alertAtom)
  const [usersUpdated, setUsersUpdated] = useState(false)
  const [dialogAlertMsg, setDialogAlertMsg] = useState<AlertProp | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormFields>({ mode: 'onChange' })

  const handleDynamicValidation = (
    field: 'firstName' | 'lastName' | 'email',
    value: string
  ): void => {
    setValue(field, value.trim())
    setErrorMsg('OR')
  }

  const onSubmit: SubmitHandler<FormFields> = ({
    firstName,
    lastName,
    email,
  }) => {
    if ((firstName && lastName) || email) {
      setErrorMsg('OR')
      setSearchLoading(true)

      getUsers(
        firstName || undefined,
        lastName || undefined,
        email || undefined
      )
        .then(response => {
          setRowData(response.data)
          setSearchLoading(false)
          setUsersUpdated(false)
        })
        .catch(() => {
          setSearchLoading(false)
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to search the user. Try again.',
            autoHideDuration: 2000,
          })
        })
    } else {
      setErrorMsg('First Name & Last name or Email is Required.')
    }
  }

  const dateFormatter = (params: ValueFormatterParams): string => {
    return params.value
      ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
      : 'N/A'
  }

  const [columnDefs] = useState<ColDef[]>([
    { field: 'firstName', filter: true, sortable: true, minWidth: 100 },
    { field: 'lastName', filter: true, sortable: true, minWidth: 100 },
    {
      headerName: 'Email',
      field: 'loginEmail',
      filter: true,
      sortable: true,
      minWidth: 100,
    },
    { field: 'clientName', filter: true, minWidth: 100 },
    {
      field: 'Address',
      filter: true,
      minWidth: 200,
      valueGetter: ({ data }: any): string => {
        let address = ''
        if (data.houseNumber) {
          address += data.houseNumber
        }
        if (data.street) {
          address += ' ' + data.street
        }
        if (data.city) {
          address += ', ' + data.city
        }
        if (data.stateProvince) {
          address += ', ' + data.stateProvince
        }
        if (data.postalCode) {
          address += ' ' + data.postalCode
        }
        return address
      },
    },
    // { field: 'street', filter: true },
    // { field: 'city', filter: true },
    // { field: 'stateProvince', filter: true },
    // { field: 'postalCode', filter: true },
    // { field: 'country', filter: true },
    {
      field: 'pointBalance',
      filter: true,
      width: 145,
      sortable: true,
    },
    { field: 'lifetimePoints', filter: true, sortable: true, width: 145 },
    {
      field: 'lastEarnDate',
      filter: true,
      valueFormatter: dateFormatter,
      sortable: true,
      width: 180,
    },
    {
      field: 'lastRewardDate',
      filter: true,
      valueFormatter: dateFormatter,
      sortable: true,
      width: 180,
    },
    {
      headerName: 'Status',
      field: 'isActive',
      filter: true,
      width: 100,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams): string => {
        return params.value ? 'Active' : 'Inactive'
      },
    },
    {
      field: 'createdAt',
      filter: true,
      valueFormatter: dateFormatter,
      sortable: true,
      width: 180,
    },
    {
      headerName: 'Auth Id',
      field: 'authProviderId',
      filter: true,
      width: 280,
    },
  ])

  const defaultColDef = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
    }),
    []
  )

  const onGridReady = (): void => {
    //
  }

  const onCellDoubleClicked = (params: CellClickedEvent<User>): void => {
    if (params) {
      setUserInView(params.data)
      setDialogOpen(true)
    }
  }

  const handleUserDialogClose = (): void => {
    setDialogOpen(false)
    setUserInView(undefined)
    if (usersUpdated) {
      // If there is change in user then refetch
      // the users as per search string to update the grid
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdatePoints = (points: number, reason: string): void => {
    if (
      userInView &&
      points !== 0 &&
      points > -userInView.pointBalance &&
      reason !== ''
    ) {
      updatePoints(userInView.userId, points, reason)
        .then(response => {
          setUsersUpdated(true)
          setUserInView(prev => {
            if (prev) {
              return {
                ...prev,
                pointBalance: response.data.totalPoints,
              }
            }
            return undefined
          })

          setDialogAlertMsg({
            show: true,
            type: 'success',
            message: 'Successfully updated user points.',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          setDialogAlertMsg({
            show: true,
            type: 'error',
            message: 'Failed to update user points. Try again.',
            autoHideDuration: 2000,
          })
        })
    }
  }

  return (
    <AdminPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Typography
          paragraph
          sx={{ fontWeight: '600', mb: 2, fontSize: '1rem' }}
        >
          Users
        </Typography>
        <Typography variant="body2" paragraph>
          Enter first name, last name or email address to search users.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mb: 2, width: { xs: '100%', sm: '50%' }, mt: 3 }}
          noValidate
          autoComplete="off"
        >
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="First Name"
                autoFocus
                color="primary"
                {...register('firstName', {
                  pattern: {
                    value: getRegex('name'),
                    message: 'Invalid characters.',
                  },
                  minLength: {
                    value: 3,
                    message: 'Atleast 3 characters required.',
                  },
                })}
                onChange={(e): void =>
                  handleDynamicValidation('firstName', e.target.value.trim())
                }
                error={errors?.firstName ? true : false}
                sx={{ fontSize: '0.6rem' }}
                helperText={
                  errors?.firstName && errors.firstName.message
                    ? String(errors.firstName.message)
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Last Name"
                {...register('lastName', {
                  pattern: {
                    value: getRegex('name'),
                    message: 'Invalid characters.',
                  },
                  minLength: {
                    value: 3,
                    message: 'Atleast 3 characters required.',
                  },
                })}
                onChange={(e): void =>
                  handleDynamicValidation('lastName', e.target.value.trim())
                }
                error={errors?.lastName ? true : false}
                helperText={
                  errors?.lastName && errors.lastName.message
                    ? String(errors.lastName.message)
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ p: 0 }}>
              <Divider>
                <FormHelperText
                  error={errorMsg !== 'OR'}
                  sx={{ textAlign: 'center' }}
                >
                  {errorMsg}
                </FormHelperText>
              </Divider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Email Address"
                {...register('email', {
                  pattern: {
                    value: getRegex('email'),
                    message: 'Invalid Email Address.',
                  },
                })}
                error={errors?.email ? true : false}
                onBlur={(
                  e: React.FocusEvent<
                    HTMLInputElement | HTMLTextAreaElement,
                    Element
                  >
                ): void => setValue('email', e.target.value.trim())}
                helperText={
                  errors?.email && errors.email.message
                    ? String(errors.email.message)
                    : ''
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="center"
              sx={{ mt: '1rem' }}
            >
              <LoadingButton
                size="small"
                endIcon={<SearchIcon />}
                loading={searchLoading}
                loadingPosition="end"
                variant="outlined"
                type="submit"
              >
                Search
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        {rowData !== undefined ? (
          rowData.length ? (
            <Box
              className="ag-theme-alpine"
              sx={{ width: '100%', height: '50vh', mt: '2.5rem' }}
            >
              <Typography
                paragraph
                sx={{ fontWeight: '400', mb: 0.5, fontSize: '0.8rem' }}
              >
                Double click on the below table rows to update user points or to
                view user points and rewards history.
              </Typography>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                animateRows={true}
                pagination={true}
                paginationPageSize={20}
                onCellDoubleClicked={onCellDoubleClicked}
                gridOptions={{
                  suppressCellFocus: true,
                }}
              />
            </Box>
          ) : (
            <>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: '500',
                  mt: '2.5rem',
                  mb: '0.2rem',
                  fontSize: '1.1rem!important',
                }}
              >
                No Results Found
              </Typography>
              <Typography paragraph sx={{ fontSize: '0.9rem' }}>
                Please try searching again.
              </Typography>
            </>
          )
        ) : null}
      </Container>
      {userInView ? (
        <EditUserDialog
          open={dialogOpen}
          user={userInView}
          setAlertMsg={dialogAlertMsg}
          onPointsUpdate={handleUpdatePoints}
          onCloseCB={handleUserDialogClose}
        />
      ) : null}
    </AdminPageWrapper>
  )
}

export default Users
