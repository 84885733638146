import { AxiosResponse } from 'axios'
import { atom } from 'jotai'
import AppService from 'services/app'
import {
  FilterOption,
  RewardCategoryItemType,
  RewardsRequest,
  RewardsResponse,
  SortOption,
} from 'types/RewardTypes'

export const rewardsListAtom = atom<RewardsResponse[]>([])

export const filterOptionsAtom = atom<FilterOption[]>([
  { label: '0 - 50', checked: false, value: 'pointsZero' },
  { label: '51 - 100', checked: false, value: 'pointsFiftyOne' },
  { label: '101 - 150', checked: false, value: 'pointsOneHundredOne' },
  { label: '151 - 200', checked: false, value: 'pointsOneFiftyOne' },
  { label: '201+', checked: false, value: 'pointsTwoHundredOne' },
])

export const categoriesAtom = atom<RewardCategoryItemType[]>([])

export const rewardTypeOptionsAtom = atom<FilterOption[]>([
  { label: 'All', checked: true, value: 'all' },
  { label: 'Local Rewards', checked: false, value: 'local' },
  { label: 'National Rewards', checked: false, value: 'national' },
])

export const sortOptionsAtom = atom<SortOption[]>([
  { value: 'created_at', label: 'Created At' },
  { value: 'popularity', label: 'Popularity' },
  { value: 'proximity', label: 'Proximity' },
  { value: 'reward_name', label: 'Reward Name' },
  { value: 'reward_partner_name', label: 'Partner Name' },
  { value: 'points', label: 'Points' },
])

export const sortByRewardsAtom = atom<SortOption>({
  value: 'created_at',
  label: 'Created At',
})

// API
export const getRewards = async ({
  userId,
  pointsZero = 0,
  pointsFiftyOne = 0,
  pointsOneHundredOne = 0,
  pointsOneFiftyOne = 0,
  pointsTwoHundredOne = 0,
  offset,
  sortBy,
  sortDirection,
  limit,
  categoryIds = undefined,
  search = undefined,
  local = 0,
  national = 0,
}: RewardsRequest): Promise<AxiosResponse<RewardsResponse[], any>> => {
  try {
    return await AppService.Rewards.getData(
      'rewards',
      {
        userId,
        pointsZero,
        pointsFiftyOne,
        pointsOneHundredOne,
        pointsOneFiftyOne,
        pointsTwoHundredOne,
        offset,
        sortBy,
        sortDirection,
        limit,
        categoryIds,
        search,
        local,
        national,
      },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getRewardsByPostalCode = async (
  postalCode: string
): Promise<AxiosResponse<RewardsResponse[], any>> => {
  try {
    return await AppService.Rewards.getData(
      'rewardsByPostalCode',
      postalCode,
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const printReward = async (
  rewardFulFillmentId: string
): Promise<AxiosResponse<Blob, any>> => {
  try {
    return await AppService.Rewards.getData(
      'printReward',
      rewardFulFillmentId,
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
