import { Container, Grid } from '@mui/material'
import learnEarnbanner from 'assets/img/live_green_banner.jpg'
import ScoreBadge from 'components/molecules/ScoreBadge'
import ContentWrapper from 'components/organisms/ContentWrapper'
import PopularReadsWrapper from 'components/organisms/PopularReadsWrapper'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { alertAtom } from 'stores'
import { userAtom } from 'stores/user'
import { pointsAtom, setPoints, validContentView } from 'stores/user'
import { contentViewEvent, earnPointsEvent } from 'utils/analytics'

const ContentDetailsContainer = (): JSX.Element => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const id = query.get('id') || undefined

  const navigate = useNavigate()
  const { type } = useParams()
  const [user] = useAtom(userAtom)
  const [pointBalance, setPointBalance] = useAtom(pointsAtom)
  const [valid, setValid] = useState<boolean>(false)
  const [, setAlert] = useAtom(alertAtom)

  const validateContent = (userId: string, baseContentId: string): void => {
    validContentView(userId, baseContentId).then(response => {
      setValid(response.data.valid)
    })
  }

  const setPageAlert = (success = true): void => {
    setAlert({
      show: true,
      type: success ? 'success' : 'error',
      message: success ? 'Successfully earned points.' : 'Failed. Try again.',
      autoHideDuration: 2000,
    })
  }

  const handleContentView = (
    baseContentId: string,
    contentId: string,
    type: string,
    points: number,
    title: string
  ): void => {
    if (user?.authProviderId) {
      // GA-ContentView
      contentViewEvent(
        baseContentId,
        user.postalCode,
        contentId,
        type,
        points,
        title
      )
    }
  }

  const handleBaseContentIdUpdate = (baseContentId: string): void => {
    if (user && user.userId && baseContentId) {
      validateContent(user.userId, baseContentId)
    }
  }

  const handleEarnPointsClick = (
    baseContentId: string,
    contentId: string,
    type: string,
    points: number,
    title: string
  ): boolean | void => {
    if (user?.userId && valid) {
      // if the contents doesnt exists in user or its future year
      setPoints(user.userId, baseContentId, type)
        .then(response => {
          earnPointsEvent(
            baseContentId,
            user.postalCode,
            contentId,
            type,
            points,
            title
          )
          // On transaction success update points
          setPointBalance(response.data.totalPoints)
          setPageAlert()
        })
        .catch(() => {
          if (user && user.userId && id) {
            validateContent(user.userId, id)
          }
          setPageAlert(false)
        })
    }
  }

  const handlePopularCardClick = (to: string): void => {
    navigate('/dashboard/' + to)
  }

  return (
    <AuthenticatedPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          backgroundImage: 'url(' + learnEarnbanner + ')',
          height: '13.75rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'end' },
          alignItems: 'baseline',
          py: 3,
        }}
      >
        <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
          <Grid
            container
            direction="row"
            justifyContent={{ xs: 'start', md: 'end' }}
            alignItems={{ xs: 'end', md: 'center' }}
            color="white"
          >
            <Grid
              item
              sx={{
                position: 'relative',
                top: { xs: '7.75rem', md: '5rem' },
                zIndex: 100,
              }}
            >
              <ScoreBadge points={pointBalance} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        component="div"
        maxWidth="lg"
        sx={{ mt: { xs: 0, md: 3 }, mb: 4 }}
      >
        <Grid container direction="row" sx={{ mt: { xs: 2, md: 9 } }}>
          <Grid item sx={{ pr: { md: 2 } }} xs={12} md={8}>
            <ContentWrapper
              type={type}
              id={id}
              disabledEarnPoint={!valid}
              onBaseContentIdUpdate={handleBaseContentIdUpdate}
              onContentView={handleContentView}
              onEarnPointsClick={handleEarnPointsClick}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <PopularReadsWrapper
              limit={4}
              onCardClick={handlePopularCardClick}
            />
            {/* <SwitchCard
              featuredImage={switchCardImg}
              imgText="DAILY PLEDGE"
              text="Instead of using individual flowers as a symbol of your love, gift potted flowers, which will last much longer and won't have to be trashed. "
              switchText="EARN 5 POINTS"
            /> */}
          </Grid>
        </Grid>
      </Container>
    </AuthenticatedPageWrapper>
  )
}

export default ContentDetailsContainer
