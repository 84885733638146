import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Reward } from 'types/admin'

interface Props {
  reward: Reward | undefined
  onClose?: () => void
  onCouponAdd: (reward: Reward, couponCode: string) => void
  open: boolean
}
const SingleCouponUpdateDialog = ({
  onClose,
  onCouponAdd,
  reward = undefined,
  open = false,
}: Props): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  const handleSubmit = (): void => {
    if (value && reward) {
      onCouponAdd(reward, value)
      setValue('')
    }
  }
  const handleClose = (): void => {
    setDialogOpen(false)
    setValue('')
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
    >
      <DialogContent>
        <TextField
          autoFocus
          required
          id="couponCode"
          label="Coupon Code"
          value={value}
          onChange={(event): void => setValue(event.target.value)}
          inputProps={{ maxLength: 255 }}
          sx={{ width: '19rem' }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={handleSubmit} disabled={value.length === 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SingleCouponUpdateDialog
