import { Box, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import RewardsFilter from 'components/molecules/RewardsFilter'
import RewardsListItemWrapper from 'components/organisms/RewardsListItemWrapper'
import { useAtom } from 'jotai'
import { ChangeEvent, useState } from 'react'
import { filterOptionsAtom, sortOptionsAtom } from 'stores/rewards'
import {
  FilterOption,
  RewardCategoryItemType,
  RewardsResponse,
  SortOption,
} from 'types/RewardTypes'

interface Props {
  sortBy: SortOption | null
  typeOptions: FilterOption[]
  rewardsList: RewardsResponse[]
  categoriesList: RewardCategoryItemType[]
  rewardsListLoading: boolean
  sortByDirection: 'asc' | 'desc'
  currentPage: number
  totalPage: number
  onSortByChange: (option: SortOption) => void
  onPointsFilterChange: (options: FilterOption[]) => void
  onCategoryFilterSelect: (option: RewardCategoryItemType) => void
  onTypeSelect: (options: FilterOption) => void
  onRewardsFilterSearch: (searchString: string) => void
  onSortByDirectionChange: (direction: 'asc' | 'desc') => void
  onPaginationChange: (event: ChangeEvent<unknown>, value: number) => void
}

const RewardsListWrapper = ({
  sortBy,
  rewardsList,
  categoriesList,
  typeOptions,
  rewardsListLoading,
  currentPage,
  totalPage,
  sortByDirection,
  onSortByChange,
  onPointsFilterChange,
  onCategoryFilterSelect,
  onTypeSelect,
  onRewardsFilterSearch,
  onSortByDirectionChange,
  onPaginationChange,
}: Props): JSX.Element => {
  const [sortOptions] = useAtom(sortOptionsAtom)
  const [filterOptions] = useAtom(filterOptionsAtom)
  const [drawerstate, setDrawerState] = useState(false)
  const handleDrawerToggle = (): void => setDrawerState(!drawerstate)

  const handlePointsFilterSelect = (option: FilterOption): void => {
    const currentFilterOptions = [...filterOptions]
    const i = currentFilterOptions.findIndex(o => o.value === option.value)

    if (i !== -1) {
      currentFilterOptions[i].checked = !currentFilterOptions[i].checked
    }

    onPointsFilterChange(currentFilterOptions)
  }

  const theme = useTheme()
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      component="div"
      className="rb_rewards_list_wrapper"
      sx={{
        mb: 3,
        mt: { xs: 2, md: '4.8rem' },
      }}
    >
      <Grid container columnSpacing={2}>
        {isDownMd ? (
          <RewardsFilter
            isDownMd
            filterOptions={filterOptions}
            typeOptions={typeOptions}
            categoriesList={categoriesList}
            drawerstate={drawerstate}
            onDrawerClose={handleDrawerToggle}
            onPointsFilterSelect={handlePointsFilterSelect}
            onCategorySelect={onCategoryFilterSelect}
            onRewardsSearch={onRewardsFilterSearch}
            onTypeSelect={onTypeSelect}
          />
        ) : (
          <Grid item xs={3}>
            <RewardsFilter
              filterOptions={filterOptions}
              typeOptions={typeOptions}
              categoriesList={categoriesList}
              onPointsFilterSelect={handlePointsFilterSelect}
              onCategorySelect={onCategoryFilterSelect}
              onRewardsSearch={onRewardsFilterSearch}
              onTypeSelect={onTypeSelect}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={9}
          className="rb_rewards_categories_list_wrapper"
        >
          <RewardsListItemWrapper
            isDownMd={isDownMd}
            sortBy={sortBy}
            sortOptions={sortOptions}
            rewardsList={rewardsList}
            rewardsListLoading={rewardsListLoading}
            sortByDirection={sortByDirection}
            currentPage={currentPage}
            totalPage={totalPage}
            onSortByChange={onSortByChange}
            onSortByDirectionChange={onSortByDirectionChange}
            onFilterToggle={handleDrawerToggle}
            onPaginationChange={onPaginationChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RewardsListWrapper
