import { useQuery } from '@apollo/client'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import {
  faArrowsRotate,
  faImages,
  faNewspaper,
  faQuestion,
  faVideo,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  ButtonProps,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { Alert } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tag from 'components/atoms/Tag'
import Loading from 'components/molecules/Loading'
import { useEffect, useState } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { BASE_CONTENT_LIST } from 'services/contentful/BaseContentList'
import { BaseContentItemType } from 'types/BaseContentTypes'
import { dateFormatNoYear } from 'utils/date'

interface Props {
  onReadMore?: (to: string) => void
}

const FaIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  position: 'absolute',
  bottom: '2.1875rem',
  left: '2.1875rem',
  color: theme.palette.primary.main,
}))

const TypeIcon = ({ type }: { type: string }): JSX.Element => {
  let typeIcon = null
  switch (type) {
    case 'Article':
      typeIcon = faNewspaper
      break
    case 'Flipcard':
      typeIcon = faArrowsRotate
      break
    case 'Video':
      typeIcon = faVideo
      break
    case 'Slideshow':
      typeIcon = faImages
      break
    case 'Quiz':
      typeIcon = faQuestion
      break
  }

  return typeIcon ? <FaIcon icon={typeIcon} size="lg" /> : <></>
}

const LikeNavButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#F5F7FA',
  textTransform: 'uppercase',
  color: '#0C1C2A',
  borderColor: '#BDC2CA',
  fontSize: '0.7rem',
  borderRadius: 0,
  '&:hover': {
    borderColor: '#BDC2CA',
    color: theme.palette.primary.main,
  },
  '& p': {
    margin: 0,
  },
}))

const Img = styled('img')({
  margin: '0',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  height: '100%',
})

const LikeCard = ({ onReadMore }: Props): JSX.Element => {
  const [contentList, setContentList] = useState<BaseContentItemType[]>([])
  const [total, setTotal] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const handleReadMore = (to: string): void => {
    if (onReadMore) onReadMore(to)
  }

  const handlePrevious = (): void => {
    if (currentPage !== 0) setCurrentPage(currentPage - 1)
  }

  const handleNext = (): void => {
    if (currentPage < total) setCurrentPage(currentPage + 1)
  }

  const { data, loading, error } = useQuery(BASE_CONTENT_LIST, {
    variables: { limit: 3, skip: currentPage },
  })

  useEffect(() => {
    if (!loading && data?.baseContentCollection?.items?.length) {
      setContentList(data.baseContentCollection.items)
      setTotal(data.baseContentCollection.total)
    }
  }, [loading, data])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  const previousItem =
    currentPage === 0
      ? null
      : currentPage === total
      ? contentList[1]
      : contentList[0]
  const item =
    currentPage === 0
      ? contentList[0]
      : currentPage === total
      ? contentList[2]
      : contentList[1]
  const nextItem =
    currentPage === 0
      ? contentList[1]
      : currentPage === total
      ? null
      : contentList[2]

  return (
    <Paper
      className="rb-like-card-container"
      sx={{
        p: 0,
        margin: 'auto',
        maxWidth: '100%',
        flexGrow: 1,
        boxShadow: {
          xs: '0px 10px 35px rgba(33, 64, 154, 0.2)',
          md: '0px 20px 60px rgba(33, 64, 154, 0.2)',
        },
        borderRadius: '0.313rem',
        mt: 3,
        mb: { xs: 6, md: 12 },
        position: 'relative',
      }}
    >
      {item ? (
        <Grid container spacing="0" key={item.sys.id}>
          <Grid item md={6} className="rb-featured-image">
            <ButtonBase sx={{ width: '100%', height: '100%' }}>
              <Img alt={item.title} src={item.image.url} loading="lazy" />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container md={6}>
            <Grid
              item
              xs
              container
              direction="column"
              spacing={0}
              sx={{ overflow: 'hidden', position: 'relative' }}
            >
              <Grid
                item
                xs
                sx={{ p: { xs: 2, md: 5 } }}
                className="rb-like-card-content"
              >
                <ButtonBase
                  aria-label="type"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    '&:hover': {
                      transform: 'scaleX(1)',
                      border: '8rem solid #F2F9F8',
                      borderRadius: '50%',
                      top: '-9.5em',
                      right: '-9.5em',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '9.375rem',
                      height: '9.375rem',
                      position: 'absolute',
                      background: '#DAEEEA',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                    }}
                  >
                    {item.content.__typename ? (
                      <TypeIcon type={item.content.__typename} />
                    ) : null}
                  </Box>
                </ButtonBase>
                {item?.contentfulMetadata?.tags ? (
                  <Stack direction="row" spacing={1} sx={{ mt: 5 }}>
                    {item?.contentfulMetadata?.tags.map((tag, i) => (
                      <Tag key={i} label={tag.label} />
                    ))}
                  </Stack>
                ) : null}
                <Typography
                  variant="h3"
                  fontWeight="600"
                  gutterBottom
                  textTransform="uppercase"
                  sx={{ my: 1 }}
                >
                  <LinesEllipsis
                    text={item.title}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="words"
                  />
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                  By Recyclebank |{' '}
                  {dateFormatNoYear(
                    item.publishDay,
                    item.publishMonth,
                    item.publishYear
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ mt: 2, mb: 3, '& p': { margin: 0 } }}
                >
                  <LinesEllipsis
                    component="p"
                    text={item.abstract}
                    maxLine="3"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </Typography>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{ fontSize: '0.7rem' }}
                  onClick={(): void =>
                    handleReadMore(
                      `${item.content.__typename}${item.slug}?id=${item.content.sys.id}`
                    )
                  }
                >
                  Read More
                </Button>
              </Grid>
              <Grid
                item
                className="rb-like-card-rating"
                sx={{ px: { xs: 2, md: 5 } }}
              >
                <Typography
                  variant="small"
                  textTransform="uppercase"
                  sx={{
                    fontWeight: 600,
                    color: '#FFA000',
                    alignItems: 'center',
                    display: 'flex',
                    mt: 1,
                    mb: 4,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    style={{ marginRight: '0.5rem' }}
                  />
                  {item.points} points
                </Typography>
              </Grid>

              <Grid
                item
                className="rb-like-card-footer"
                sx={{ display: 'flex' }}
              >
                <ButtonGroup
                  size="large"
                  fullWidth
                  disableElevation
                  sx={{ borderRadius: 0 }}
                >
                  {previousItem ? (
                    <LikeNavButton
                      endIcon={
                        <ArrowRightAltIcon
                          sx={{
                            transform: 'rotate(-180deg)',
                            ml: { xs: 1, md: 1.5 },
                          }}
                        />
                      }
                      sx={{
                        borderWidth: '0 1px 0 0',
                        justifyContent: 'end',
                        textAlign: 'right',
                        '&:hover': { borderWidth: '0 1px 0 0' },
                      }}
                      onClick={handlePrevious}
                    >
                      <LinesEllipsis
                        component="p"
                        text={previousItem.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </LikeNavButton>
                  ) : null}
                  {nextItem ? (
                    <LikeNavButton
                      startIcon={
                        <ArrowRightAltIcon sx={{ mr: { xs: 1, md: 1.5 } }} />
                      }
                      sx={{
                        borderWidth: '0 0 0 1px',
                        justifyContent: 'start',
                        textAlign: 'left',
                        '&:hover': { borderWidth: '0 0 0 1px' },
                      }}
                      onClick={handleNext}
                    >
                      <LinesEllipsis
                        component="p"
                        text={nextItem.title}
                        maxLine="2"
                        ellipsis="..."
                        basedOn="letters"
                      />
                    </LikeNavButton>
                  ) : null}
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  )
}

export default LikeCard
