import { FormControlLabel, Switch as MuiSwitch } from '@mui/material'
import { SwitchProps } from '@mui/material/Switch'
import { SxProps, Theme, styled } from '@mui/material/styles'

const scoreIcon = (fill: string): string => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"  fill="${fill}"><path d="M0 0h24v24H0z" fill="none"/><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/></svg>')`
}

const IOSSwitch = styled((props: SwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme, size, color, className }) => ({
  height: size === 'small' ? 20 : 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: `translateX(${size === 'small' ? 20 : 32}px)`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : color,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: size === 'small' ? 16 : 22,
    height: size === 'small' ? 16 : 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: size === 'small' ? 20 / 2 : 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#BDC2CA' : '#39393D',
    boxShadow: '0 3px 3px 0 #00000040 inset',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 20,
      height: 20,
      opacity: 0.3,
      backgroundRepeat: 'no-repeat',
    },
    '&:before': {
      backgroundImage:
        className === 'hasIcon'
          ? `${scoreIcon(
              encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
              )
            )}`
          : 'none',
      left: 8,
    },
    '&:after': {
      backgroundImage:
        className === 'hasIcon'
          ? `${scoreIcon(
              encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
              )
            )}`
          : 'none',
      right: 8,
    },
  },
}))

interface Props {
  label?: string
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined
  labelWidth?: number
  disabled?: boolean
  checked?: boolean
  size?: 'medium' | 'small' | undefined
  edge?: 'end' | 'start' | false
  inputProps?: object
  hasIcon?: boolean
  labelProps?: SxProps<Theme>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch = ({
  label,
  color = 'primary',
  labelWidth = 50,
  disabled = false,
  checked = false,
  onChange,
  size = 'medium',
  inputProps,
  edge = false,
  hasIcon = true,
  labelProps,
}: Props): JSX.Element => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <IOSSwitch
          sx={{ m: 1 }}
          checked={checked}
          onChange={handleOnChange}
          size={size}
          inputProps={inputProps}
          edge={edge}
          color={color}
          className={hasIcon ? 'hasIcon' : 'noIcon'}
        />
      }
      label={label}
      sx={labelProps}
      componentsProps={{
        typography: {
          color: color ? color : 'black',
          fontSize: '0.65rem',
          fontWeight: 700,
          width: labelWidth,
        },
      }}
    />
  )
}

export default Switch
