import {
  faArrowDownShortWide,
  faArrowUpWideShort,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  ToggleButton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import Loading from 'components/molecules/Loading'
import RewardsFilterMini from 'components/molecules/RewardsFilterMini'
import RewardsListItem from 'components/molecules/RewardsListItem'
import { ChangeEvent } from 'react'
import { RewardsResponse, SortOption } from 'types/RewardTypes'

interface Props {
  sortBy: SortOption | null
  isDownMd: boolean
  sortOptions: SortOption[]
  rewardsList: RewardsResponse[]
  rewardsListLoading: boolean
  sortByDirection: 'asc' | 'desc'
  currentPage: number
  totalPage: number
  onSortByChange: (option: SortOption) => void
  onSortByDirectionChange: (direction: 'asc' | 'desc') => void
  onFilterToggle: () => void
  onPaginationChange: (event: ChangeEvent<unknown>, value: number) => void
}

const SortItem = styled(MenuItem)({
  fontSize: '0.875rem',
})

const RewardsListItemWrapper = ({
  sortBy,
  isDownMd = false,
  sortOptions = [],
  rewardsList,
  rewardsListLoading,
  currentPage,
  totalPage,
  sortByDirection,
  onSortByChange,
  onSortByDirectionChange,
  onFilterToggle,
  onPaginationChange,
}: Props): JSX.Element => {
  if (rewardsListLoading) {
    return <Loading />
  }

  const handleSortMenuSlect = (option: SortOption): void => {
    onSortByChange(option)
  }

  const handleSortByChange = (value: string): void => {
    const option = sortOptions.find(option => option.value === value)
    if (option) {
      onSortByChange(option)
    }
  }

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          mb: 2,
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'start',
            mr: { xs: 0, md: '8rem' },
          }}
        >
          {isDownMd ? (
            <RewardsFilterMini
              sortOptions={sortOptions}
              onFilterToggle={onFilterToggle}
              onSortSelect={handleSortMenuSlect}
            />
          ) : (
            <FormControl sx={{ minWidth: 170 }} size="small">
              <InputLabel
                id="sort_by"
                sx={{ color: '#0C1C2A', fontSize: '0.875rem' }}
              >
                Sort By
              </InputLabel>
              <Select
                labelId="sort_by"
                id="sort_by"
                label="Sort By "
                value={sortBy ? sortBy.value.toString() : ''}
                onChange={(event: SelectChangeEvent): void =>
                  handleSortByChange(event.target.value)
                }
                sx={{ fontSize: '0.875rem' }}
              >
                {sortOptions.map(option => (
                  <SortItem key={option.value} value={option.value}>
                    {option.label}
                  </SortItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Tooltip
            title={sortByDirection === 'asc' ? 'Ascending' : 'Descending'}
            placement="top"
          >
            <ToggleButton
              value="direction"
              color="primary"
              size="small"
              selected={sortByDirection === 'asc'}
              onChange={(): void => {
                onSortByDirectionChange(
                  sortByDirection === 'asc' ? 'desc' : 'asc'
                )
              }}
              sx={{
                ml: isDownMd ? 0 : 2,
                p: isDownMd ? '0.6179rem 0.554rem' : '0.555rem 0.492rem',
                border: isDownMd ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
                color: '#0c1c2a',
              }}
            >
              {sortByDirection === 'asc' ? (
                <FontAwesomeIcon icon={faArrowUpWideShort} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faArrowDownShortWide} size="lg" />
              )}
            </ToggleButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {rewardsList.length ? (
          rewardsList.map(item => (
            <Grid item xs={12} sm={6} md={4} key={item.contentfulSysId}>
              <RewardsListItem sysId={item.contentfulSysId} />
            </Grid>
          ))
        ) : (
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '40vh',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Typography
              variant="small"
              paragraph
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              No Data Available Check Filters.
            </Typography>
          </Grid>
        )}
      </Grid>
      {rewardsList.length && totalPage > 1 ? (
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            my: 2,
          }}
        >
          <Pagination
            color="primary"
            count={totalPage}
            page={currentPage}
            onChange={onPaginationChange}
            disabled={rewardsListLoading}
          />
        </Box>
      ) : null}
    </>
  )
}

export default RewardsListItemWrapper
