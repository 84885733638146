import { Box, Container, Typography } from '@mui/material'
import MediaCardWrapper from 'components/organisms/MediaCardsWrapper'
import { useNavigate } from 'react-router-dom'

const HomeContentSlider = (): JSX.Element => {
  const navigate = useNavigate()

  const handleReadMore = (to: string): void => {
    navigate(`/dashboard/${to}`)
  }

  const getRandomSkip = (min: number, max: number): number => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  return (
    <Box component="section">
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: { xs: 4, md: 8 },
          mb: { xs: 8, md: 8 },
        }}
        maxWidth="lg"
        className="home-content-slider-contanier"
      >
        <Typography component="h1" variant="h5" sx={{ mb: { xs: 3, md: 6 } }}>
          Educate yourself while{' '}
          <Typography
            component="span"
            variant="h5"
            sx={{
              borderBottom: '5px solid #7FCEDE',
              fontSize: 'inherit',
              fontWeight: 'inherit',
              fontFamily: 'inherit',
            }}
          >
            earning points.
          </Typography>
        </Typography>
        <MediaCardWrapper
          onReadMore={handleReadMore}
          limit={3}
          skip={getRandomSkip(0, 15)}
          cardElevation={0}
        />
      </Container>
    </Box>
  )
}

export default HomeContentSlider
