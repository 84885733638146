import { useQuery } from '@apollo/client'
import { Alert } from '@mui/material'
import Loading from 'components/molecules/Loading'
import MediaCard from 'components/molecules/MediaCard'
import { useEffect, useState } from 'react'
import Slick, { Settings } from 'react-slick'
import { BASE_CONTENT_LIST } from 'services/contentful/BaseContentList'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { BaseContentItemType } from 'types/BaseContentTypes'
import { dateFormatNoYear } from 'utils/date'

interface Props {
  type?: 'baseContent' | 'article' | 'flipcard' | 'video'
  arrows?: boolean | undefined
  xsArrows?: boolean | undefined
  showReadMore?: boolean
  limit?: number
  skip?: number
  cardElevation?: number
  onReadMore?: (to: string) => void
}

const MediaCardList = ({
  type,
  arrows,
  xsArrows,
  showReadMore,
  onReadMore,
  limit,
  skip,
  cardElevation = 1,
}: Props): JSX.Element => {
  const slickSettings: Settings = {
    lazyLoad: 'ondemand',
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: arrows,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: arrows,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: xsArrows,
        },
      },
    ],
  }
  const [contentList, setContentList] = useState<BaseContentItemType[]>([])

  const { data, loading, error } = useQuery(BASE_CONTENT_LIST, {
    variables: { limit: limit, skip: skip != undefined ? skip : 0 },
  })

  useEffect(() => {
    if (!loading && !error && data?.baseContentCollection?.items?.length) {
      setContentList(data.baseContentCollection.items)
    }
  }, [loading, data, error])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return (
    <Slick {...slickSettings}>
      {contentList
        ? contentList.map(item => (
            <MediaCard
              type={type}
              key={item.sys.id}
              id={item.sys.id}
              featuredImage={item.image.url}
              heading={item.title}
              shortText={item.abstract}
              tags={item?.contentfulMetadata?.tags}
              whom="By Recyclebank"
              date={dateFormatNoYear(
                item.publishDay,
                item.publishMonth,
                item.publishYear
              )}
              points={item.points}
              more={`${item.content.__typename}${item.slug}?id=${item.content.sys.id}`}
              elevation={cardElevation}
              showReadMore={showReadMore}
              onReadMore={onReadMore}
            />
          ))
        : null}
    </Slick>
  )
}

const MediaCardWrapper = ({
  type = 'baseContent',
  arrows = true,
  xsArrows = true,
  showReadMore,
  onReadMore,
  limit = 6,
  skip = 0,
  cardElevation = 1,
}: Props): JSX.Element => {
  return (
    <div className="rb-media-card-wrapper">
      <MediaCardList
        type={type}
        arrows={arrows}
        xsArrows={xsArrows}
        showReadMore={showReadMore}
        onReadMore={onReadMore}
        limit={limit}
        skip={skip}
        cardElevation={cardElevation}
      />
    </div>
  )
}

export default MediaCardWrapper
