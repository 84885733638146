import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress, Typography, styled } from '@mui/material'
import { useAtom } from 'jotai'
import { NavLink } from 'react-router-dom'
import { isUserLoadingAtom } from 'stores/user'

interface Props {
  points: number
}

const Icon = styled(FontAwesomeIcon)({
  position: 'relative',
  top: '0.5rem',
  color: 'white',
})

const ScoreBadge = ({ points = 0 }: Props): JSX.Element => {
  const [userLoading] = useAtom(isUserLoadingAtom)

  return (
    <NavLink to="../dashboard/settings" style={{ textDecoration: 'none' }}>
      <Box
        className="rb-score-badge"
        sx={{
          backgroundColor: '#FFA000',
          minWidth: '7.4375rem',
          border: '5px solid #FFFFFF',
          borderRadius: '0.313rem',
          zIndex: 2,
          position: 'relative',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '-435px -435px, 0 0',
          backgroundImage:
            'linear-gradient(to left top, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 37%, rgba(255,255,255,0.8) 45%, rgba(255,255,255,0) 50%)',
          backgroundSize: '250% 250%, 100% 100%',
          transition: 'background-position 0s ease',
          userSelect: 'none',
          '&:hover': {
            backgroundPosition: '0 0, 0 0',
            transitionDuration: '0.5s',
          },
        }}
      >
        <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.15)',
            borderRadius: '0 0 50% 50%',
            p: 1,
            textAlign: 'center',
          }}
        >
          <Icon
            icon={faStar}
            sx={{
              mr: '0.2rem',
              fontSize: {
                xs: '1rem',
                md: '1.2rem',
              },
            }}
          />
          <Icon
            icon={faStar}
            sx={{
              mx: '0.1rem',
              fontSize: {
                xs: '1.5rem',
                md: '2rem',
              },
            }}
          />
          <Icon
            icon={faStar}
            sx={{
              ml: '0.2rem',
              fontSize: {
                xs: '1rem',
                md: '1.2rem',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            height: { xs: 75, md: 100 },
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexGrow: 1,
            fontSize: { xs: '0.625rem', md: '1.25rem' },
            flexDirection: 'column',
          }}
        >
          {userLoading ? (
            <CircularProgress sx={{ mt: '1.5rem' }} color="inherit" size={25} />
          ) : (
            <>
              <Typography
                variant={points > 999 ? 'h4' : 'h3'}
                paragraph
                sx={{
                  fontWeight: 700,
                  marginBottom: 0,
                  lineHeight: 1,
                  mt: '0.2rem',
                }}
              >
                {points}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  textTransform: 'uppercase',
                  marginBottom: 0,
                  lineHeight: 1,
                  fontWeight: 600,
                  mt: '0.2rem',
                }}
              >
                points
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </NavLink>
  )
}

export default ScoreBadge
