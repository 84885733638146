import { Chip } from '@mui/material'
import { ChipProps } from '@mui/material/Chip'

const Tag = (props: ChipProps): JSX.Element => {
  return (
    <Chip
      className="tag"
      {...props}
      component="span"
      size={props.size ? props.size : 'small'}
      variant="outlined"
      sx={{
        ...props.sx,
        ...{ fontSize: '0.55rem', fontWeight: 600, p: 0.5 },
      }}
    />
  )
}

export default Tag
