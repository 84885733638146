import axios from 'axios'
import Admin from 'services/app/admin'
import Client from 'services/app/client'
import Rewards from 'services/app/rewards'
import Support from 'services/app/support'
import User from 'services/app/user'
import firebase from 'services/firebase'
import { logout } from 'stores/auth'
import { exceptionEvent } from 'utils/analytics'

// Axios init start -----------------
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

axiosInstance.interceptors.request.use(
  (request): any => {
    const originalRequest = request
    if (firebase.auth.currentUser) {
      return firebase.auth.currentUser
        .getIdToken() // always returns latest idToken
        .then(idToken => {
          //Update itToken to axios headers
          if (originalRequest.headers) {
            originalRequest.headers['Authorization'] = `Bearer ${idToken}`
          }

          return Promise.resolve(originalRequest)
        })
        .catch(() => {
          logout('/login', 'Failed to get token.')
        })
    } else {
      logout()
    }
    return request
  },
  error => {
    // GA-Track exception
    exceptionEvent(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // GA-Track exception
    exceptionEvent(error)
    if (error.response.status === 401) {
      logout('/401')
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      logout('/403')
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export { axiosInstance }

// Axios init end -----------------

export default {
  User: new User(),
  Client: new Client(),
  Support: new Support(),
  Rewards: new Rewards(),
  Admin: new Admin(),
}
