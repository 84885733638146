import { Box, Container, Grid, Typography } from '@mui/material'
import article from 'assets/img/article.svg'
import bannerImage from 'assets/img/background-img.svg'
import testEarn from 'assets/img/earn_points_latest_banner.svg'
import ScoreBadge from 'components/molecules/ScoreBadge'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import { pointsAtom } from 'stores/user'

const EarnPoints = (): JSX.Element => {
  const topBanner = bannerImage
  const latestImage = testEarn
  const articleImage = article
  const [pointBalance] = useAtom(pointsAtom)

  return (
    <AuthenticatedPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          backgroundImage: 'url(' + topBanner + ')',
          height: { xs: '8.75rem', md: '9.75rem' },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto',
          backgroundPosition: 'center',
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ position: 'relative' }}
          display="flex"
          justifyContent={{ xs: 'end', md: 'center', sm: 'center' }}
          alignItems={{ xs: 'end', md: 'center', sm: 'center' }}
          top={{ xs: '1rem', md: '0rem' }}
        >
          <Box
            component="img"
            sx={{ width: { xs: '14rem', md: '23rem' } }}
            alt="Learn-Earn-Get Rewards"
            src={latestImage}
          />
        </Grid>
        <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
          <Grid
            container
            direction="row"
            justifyContent={{ xs: 'start', md: 'end' }}
            alignItems={{ xs: 'end', md: 'center' }}
            color="white"
          >
            <Grid
              item
              sx={{
                position: 'relative',
                zIndex: 100,
                top: { md: '-5rem' },
              }}
            >
              <ScoreBadge points={pointBalance} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        component="div"
        maxWidth={false}
        sx={{
          background: '#F5F7FA',
          flexGrow: 1,
        }}
      >
        <Container
          component="div"
          maxWidth="lg"
          sx={{
            pt: { xs: 10 },
            pb: 6,
          }}
        >
          <Grid container spacing="0">
            <Grid item xs={12} md={6}>
              <Box>
                <img
                  alt="featuredImage"
                  src={articleImage}
                  loading="lazy"
                  style={{ width: '94%' }}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} md={6} sx={{ mt: { xs: 2, md: 0 } }}>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    mb: 0.5,
                    paddingTop: { md: '1rem' },
                  }}
                >
                  HOW DO POINTS WORK ?
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  sx={{
                    mb: 0,
                    paddingTop: { md: '1rem' },
                  }}
                >
                  You can earn points by learning right here! Check back every
                  day to earn more points; we add new opportunities all the
                  time. Once you’ve earned enough points, you can cash them in
                  for great deals!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AuthenticatedPageWrapper>
  )
}

export default EarnPoints
