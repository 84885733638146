import { AppBar, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'
import logoGreen from 'assets/img/recyclebank_logo_green.png'
import logoWhite from 'assets/img/recyclebank_logo_white.png'
import { Link } from 'react-router-dom'

interface Props {
  isPrimary?: boolean
  elevation?: number
}

const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: '2.1875rem',
    width: '12rem',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
  [theme.breakpoints.up('lg')]: {
    height: '2.625rem',
    width: '14rem',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
}))

const Header = ({ isPrimary = false, elevation = 0 }: Props): JSX.Element => {
  const logoSrc = isPrimary ? logoGreen : logoWhite

  return (
    <AppBar
      position="absolute"
      color="transparent"
      elevation={elevation}
      square
    >
      <Toolbar
        variant="dense"
        sx={{
          mt: { xs: 0.5, md: 1.5 },
        }}
      >
        <Link to="/">
          <Logo src={logoSrc} alt={'RecycleBank'} />
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default Header
