import { useQuery } from '@apollo/client'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import startEarning from 'assets/img/start_earning.png'
import Footer from 'components/molecules/Footer'
import HeaderHome from 'components/molecules/HeaderHome'
import Loading from 'components/molecules/Loading'
import HomeContentSlider from 'components/organisms/HomeContentSlider'
import SlideBoxWrapper from 'components/organisms/SlideBoxWrapper'
import { useNavigate } from 'react-router-dom'

// import StepBoxWrapper from 'components/organisms/StepBoxWrapper'
import { HOME_CONTENT } from '../../../services/contentful/home'

const Dashboard = (): JSX.Element => {
  const {
    loading: slideBoxLoading,
    error: slideBoxError,
    data: slideBoxData,
  } = useQuery(HOME_CONTENT, {
    variables: { isSlide: true },
  })

  const navigate = useNavigate()

  if (slideBoxLoading) {
    return <Loading />
  }

  if (slideBoxError) {
    const error: any = slideBoxError
    return <Alert severity="error">{error.message}</Alert>
  }

  return (
    <Box component="main">
      <HeaderHome isPrimary></HeaderHome>
      <Box component="section">
        {slideBoxData.homeCollection.items ? (
          <SlideBoxWrapper items={slideBoxData.homeCollection.items} />
        ) : null}
        <Box>
          <Box
            sx={{
              background: {
                lg: 'linear-gradient(90deg, rgba(240,245,251,1) 26%, rgba(255,255,255,1) 26%, rgba(255,255,255,1) 100%)',
                md: 'linear-gradient(90deg, rgba(240,245,251,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 100%)',
                xs: 'linear-gradient(90deg, rgba(240,245,251,1) 32%, rgba(255,255,255,1) 32%, rgba(255,255,255,1) 100%)',
              },
            }}
          >
            <Container maxWidth="lg" sx={{ mt: { xs: 6, md: 12 }, py: 1 }}>
              <Typography variant="caption" sx={{ my: 8 }}>
                HOW IT WORKS
              </Typography>
            </Container>
          </Box>
          <Container maxWidth="lg" sx={{ mb: { xs: 6, md: 12 } }}>
            <Typography
              variant="h5"
              sx={{ mt: { xs: 4, md: 6 }, mb: 5, fontWeight: 700 }}
            >
              Turn your points into{' '}
              <Typography
                variant="h5"
                component="span"
                sx={{ borderBottom: '5px solid #7FCEDE', fontWeight: 700 }}
              >
                savings.
              </Typography>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography display="flex" component="h6" sx={{ mb: 3.5 }}>
                  <Typography
                    component="span"
                    sx={{
                      color: '#7FCEDE',
                      mr: 1,
                      fontSize: '2rem',
                      lineHeight: 0.8,
                    }}
                  >
                    1
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: '1.05rem', fontWeight: 800 }}
                  >
                    Sign Up
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  Members can use their Recyclebank points to get great deals at
                  local businesses and exclusive discounts on sustainable goods.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography display="flex" component="h6" sx={{ mb: 3.5 }}>
                  <Typography
                    component="span"
                    sx={{
                      color: '#7FCEDE',
                      mr: 1,
                      fontSize: '2rem',
                      lineHeight: 0.8,
                    }}
                  >
                    2
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: '1.05rem', fontWeight: 800 }}
                  >
                    Earn Points
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  Earn points by participating in activities such as planting or
                  adopting trees, joining environmental education programs,
                  taking quizzes, and signing up for newsletters.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography display="flex" component="h6" sx={{ mb: 3.5 }}>
                  <Typography
                    component="span"
                    sx={{
                      color: '#7FCEDE',
                      mr: 1,
                      fontSize: '2rem',
                      lineHeight: 0.8,
                    }}
                  >
                    3
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: '1.05rem', fontWeight: 800 }}
                  >
                    Redeem Rewards
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  Sign up to start earning points. Find out if you are eligible
                  for rewards based on your geographical location.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: '#F0F5FB', mt: { xs: 10, md: 14 } }}>
          <Container maxWidth="lg" sx={{}}>
            <Grid
              container
              spacing={1}
              direction={{ xs: 'column-reverse', md: 'row' }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{ mt: { xs: 4, md: 8 }, mb: { xs: 4, md: 18 } }}
              >
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{ fontSize: '2.3rem!important' }}
                >
                  Your{' '}
                  <Typography
                    variant="h4"
                    component="span"
                    sx={{ fontWeight: 800, fontSize: '2.3rem!important' }}
                  >
                    points.
                  </Typography>
                </Typography>
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{ fontSize: '2.3rem!important' }}
                >
                  Your{' '}
                  <Typography
                    component="span"
                    sx={{ fontWeight: 800, fontSize: '2.3rem!important' }}
                  >
                    rewards.
                  </Typography>
                </Typography>
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{ fontSize: '2.3rem!important' }}
                >
                  Your{' '}
                  <Typography
                    variant="h4"
                    component="span"
                    sx={{
                      fontWeight: 800,
                      fontSize: '2.3rem!important',
                      borderBottom: '7px solid #A0CAA7',
                    }}
                  >
                    own pace.
                  </Typography>
                </Typography>
                <Typography variant="small" paragraph sx={{ mt: 5 }}>
                  It&apos;s your recycling journey. Start it with{' '}
                  <Typography
                    variant="small"
                    component="span"
                    sx={{ fontWeight: 800 }}
                  >
                    Recyclebank.
                  </Typography>
                </Typography>
                <Button
                  variant="contained"
                  onClick={(): void => navigate('/login')}
                  disableElevation
                  sx={{
                    backgroundColor: '#005E85 ',
                    borderRadius: '2.75rem',
                    color: '#F0F5FB',
                    fontSize: '0.75rem',
                    py: '0.4rem',
                    px: '1.5rem',
                    mt: 4,
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#004460',
                    },
                    '&:active': {
                      backgroundColor: '#004460',
                    },
                  }}
                  endIcon={<ArrowRightAltIcon />}
                >
                  Start Earning
                </Button>
              </Grid>
              <Grid item xs={12} md={8} sx={{}}>
                <Box
                  sx={{
                    backgroundImage: 'url(' + startEarning + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: { xs: 'cover', md: 'contain' },
                    height: { xs: '100vw', md: '100%' },
                    mt: '-41px',
                    mb: { xs: 0, md: 6 },
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <HomeContentSlider />
        {/* {stepBoxData.homeCollection.items ? (
          <StepBoxWrapper items={stepBoxData.homeCollection.items} />
        ) : null} */}
      </Box>
      <Footer />
    </Box>
  )
}

export default Dashboard
