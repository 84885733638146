import { FilterAlt, Sort } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { SortOption } from 'types/RewardTypes'

interface Props {
  sortOptions: SortOption[]
  onSortSelect: (option: SortOption) => void
  onFilterToggle: () => void
}

const RewardsFilterMini = ({
  sortOptions = [],
  onSortSelect,
  onFilterToggle,
}: Props): JSX.Element => {
  //Sort Menu
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedSortItem, setSelectedSortItem] = useState<null | SortOption>(
    null
  )
  const sortMenuOpen = Boolean(sortAnchorEl)
  const handleSortButtonClick = (event: MouseEvent<HTMLElement>): void => {
    setSortAnchorEl(event.currentTarget)
  }

  const handleSortMenuItemClick = (option: SortOption): void => {
    setSelectedSortItem(option)
    onSortSelect(option)
    setSortAnchorEl(null)
  }

  const handleSortMenuClose = (): void => {
    setSortAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        aria-label="sort"
        color="secondary"
        onClick={handleSortButtonClick}
      >
        <Sort fontSize="small" />
      </IconButton>
      <Menu
        id="sort-menu"
        anchorEl={sortAnchorEl}
        open={sortMenuOpen}
        onClose={handleSortMenuClose}
        MenuListProps={{
          'aria-labelledby': 'sort-button',
          role: 'listbox',
          dense: true,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0rem 0.3rem .4rem rgba(0, 0, 0, 25%))',
            mt: 0.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '3.3rem',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {sortOptions.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedSortItem?.value}
            onClick={(): void => handleSortMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        aria-label="filter"
        color="secondary"
        onClick={onFilterToggle}
      >
        <FilterAlt fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default RewardsFilterMini
