import { Grid, Typography } from '@mui/material'
import ActivityListWrapper from 'components/molecules/ActivityListWrapper'
import User from 'components/molecules/User'
import { ActivityListItemType } from 'types/UserSettingsTypes'

interface Props {
  activityList: ActivityListItemType[]
  activityListLoading: boolean
  address: string
  displayName: string
  email: string
  photoURL: string
  onViewAllActivity: () => void
  onAccountSettings: () => void
  onPrintRewards?: (rewardFulFillmentId: string) => void
}

const ViewProfileWrapper = ({
  activityList = [],
  activityListLoading,
  displayName,
  email,
  address,
  photoURL,
  onViewAllActivity,
  onAccountSettings,
  onPrintRewards,
}: Props): JSX.Element => {
  return (
    <Grid
      container
      justifyContent="start"
      alignItems="center"
      display="flex"
      spacing={2}
      sx={{
        mb: 2,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        mt: { xs: 6, md: 0 },
      }}
      className="rb-view-profile-Wrapper"
    >
      <Grid item xs={12} md={4} sx={{ mt: 4.2 }}>
        <User
          address={address}
          displayName={displayName}
          email={email}
          photoURL={photoURL}
          onAccountSettings={onAccountSettings}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6.5}
        sx={{
          mt: { xs: 4, md: 0 },
          mb: { xs: 2, md: 0 },
        }}
      >
        {activityList.length ? (
          <>
            <Typography
              variant="body2"
              sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 2 }}
            >
              Latest Activities
            </Typography>

            <ActivityListWrapper
              activityList={activityList}
              loading={activityListLoading}
              onViewAllActivity={onViewAllActivity}
              onPrintRewards={onPrintRewards}
            />
          </>
        ) : (
          <Typography
            variant="small"
            paragraph
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
              m: 0,
              width: '100%',
            }}
          >
            No Activities Found.
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default ViewProfileWrapper
