import {
  faArrowsRotate,
  faImages,
  faNewspaper,
  faQuestion,
  faVideo,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StarIcon from '@mui/icons-material/Star'
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import Tag from 'components/atoms/Tag'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'

interface Props {
  type?: string
  id: string
  heading: string
  featuredImage: string
  size?: 'large' | 'normal' | 'small'
  shortText?: string
  whom?: string
  date?: string
  commentsCount?: number
  points?: number
  tags?: { label: string; type: string }[]
  isLast?: boolean
  more?: string
}

const Img = styled('img')(({ theme }) => ({
  margin: '0',
  display: 'block',
  maxWidth: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center top',
  width: '100%',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
}))

const FaIcon = styled(FontAwesomeIcon)({
  position: 'absolute',
  bottom: '0.313rem',
  color: 'white',
})

const typeIcon = (type: string, size: string): JSX.Element | string => {
  const iconSize = size !== 'small' ? 'sm' : 'xs'

  switch (type) {
    case 'Article':
      return (
        <FaIcon
          icon={faNewspaper}
          size={iconSize}
          style={{
            position: 'absolute',
            bottom: size !== 'small' ? '0.313rem' : '0.3rem',
          }}
        />
      )
    case 'Flipcard':
      return (
        <FaIcon
          icon={faArrowsRotate}
          size={iconSize}
          style={{
            position: 'absolute',
            bottom: size !== 'small' ? '0.313rem' : '0.3rem',
          }}
        />
      )
    case 'Video':
      return (
        <FaIcon
          icon={faVideo}
          size={iconSize}
          style={{
            position: 'absolute',
            bottom: size !== 'small' ? '0.313rem' : '0.3rem',
          }}
        />
      )
    case 'Slideshow':
      return (
        <FaIcon
          icon={faImages}
          size={iconSize}
          style={{
            position: 'absolute',
            bottom: size !== 'small' ? '0.313rem' : '0.3rem',
          }}
        />
      )
    case 'Quiz':
      return (
        <FaIcon
          icon={faQuestion}
          size={iconSize}
          style={{
            position: 'absolute',
            bottom: size !== 'small' ? '0.313rem' : '0.3rem',
          }}
        />
      )
    default:
      return ''
  }
}

const ContentCard = ({
  type,
  id,
  heading,
  featuredImage,
  size = 'normal',
  shortText,
  whom,
  date,
  // commentsCount = 0,
  points = 0,
  tags = [],
  isLast = false,
  more = '#',
}: Props): JSX.Element => {
  return (
    <Paper
      id={'article_' + id}
      className="rb-story-card-container"
      elevation={0}
      sx={{
        p: 0,
        margin: 'auto',
        maxWidth: '100%',
        flexGrow: 1,
        background: size === 'large' ? '#F5F7FA' : 'white',
        border: size === 'normal' ? '1px solid #BDC2CA' : 'none',
        borderRadius: '0.313rem',
        mb: isLast ? 0 : 2,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Grid container spacing="0">
        <Grid
          item
          md={5}
          className="rb-featured-image"
          sx={{ position: 'relative' }}
        >
          {type ? (
            <Box
              className="ribbon"
              sx={{
                position: 'absolute',
                top: 0,
                height: size !== 'small' ? '2.813rem' : '2rem',
                backgroundColor: 'transparent',
                right: size !== 'small' ? '0.9375rem' : '0.625rem',
                border:
                  size !== 'small'
                    ? 'solid 0.938rem #FFA000'
                    : 'solid 0.625rem #FFA000',
                borderBottom:
                  size !== 'small'
                    ? 'solid 0.938rem transparent'
                    : 'solid 0.625rem transparent',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {typeIcon(type, size)}
              </Box>
            </Box>
          ) : (
            ''
          )}
          <Img alt="featuredImage" src={featuredImage} loading="lazy" />
        </Grid>
        <Grid item xs={12} md={7} sm container>
          <Grid item xs container direction="column" spacing={0}>
            <Grid
              item
              xs
              sx={{
                p: size === 'large' ? 3 : size === 'normal' ? 2 : 1.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: size === 'small' ? 'center' : 'start',
                alignItems: 'flex-start',
              }}
              className="rb-story-card-content"
            >
              {size !== 'small' && tags.length > 0 && (
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  {tags.map((tag, i) => (
                    <Tag key={i} label={tag.label} />
                  ))}
                </Stack>
              )}
              <Typography
                variant={
                  size === 'large' ? 'h3' : size === 'small' ? 'small' : 'h6'
                }
                fontWeight={size === 'large' ? 700 : 600}
                gutterBottom
                sx={{
                  textTransform: size === 'small' ? 'capitalize' : 'uppercase',
                  my: 1,
                }}
              >
                {heading}
              </Typography>
              {size !== 'small' && (
                <>
                  {size === 'normal' && (
                    <Typography variant="xsmall" component="small">
                      By {whom} | {date}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    paragraph
                    component="div"
                    sx={{
                      mt: size === 'large' ? 0 : 1,
                      mb: 1,
                    }}
                  >
                    <LinesEllipsis
                      text={shortText}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </Typography>
                </>
              )}
            </Grid>
            {size !== 'small' && (
              <Grid
                item
                className="rb-story-card-footer"
                sx={{ px: { xs: 2, md: size === 'large' ? 3 : 2 } }}
              >
                <Stack
                  direction={size === 'large' ? 'row' : 'row-reverse'}
                  justifyContent="space-between"
                  sx={{
                    my: size === 'large' ? 2 : 1,
                  }}
                >
                  <Button
                    to={more}
                    component={Link}
                    variant="contained"
                    disableElevation
                  >
                    Read More
                  </Button>
                  <Typography
                    variant="small"
                    textTransform="uppercase"
                    sx={{
                      fontWeight: 'inherit',
                      color: '#FFA000',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <StarIcon fontSize="small" sx={{ mr: 0.5 }} />
                    {points} points
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContentCard
