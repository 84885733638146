import { AxiosResponse } from 'axios'
import { atom } from 'jotai'
import AppService from 'services/app'
import { ActivityListItemType } from 'types/UserSettingsTypes'

export const activityAtom = atom<ActivityListItemType[]>([])
export const recentActivityAtom = atom<ActivityListItemType[]>([])

export const getActivity = async (
  userId: string,
  startDate?: string | undefined,
  endDate?: string | undefined,
  type?: 'rewards' | 'points',
  page?: number | undefined,
  itemsPerPage?: number | undefined,
  descending?: boolean,
  all = false
): Promise<
  AxiosResponse<
    {
      noOfPages: number
      currentPage: number
      noOfRows: number
      pointHistoryResponses: ActivityListItemType[]
    },
    any
  >
> => {
  try {
    return await AppService.User.getData(
      'activity',
      {
        userId,
        startDate,
        endDate,
        type,
        page,
        itemsPerPage,
        descending,
        all,
      },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
