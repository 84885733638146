import { gql } from '@apollo/client'

export const GET_GENERIC_CONTENT_BY_ID = gql`
  query getGenericPagesById($id: Int!) {
    genericPagesCollection(limit: 1, where: { id: $id }) {
      items {
        sys {
          id
          publishedAt
        }
        id
        title
        featuredImage {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        content {
          json
        }
      }
    }
  }
`
