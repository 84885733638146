import { Tab as MuiTab, styled } from '@mui/material'

interface StyledTabProps {
  label: string
  value: string
}

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(12),
  marginRight: theme.spacing(1),
  color: '#0C1C2A',
  '&.Mui-selected': {
    color: '#008F6B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}))

export default Tab
