import { Box } from '@mui/material'
import SlideBox from 'components/molecules/SlideBox'
import { SLIDE_ITEM } from 'types/HomeTypes'

const SlideBoxWrapper = ({ items }: any): JSX.Element => {
  return (
    <Box component="section">
      {items.map((details: SLIDE_ITEM, i: number) => (
        <SlideBox
          key={details.id}
          first={i === 1}
          backgroundImage={details.image.url}
          backgroundPosition={details.bckImgPosition}
          alignItems={details.alignContent ? details.alignContent : 'start'}
          title={details.title}
          subTitle={details.subtitle}
          description={details.description}
          contentWidth={
            details.contentWidth ? details.contentWidth + 'px' : undefined
          }
          contentBckColor={
            details.contentBckColor ? details.contentBckColor : undefined
          }
          contentShadow={details.contentShadow ? details.contentBckColor : 0}
          contentColor={details.contentColor ? details.contentColor : undefined}
        />
      ))}
    </Box>
  )
}

export default SlideBoxWrapper
