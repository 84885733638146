import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import logoGreen from 'assets/img/recyclebank_logo_green.png'
import logoWhite from 'assets/img/recyclebank_logo_white.png'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  isPrimary?: boolean
}

const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: '2.1875rem',
    width: '12rem',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
  [theme.breakpoints.up('lg')]: {
    height: '2.625rem',
    width: '14rem',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
}))

const pages: { name: string; to: string }[] = [
  { name: 'Sign Up', to: 'register' },
]

const HeaderHome = ({ isPrimary = false }: Props): JSX.Element => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  const navigate = useNavigate()
  const logoSrc = isPrimary ? logoGreen : logoWhite

  return (
    <AppBar position="absolute" color="transparent" elevation={0} square>
      <Toolbar variant="dense">
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'primary'}
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'block' },
            mt: { xs: 0.5, md: 1.5 },
          }}
        >
          <Logo src={logoSrc} alt={'RecycleBank'} />
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{ color: 'white' }}
          >
            {anchorElNav ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color={'primary'}
            alignItems="center"
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              mt: 0.5,
            }}
          >
            <Logo src={logoSrc} alt={'RecycleBank'} />
          </Typography>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map(page => (
              <MenuItem
                key={page.name}
                onClick={(): void => navigate(`/${page.to}`)}
              >
                <Typography textAlign="center">{page.name}</Typography>
              </MenuItem>
            ))}
            <MenuItem color="primary" onClick={(): void => navigate('/login')}>
              Login
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems="center">
          {pages.map(page => (
            <Link
              onClick={(): void => navigate(`/${page.to}`)}
              key={page.name}
              underline="none"
              sx={{
                color: 'white',
                display: 'block',
                mx: 1,
                fontSize: '0.9rem',
                '&:hover': {
                  cursor: 'pointer',
                  color: '#005E85',
                },
              }}
            >
              {page.name}
            </Link>
          ))}
          <Button
            variant="contained"
            onClick={(): void => navigate('/login')}
            disableElevation
            sx={{
              ml: 3,
              backgroundColor: '#F0F5FB',
              borderRadius: '2.75rem',
              color: '#005E85',
              fontSize: '0.9rem',
              py: '0.5rem',
              px: '1.5rem',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#e7f1fd',
              },
              '&:active': {
                backgroundColor: '#e7f1fd',
              },
            }}
            endIcon={<ArrowRightAltIcon />}
          >
            Login
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderHome
