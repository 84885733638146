import { NetworkStatus, useQuery } from '@apollo/client'
import {
  Alert,
  Box,
  Pagination,
  PaginationItem,
  Typography,
} from '@mui/material'
import SearchBox from 'components/atoms/SearchBox'
import ContentTypeList from 'components/molecules/ContentTypeList'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import {
  GET_FLIPCARD_LIST,
  SEARCH_FLIPCARD_LIST,
} from 'services/contentful/ContentLists'
import { alertAtom } from 'stores'
import { GenericContentItemType } from 'types/ContentListTypes'

interface Props {
  title: string
}

const FlipcardListWrapper = ({ title }: Props): JSX.Element => {
  // track page from URL
  const [searchParams, setSearchParams] = useSearchParams()
  let page = parseInt(searchParams.get('page') || '1', 10)
  const [searchString, setSearchString] = useState<string>('')
  // scroll top
  const pageHeadingRef = useRef<null | HTMLElement>(null)
  const executeScroll = (): void => {
    if (pageHeadingRef && pageHeadingRef.current)
      pageHeadingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }
  const [currentPage, setCurrentPage] = useState(page)
  const [totalPage, setTotalPage] = useState(1)
  const [pageSize] = useState(5)
  const [flipcardList, setFlipcardList] = useState<GenericContentItemType[]>([])
  const [contentLoading, setContentLoading] = useState(false)
  const [, setAlert] = useAtom(alertAtom)
  const { data, loading, error, networkStatus, fetchMore, refetch } =
    searchString
      ? useQuery(SEARCH_FLIPCARD_LIST, {
          variables: {
            limit: pageSize,
            skip: (page - 1) * pageSize,
            searchString: searchString,
          },
          notifyOnNetworkStatusChange: true,
        })
      : useQuery(GET_FLIPCARD_LIST, {
          variables: { limit: pageSize, skip: (page - 1) * pageSize },
          notifyOnNetworkStatusChange: true,
        })

  const resetList = (): void => {
    if (searchParams.get('page')) {
      searchParams.delete('page')
      page = parseInt('1', 10)
      setCurrentPage(page)
      setSearchParams(searchParams)
    }
    setFlipcardList([])
    setTotalPage(1)
  }

  const onSearchInput = (searchString: string): any => {
    resetList()
    if (searchParams.get('page')) {
      searchParams.delete('page')
      page = parseInt('1', 10)
      setCurrentPage(page)
      setSearchParams(searchParams)
    }
    setSearchString(searchString)
    refetch({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      searchString: searchString,
    })
  }

  useEffect(() => {
    if (!loading && data?.flipcardCollection?.items?.length) {
      setFlipcardList(data.flipcardCollection.items)
      const totalPages = data?.flipcardCollection?.total
        ? Math.ceil(data?.flipcardCollection?.total / pageSize)
        : 0
      setTotalPage(totalPages)
    }
  }, [loading, data])

  const loadingMoreContent = networkStatus === NetworkStatus.fetchMore

  if (loading && !loadingMoreContent) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  const handleChange = (event: ChangeEvent<unknown>, value: number): void => {
    setContentLoading(true)
    const skip = (value - 1) * pageSize
    fetchMore({
      variables: {
        skip: skip,
      },
    })
      .then(response => {
        if (
          !response.loading &&
          response?.data?.flipcardCollection?.items.length
        ) {
          setCurrentPage(value)
          setFlipcardList(response.data.flipcardCollection.items)
        }
        executeScroll()
        setContentLoading(false)
      })
      .catch(() => {
        setContentLoading(false)
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to get content. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  return (
    <Box className="rb_flipcard_list_wrapper">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="h4"
          align="left"
          fontWeight="600"
          paragraph
          textTransform="uppercase"
          sx={{ mb: 2 }}
          ref={pageHeadingRef}
        >
          {title}
        </Typography>
        <SearchBox
          placeHolder="Search Flipcards..."
          onChange={onSearchInput}
          defaultValue={searchString}
        />
      </Box>
      <ContentTypeList data={flipcardList} type="Flipcard" />
      {flipcardList && flipcardList.length ? (
        <Box
          component="div"
          sx={{
            mt: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Pagination
            color="primary"
            page={currentPage}
            count={totalPage}
            onChange={handleChange}
            disabled={contentLoading}
            renderItem={(item): JSX.Element => (
              <PaginationItem
                component={Link}
                to={`/dashboard/flipcard${
                  item.page === 1 ? '' : `?page=${item.page}`
                }`}
                {...item}
              />
            )}
          />
        </Box>
      ) : (
        <Typography paragraph>No Flipcards Found.</Typography>
      )}
    </Box>
  )
}

export default FlipcardListWrapper
