import { Close } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  Typography,
} from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import SearchBox from 'components/atoms/SearchBox'
import { useState } from 'react'
import { FilterOption, RewardCategoryItemType } from 'types/RewardTypes'

interface Props {
  isDownMd?: boolean
  filterOptions: FilterOption[]
  categoriesList: RewardCategoryItemType[]
  typeOptions: FilterOption[]
  drawerstate?: boolean
  onDrawerClose?: () => void
  onPointsFilterSelect: (option: FilterOption) => void
  onTypeSelect: (option: FilterOption) => void
  onCategorySelect: (option: RewardCategoryItemType) => void
  onRewardsSearch: (searchString: string) => void
}

interface FilterContentProps {
  isDownMd?: boolean
  filterOptions: FilterOption[]
  categoriesList: RewardCategoryItemType[]
  typeOptions: FilterOption[]
  onClose?: () => void
  onPointsFilterSelect: (option: FilterOption) => void
  onCategorySelect: (option: RewardCategoryItemType) => void
  onTypeSelect: (option: FilterOption) => void
  onRewardsSearch: (searchString: string) => void
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(245 247 250)',
  minHeight: '2.6875rem',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(0),
  },
  '&.Mui-expanded': {
    minHeight: '2.6875rem',
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
    },
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const FilterContent = ({
  isDownMd = false,
  filterOptions = [],
  typeOptions = [],
  categoriesList = [],
  onPointsFilterSelect,
  onCategorySelect,
  onTypeSelect,
  onRewardsSearch,
  onClose,
}: FilterContentProps): JSX.Element => {
  const [pointsExpanded, setPointsExpanded] = useState<boolean>(true)
  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(true)
  const [typesExpanded, setTypesExpanded] = useState<boolean>(true)

  const togglePoints = (): void => {
    setPointsExpanded(!pointsExpanded)
  }

  const toggleCategories = (): void => {
    setCategoriesExpanded(!categoriesExpanded)
  }

  const toggleTypes = (): void => {
    setTypesExpanded(!typesExpanded)
  }

  const handleFilterSelect = (option: FilterOption) => () => {
    onPointsFilterSelect(option)
  }

  const handleCategorySelect = (option: RewardCategoryItemType) => () => {
    onCategorySelect({ ...option, checked: !option.checked })
  }

  const handleTypeChange = (option: FilterOption) => () => {
    onTypeSelect({ ...option, checked: !option.checked })
  }

  const onSearchInput = (searchString: string): any => {
    onRewardsSearch(searchString)
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: { xs: '#F5F7FA', md: '#fff' },
          px: { xs: '1rem', md: 0 },
          py: { xs: '0.2rem', md: 0 },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: { xs: 0, md: 2 },
        }}
      >
        <Typography
          variant="body2"
          paragraph
          sx={{
            fontWeight: 700,
            textTransform: 'uppercase',
            m: 0,
          }}
        >
          Filter by
        </Typography>
        {isDownMd ? (
          <IconButton aria-label="close" color="primary" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        ) : null}
      </Box>
      {isDownMd ? <Divider /> : null}
      <Box sx={{ mt: '1rem', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            backgroundColor: '#F5F7FA',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderBottom: 0,
            py: 0.5,
          }}
        >
          <SearchBox
            placeHolder="Search Rewards..."
            mode="light"
            onChange={onSearchInput}
            width={22}
          />
        </Box>
        {typeOptions && typeOptions.length ? (
          <Accordion
            expanded={typesExpanded}
            onChange={toggleTypes}
            elevation={0}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="type-content"
              id="type-header"
            >
              <Typography
                variant="button"
                sx={{ fontSize: '0.75rem', fontWeight: 600 }}
              >
                Reward Type
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: '100%',
                }}
              >
                {typeOptions.map(typeOption => {
                  const labelId = `type-checkbox-list-label-${typeOption.value}`

                  return (
                    <ListItem key={typeOption.value} disablePadding>
                      <ListItemButton
                        role={undefined}
                        dense
                        onClick={handleTypeChange(typeOption)}
                        sx={{ pl: 0.5 }}
                      >
                        <Radio
                          checked={typeOption.checked}
                          size="small"
                          sx={{ color: '#BDC2CA', p: 0, mr: 1 }}
                        />
                        <ListItemText
                          disableTypography
                          id={labelId}
                          primary={typeOption.label}
                          sx={{ fontSize: '0.85rem', fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Accordion
          expanded={pointsExpanded}
          onChange={togglePoints}
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Points-content"
            id="Points-header"
          >
            <Typography
              variant="button"
              sx={{ fontSize: '0.75rem', fontWeight: 600 }}
            >
              Points
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                width: '100%',
              }}
            >
              {filterOptions.map(option => {
                const labelId = `checkbox-list-label-${option.label}`

                return (
                  <ListItem key={option.value} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleFilterSelect(option)}
                      dense
                    >
                      <Checkbox
                        size="small"
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        checked={option.checked}
                        inputProps={{ 'aria-labelledby': labelId }}
                        sx={{ color: '#BDC2CA', p: 0, mr: 1 }}
                      />
                      <ListItemText
                        disableTypography
                        id={labelId}
                        primary={option.label}
                        sx={{ fontSize: '0.85rem', fontWeight: 500 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        {categoriesList && categoriesList.length ? (
          <Accordion
            expanded={categoriesExpanded}
            onChange={toggleCategories}
            elevation={0}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="categories-content"
              id="categories-header"
            >
              <Typography
                variant="button"
                sx={{ fontSize: '0.75rem', fontWeight: 600 }}
              >
                categories
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: '100%',
                }}
              >
                {categoriesList.map(option => {
                  const labelId = `category-checkbox-list-label-${option.sys.id}`

                  return (
                    <ListItem key={option.sys.id} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleCategorySelect(option)}
                        dense
                      >
                        <Checkbox
                          size="small"
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          checked={option.checked}
                          inputProps={{ 'aria-labelledby': labelId }}
                          sx={{ color: '#BDC2CA', p: 0, mr: 1 }}
                        />
                        <ListItemText
                          disableTypography
                          id={labelId}
                          primary={option.title}
                          sx={{ fontSize: '0.85rem', fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
    </>
  )
}

const RewardsFilter = ({
  isDownMd = false,
  filterOptions = [],
  categoriesList = [],
  typeOptions = [],
  drawerstate = false,
  onPointsFilterSelect,
  onCategorySelect,
  onTypeSelect,
  onRewardsSearch,
  onDrawerClose,
}: Props): JSX.Element => {
  return isDownMd ? (
    <Drawer anchor="bottom" open={drawerstate} onClose={onDrawerClose}>
      <FilterContent
        isDownMd={isDownMd}
        onClose={onDrawerClose}
        filterOptions={filterOptions}
        typeOptions={typeOptions}
        categoriesList={categoriesList}
        onPointsFilterSelect={onPointsFilterSelect}
        onTypeSelect={onTypeSelect}
        onCategorySelect={onCategorySelect}
        onRewardsSearch={onRewardsSearch}
      />
    </Drawer>
  ) : (
    <FilterContent
      isDownMd={isDownMd}
      filterOptions={filterOptions}
      typeOptions={typeOptions}
      categoriesList={categoriesList}
      onPointsFilterSelect={onPointsFilterSelect}
      onTypeSelect={onTypeSelect}
      onCategorySelect={onCategorySelect}
      onRewardsSearch={onRewardsSearch}
    />
  )
}

export default RewardsFilter
