import { useQuery } from '@apollo/client'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material'
import Loading from 'components/molecules/Loading'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { REWARD_BY_ID } from 'services/contentful/Rewards'
import { RewardsItemType } from 'types/RewardTypes'

interface Props {
  sysId: string
}

const RewardsListItem = ({ sysId }: Props): JSX.Element => {
  const [rewardData, setRewardData] = useState<RewardsItemType | null>()

  const {
    loading: APILoading,
    error: APIError,
    data: data,
  } = useQuery(REWARD_BY_ID, {
    variables: { id: sysId },
  })

  useEffect(() => {
    if (!APILoading) {
      if (data) {
        setRewardData(data.rewards)
      }
    }
  }, [APILoading, APIError, data])

  if (APILoading) {
    return <Loading />
  }

  if (APIError) {
    const error: any = APIError
    return <Alert severity="error">Loading error :{error.message}</Alert>
  }

  return (
    <Card
      id={sysId}
      sx={{
        backgroundColor: '#F5F7FA',
        p: '0.2rem',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {rewardData ? (
        <>
          {rewardData.image && rewardData.image.url ? (
            <CardMedia
              component="img"
              image={
                rewardData.image && rewardData.image.url
                  ? rewardData.image.url
                  : ''
              }
              alt={rewardData.rewardTitle}
              sx={{
                objectFit: 'contain',
                objectPosition: 'center center',
                height: 150,
              }}
            />
          ) : (
            <Box
              sx={{
                height: 150,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="h5">
                {rewardData.partner.partnerName}
              </Typography>
            </Box>
          )}
          <CardContent sx={{ pb: 0.5 }}>
            <Tooltip title={rewardData.rewardTitle} placement="top" arrow>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: '0.8rem!important',
                  mb: 0.5,
                  textTransform: 'capitalize',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {rewardData.rewardTitle}
              </Typography>
            </Tooltip>
            <Typography
              variant="small"
              component="div"
              sx={{
                height: 60,
                overflowY: 'hidden',
                textAlign: 'justify',
                '&:hover': {
                  overflowY: 'auto',
                },
                '&::-webkit-scrollbar': {
                  width: '0.6rem',
                },
                '& a': {
                  color: '#008f6b',
                  textDecoration: 'none',
                  overflowWrap: 'break-word',
                },
              }}
              dangerouslySetInnerHTML={{ __html: rewardData.description }}
            />
          </CardContent>
          <CardActions
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              mx: 1,
            }}
          >
            <Typography
              variant="xsmall"
              textTransform="uppercase"
              sx={{
                fontWeight: 600,
                color: '#FFA000',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                style={{ marginRight: '0.5rem' }}
              />
              {rewardData.points}
            </Typography>
            <Button
              size="small"
              sx={{ fontSize: '0.7rem' }}
              endIcon={
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem !important' }} />
              }
              to={`/dashboard/rewards/${rewardData.sys.id}`}
              component={Link}
            >
              VIEW REWARD
            </Button>
          </CardActions>
        </>
      ) : null}
    </Card>
  )
}

export default RewardsListItem
