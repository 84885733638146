import UploadFileIcon from '@mui/icons-material/UploadFile'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material'
import { useAtom } from 'jotai'
import { ChangeEvent, useEffect, useState } from 'react'
import { alertAtom } from 'stores'
import { Reward } from 'types/admin'

interface Props {
  reward: Reward | undefined
  onClose?: () => void
  onFileUpload: (reward: Reward, file: File) => void
  open: boolean
}
const MultipleCouponUpdateDialog = ({
  onClose,
  onFileUpload,
  reward = undefined,
  open = false,
}: Props): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [value, setValue] = useState<File | null>(null)
  const [coupons, setCoupons] = useState<string[] | []>([])
  const [, setAlert] = useAtom(alertAtom)

  const handleMultipleCouponUpdate = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    if (!e.target.files) return
    const file = e.target.files[0]
    if (file.type === 'text/csv') {
      setValue(file)
      const reader = new FileReader()

      reader.onload = (e: ProgressEvent<FileReader>): void => {
        if (e && e.target) {
          const coupons = e.target.result as string
          const rows = coupons ? coupons.split(',') : []
          if (rows) setCoupons(rows)
        }
      }
      reader.readAsText(file)
    } else {
      setAlert({
        show: true,
        type: 'error',
        message: 'Only .CSV file is allowed',
        autoHideDuration: 2000,
      })
    }
  }

  const handleSubmit = (): void => {
    if (value && reward) {
      onFileUpload(reward, value)
      setValue(null)
    }
  }

  const handleClose = (): void => {
    setDialogOpen(false)
    setValue(null)
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
    >
      <DialogContent>
        <Button
          variant="outlined"
          endIcon={<UploadFileIcon />}
          color="primary"
          aria-label="upload coupons"
          component="label"
          sx={{ minWidth: '12.5rem' }}
        >
          {value ? value.name : 'No file selected'}
          <input
            hidden
            accept=".csv"
            type="file"
            onChange={handleMultipleCouponUpdate}
          />
        </Button>
        {value ? (
          <FormHelperText id="coupon-helper-text">
            {coupons.length} coupon codes found.
          </FormHelperText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={handleSubmit} disabled={!value}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MultipleCouponUpdateDialog
