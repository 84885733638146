import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PasswordResetFields } from 'types/AuthTypes'

interface Props {
  onSubmit: (data: PasswordResetFields) => void
}

const UpdatePassword = ({ onSubmit }: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordResetFields>()

  const password = useRef({})
  password.current = watch('newPassword', '')

  const submit = async (data: PasswordResetFields): Promise<void> => {
    onSubmit(data)
  }

  const [showOldPassword, setShowOldPassword] = useState(Boolean)
  const [showNewPassword, setShowNewPassword] = useState(Boolean)
  const [showConfirmPassword, setConfirmShowPassword] = useState(Boolean)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const checkPasswordStrength = async (password: string): Promise<any> => {
    // Firebase has weak password check
    let strength = 0
    if (password.match(/[a-z]+/)) strength += 1
    if (password.match(/[A-Z]+/)) strength += 1
    if (password.match(/[0-9]+/)) strength += 1
    if (password.match(/[$@#&!]+/)) strength += 1

    return strength >= 3 ? true : 'Password is too weak'
  }

  return (
    <Box
      justifyContent={'center'}
      alignItems={'start'}
      display="flex"
      flexDirection="column"
      sx={{ width: { xs: '100%', md: '50%' } }}
    >
      <form onSubmit={(e): void => e.preventDefault()}>
        <Typography
          variant="overline"
          paragraph
          sx={{ color: 'rgba(0, 0, 0, 0.6)', mb: 3 }}
        >
          Update Password
        </Typography>
        <FormControl variant="outlined" fullWidth focused sx={{ mb: 2 }}>
          <InputLabel
            htmlFor="currentPassword"
            sx={{
              '&:not(.MuiInputLabel-shrink):not(.Mui-focused)': {
                top: '-0.5rem',
              },
            }}
          >
            Old Password
          </InputLabel>
          <OutlinedInput
            id="currentPassword"
            size="small"
            type={showOldPassword ? 'text' : 'password'}
            autoComplete="old-password"
            {...register('currentPassword', {
              required: 'Old Password is Required ',
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters',
              },
            })}
            error={errors?.currentPassword ? true : false}
            aria-describedby="Old Password"
            label="Old Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(): void => setShowOldPassword(!showOldPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  color="primary"
                  sx={{ '& svg': { fontSize: '1rem' } }}
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="passsword-helper-text"
            error={errors?.currentPassword ? true : false}
          >
            {errors?.currentPassword ? errors.currentPassword.message : ' '}
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
          <InputLabel
            htmlFor="newPassword"
            sx={{
              '&:not(.MuiInputLabel-shrink):not(.Mui-focused)': {
                top: '-0.5rem',
              },
            }}
          >
            New Password
          </InputLabel>
          <OutlinedInput
            id="newPassword"
            size="small"
            type={showNewPassword ? 'text' : 'password'}
            autoComplete="new-password"
            {...register('newPassword', {
              required: 'New Password is Required ',
              minLength: {
                value: 6,
                message: 'Atleast 6 characters required',
              },
              validate: checkPasswordStrength,
            })}
            error={errors?.newPassword ? true : false}
            aria-describedby="New Password"
            label="New Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(): void => setShowNewPassword(!showNewPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  color="primary"
                  sx={{ '& svg': { fontSize: '1rem' } }}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="passsword-helper-text"
            error={errors?.newPassword ? true : false}
          >
            {errors?.newPassword ? errors.newPassword.message : ' '}
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
          <InputLabel
            htmlFor="confirmPassword"
            sx={{
              '&:not(.MuiInputLabel-shrink):not(.Mui-focused)': {
                top: '-0.5rem',
              },
            }}
          >
            Confirm Password
          </InputLabel>
          <OutlinedInput
            id="confirmPassword"
            size="small"
            type={showConfirmPassword ? 'text' : 'password'}
            autoComplete="new-password"
            {...register('confirmPassword', {
              required: 'Confirm Password is Required ',
              validate: value =>
                value === password.current || "Confirm Passwords doesn't match",
            })}
            error={errors?.confirmPassword ? true : false}
            aria-describedby="Password"
            label="Confirm Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(): void =>
                    setConfirmShowPassword(!showConfirmPassword)
                  }
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  color="primary"
                  sx={{ '& svg': { fontSize: '1rem' } }}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="passsword-helper-text"
            error={errors?.confirmPassword ? true : false}
          >
            {errors?.confirmPassword ? errors.confirmPassword.message : ' '}
          </FormHelperText>
        </FormControl>
        <Button
          variant="outlined"
          size="medium"
          sx={{ minHeight: '2.5rem' }}
          type="submit"
          onClick={handleSubmit(submit)}
        >
          Change Password
        </Button>
      </form>
    </Box>
  )
}

export default UpdatePassword
