import { AuthErrorCodes } from 'firebase/auth'

interface Codes {
  [key: string]: string
}

const errorCodes: Codes = {
  [AuthErrorCodes.APP_NOT_AUTHORIZED]:
    'The site is unable to connect to authentication servers. Please refresh.',
  [AuthErrorCodes.ARGUMENT_ERROR]:
    'An unexpected error occurred. Please refresh. (ARGUMENT_ERROR)',
  [AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN]:
    'You have been logged out. Please log back in.',
  [AuthErrorCodes.EMAIL_EXISTS]:
    'The provided email is already in use by an existing user. Each user must have a unique email.',
  [AuthErrorCodes.INTERNAL_ERROR]:
    'The Authentication server encountered an unexpected error while trying to process the request.',
  [AuthErrorCodes.INVALID_API_KEY]:
    'An unexpected error occurred. Please refresh. (INVALID_API_KEY)',
  [AuthErrorCodes.INVALID_APP_CREDENTIAL]:
    'An unexpected error occurred. Please refresh. (INVALID_APP_CREDENTIAL)',
  [AuthErrorCodes.INVALID_AUTH]:
    'The provided ID token is not a valid Firebase ID token.',
  [AuthErrorCodes.INVALID_EMAIL]:
    'The provided value for the email user property is invalid. It must be a string email address.',
  [AuthErrorCodes.INVALID_PASSWORD]:
    'Incorrect or invalid password. Please ensure the provided password is correct.',
  [AuthErrorCodes.INVALID_ORIGIN]:
    'An unexpected error occurred. Please refresh. (INVALID_ORIGIN)',
  [AuthErrorCodes.INVALID_TENANT_ID]:
    'An unexpected error occurred. Please refresh. (INVALID_TENANT_ID)',
  [AuthErrorCodes.MODULE_DESTROYED]:
    'An unexpected error occurred. Please refresh. (MODULE_DESTROYED)',
  [AuthErrorCodes.NETWORK_REQUEST_FAILED]: 'Network error - please try again',
  [AuthErrorCodes.OPERATION_NOT_ALLOWED]:
    'An unexpected error occurred. Please refresh. (OPERATION_NOT_ALLOWED)',
  [AuthErrorCodes.TIMEOUT]: 'Network error - please try again',
  [AuthErrorCodes.TOKEN_EXPIRED]:
    'An authentication error occurred. Please log out and back in.',
  [AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]:
    'Too many login attempts. Try again later.',
  [AuthErrorCodes.INVALID_PHONE_NUMBER]:
    'The provided value for the phoneNumber is invalid.',
  [AuthErrorCodes.USER_DELETED]:
    'There is no existing user record corresponding to the provided identifier.',
  [AuthErrorCodes.USER_DISABLED]:
    'This user has been disabled. Please contact customer support.',
  [AuthErrorCodes.USER_MISMATCH]:
    'An authentication error occurred. Please log out and back in.',
  [AuthErrorCodes.USER_SIGNED_OUT]:
    'An authentication error occurred. Please log out and back in.',
  [AuthErrorCodes.WEAK_PASSWORD]:
    'Password does not meet security requirements.',
  [AuthErrorCodes.WEB_STORAGE_UNSUPPORTED]:
    'Error - unable to save authentication. Please ensure your browser allows storage.',
}

export const getErrorMessage = (code: string): string => {
  let message: string = errorCodes[code]

  if (!message) {
    message = 'Server error.'
  }

  return message
}
