import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Icon from '@mui/material/Icon'
import { styled } from '@mui/material/styles'
import logo from 'assets/img/recyclebank_logo_green.png'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

interface Link {
  name: string
  path: string
  icon?: string
}

interface Headerprops {
  name: string
  settingsMenu: Array<Link>
  loading?: boolean
  drawerOpen?: boolean
  onMenuClickCB: (item: Link) => void
  onDrawerOpen: () => void
}

const stringToColor = (string: string): string => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const stringAvatar = (name: string): object => {
  if (name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  } else {
    return {}
  }
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 2,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: '2.1875rem',
    width: '12rem',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
  [theme.breakpoints.up('lg')]: {
    height: '2.625rem',
    width: '14rem',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
}))

const HeaderAdmin = ({
  name,
  settingsMenu = [],
  loading = false,
  drawerOpen = false,
  onMenuClickCB,
  onDrawerOpen,
}: Headerprops): JSX.Element => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }
  const handleOnSettingsMenuClick = (): void => {
    setAnchorElUser(null)
  }

  const handleSettingsMenuItemClick = (
    e: React.MouseEvent<HTMLElement>,
    menuItem: Link
  ): void => {
    e.preventDefault()
    if (menuItem) onMenuClickCB(menuItem)
    setAnchorElUser(null)
  }

  return (
    <AppBar
      elevation={0}
      open={drawerOpen}
      position="fixed"
      sx={{
        backgroundColor: 'white',
        borderBottom: '1px solid #ccc',
      }}
    >
      {loading ? <LinearProgress color="warning" sx={{ height: 2 }} /> : ''}
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={onDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography
          noWrap
          component="div"
          sx={{
            mr: 2,
            pt: 1,
            alignItems: 'center',
          }}
        >
          <NavLink to="/admin" style={{ outline: 'none' }}>
            <Logo src={logo} alt={'RecycleBank'} />
          </NavLink>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Box
          className="rb-menu-link"
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="small">
              <Avatar
                {...stringAvatar(name)}
                sx={{
                  width: { md: 30, lg: 35 },
                  height: { md: 30, lg: 35 },
                  fontSize: { md: '0.8rem', lg: '0.95rem' },
                  textTransform: 'uppercase',
                  border: '2px solid #008F6B',
                }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '1.875rem' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleOnSettingsMenuClick}
          >
            {settingsMenu.map(item => (
              <MenuItem
                key={item.name}
                onClick={(e): void => handleSettingsMenuItemClick(e, item)}
              >
                <ListItemIcon>
                  <Icon fontSize="small">{item.icon}</Icon>
                </ListItemIcon>
                <Typography textAlign="center">{item.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderAdmin
