import { AxiosResponse } from 'axios'
import { AddUser, UpdateUser, User } from 'types/AuthTypes'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  user(authId: string): Promise<AxiosResponse<User, any>> {
    return axiosInstance.get(`/users/authid/${authId}`)
  },

  validateContent(params: { baseContentId: string; userId: string }): Promise<
    AxiosResponse<
      {
        valid: boolean
      },
      any
    >
  > {
    return axiosInstance.get(
      `/users/${params.userId}/point-history/validatecontent`,
      {
        params: {
          baseContentId: params.baseContentId,
          userId: params.userId,
        },
      }
    )
  },

  activity(params: {
    userId: string
    contentType?: string
    startDate?: string
    endDate?: string
    type?: 'rewards' | 'points'
    page?: number
    itemsPerPage?: number
    descending?: boolean
    all?: boolean
  }): Promise<
    AxiosResponse<
      {
        count: number
        currentPage: number
        pointHistoryResponses: {
          baseContentId: string
          title: string
          createdDate: string
          contentType: string
          rewardId: null | string
          points: number
          type: 'rewards' | 'points'
        }[]
      },
      any
    >
  > {
    return axiosInstance.get(
      `/users/${params.userId}/point-history/allactivity`,
      {
        params: {
          userId: params.userId,
          contentType: params.contentType,
          startDate: params.startDate,
          endDate: params.endDate,
          type: params.type,
          page: params.page,
          itemsPerPage: params.itemsPerPage,
          descending: params.descending,
          all: params.all,
        },
      }
    )
  },
}

const saves = {
  trackLogin(): Promise<AxiosResponse<User, any>> {
    return axiosInstance.post(`/users/track`)
  },

  user(params: {
    authId: string
    user: AddUser
  }): Promise<AxiosResponse<User, any>> {
    return axiosInstance.post(`/users/authid/${params.authId}`, params.user)
  },

  updateUser(params: {
    authId: string
    user: UpdateUser
  }): Promise<AxiosResponse<User, any>> {
    return axiosInstance.patch(`/users/authid/${params.authId}`, params.user)
  },

  point(params: {
    userId: string
    baseContentId: string
    contentType: string
  }): Promise<
    AxiosResponse<
      {
        totalPoints: number
      },
      any
    >
  > {
    return axiosInstance.post(`/users/${params.userId}/point-history/points`, {
      baseContentId: params.baseContentId,
      contentType: params.contentType,
    })
  },

  purchaseReward(params: { userId: string; rewardId: string }): Promise<
    AxiosResponse<
      {
        totalPoints: number
      },
      any
    >
  > {
    return axiosInstance.post(
      `/users/${params.userId}/point-history/purchaseReward/${params.rewardId}`
    )
  },
}

const deletes = {}

export default class UserService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
