import { useQuery } from '@apollo/client'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import StarIcon from '@mui/icons-material/Star'
import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { Link as routeLink } from 'react-router-dom'
import { REWARD_BY_ID } from 'services/contentful/Rewards'
import { pointsAtom } from 'stores/user'
import { RewardDetails, RewardsItemType } from 'types/RewardTypes'
import { dateDisplayFormat } from 'utils/date'

interface Props {
  id: string | null
  setReward: (rewardItem: RewardsItemType) => void
  onGetReward: () => void
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

const RewardsDetailsWrapper = ({
  id,
  setReward,
  onGetReward,
}: Props): JSX.Element => {
  const [pointBalance] = useAtom(pointsAtom)

  const {
    loading: APILoading,
    error: APIError,
    data: data,
  } = useQuery(REWARD_BY_ID, {
    variables: { id: id },
  })

  if (APILoading) {
    return <Loading />
  }

  if (APIError) {
    const error: any = APIError
    return <Alert severity="error">Loading error :{error.message}</Alert>
  }

  if (!APILoading && !APIError) {
    setReward(data.rewards)
  }

  const reward: RewardDetails = data.rewards

  return (
    <Box
      component="div"
      className="rb_rewards_details_wrapper"
      sx={{
        mb: 3,
        mt: { xs: 2, md: '4.8rem' },
      }}
    >
      <Button
        to="/dashboard/rewards"
        component={routeLink}
        size="small"
        sx={{ fontSize: '0.7rem' }}
        startIcon={<ArrowBackIosIcon sx={{ fontSize: '0.75rem !important' }} />}
      >
        Back
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2.2}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              background: '#FFFFFF',
              border: '.25rem solid #F5F7FA',
              borderRadius: '.25rem',
              display: 'flex',
              justifyItems: 'center',
              maxHeight: '12rem',
              p: 2,
            }}
          >
            {reward.image && reward.image.url ? (
              <Img alt=" " src={reward.image.url} loading="lazy" />
            ) : (
              <Typography variant="body1">
                {reward.partner.partnerName}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column">
            <Grid item>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  maxWidth: { xs: '100%', md: '85%' },
                }}
              >
                {reward.rewardTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  mt: 0.5,
                  '& a': {
                    color: '#008f6b',
                    textDecoration: 'none',
                    overflowWrap: 'break-word',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: reward.description }}
              />
            </Grid>
            <Grid item sx={{ my: 3 }}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  startIcon={<StarIcon />}
                  color="warning"
                  sx={(theme): object => ({
                    backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    pointerEvents: 'none',
                  })}
                  disableRipple
                  component="div"
                >
                  {reward.points} POINTS
                </Button>
                <Button
                  variant="contained"
                  onClick={onGetReward}
                  disabled={reward.points > pointBalance}
                >
                  GET THIS REWARD
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                {reward.rewardTypes ? (
                  <Box
                    component="div"
                    sx={{
                      px: 1,
                      borderLeft: '.25rem solid #008F6B',
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {reward.rewardTypes}
                    </Typography>
                    <Typography variant="xsmall">
                      <strong> Please see terms and conditions below.</strong>
                    </Typography>
                  </Box>
                ) : null}
                {reward.expirationDate ? (
                  <Box
                    component="div"
                    sx={{ px: 1, borderLeft: '.25rem solid #008F6B' }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Deal Expires
                    </Typography>
                    <Typography variant="xsmall">
                      {dateDisplayFormat(reward.expirationDate)}
                    </Typography>
                  </Box>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{ backgroundColor: '#F5F7FA', borderRadius: '.25rem', mt: 3 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRight: { xs: 'none', md: '1px solid #BDC2CA' },
            borderBottom: { xs: '1px solid #BDC2CA', md: 'none' },
            p: { xs: 2, md: 5 },
            textAlign: 'justify',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            ABOUT THIS PARTNER
          </Typography>
          <Typography
            variant="xsmall"
            paragraph
            sx={{ lineHeight: 1.6, mt: 0.8, mb: 0 }}
            dangerouslySetInnerHTML={{ __html: reward.partner.aboutPartner }}
          />
          <Link
            variant="xsmall"
            href={reward.partner.partnerUrl}
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{ fontWeight: 500, mt: 1, textTransform: 'uppercase' }}
          >
            more about {reward.rewardTitle}{' '}
            <ArrowForwardIosIcon
              sx={{ fontSize: '0.7rem', top: '0.16rem', position: 'relative' }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ p: { xs: 2, md: 5 }, textAlign: 'justify' }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            TERMS &amp; CONDITIONS
          </Typography>
          <Typography
            variant="xsmall"
            paragraph
            sx={{
              lineHeight: 1.6,
              mt: 0.8,
              mb: 0,
              '& a': {
                color: '#008f6b',
                textDecoration: 'none',
                overflowWrap: 'break-word',
              },
            }}
            dangerouslySetInnerHTML={{ __html: reward.termsAndConditions }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RewardsDetailsWrapper
