import { gql } from '@apollo/client'

import { BASE_CONTENT_FIELDS } from './Fragments'

export const BASE_CONTENT_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query GetContentList($limit: Int!, $skip: Int!) {
    baseContentCollection(limit: $limit, skip: $skip) {
      total
      items {
        ...BaseContent
        content {
          __typename
          ... on Article {
            sys {
              id
              publishedAt
            }
          }
          ... on Flipcard {
            sys {
              id
              publishedAt
            }
          }
          ... on Video {
            sys {
              id
              publishedAt
            }
          }
          ... on Slideshow {
            sys {
              id
              publishedAt
            }
          }
          ... on Quiz {
            sys {
              id
              publishedAt
            }
          }
        }
      }
    }
  }
`
export const LATEST_CONTENT = gql`
  ${BASE_CONTENT_FIELDS}
  query GetLatestContent {
    baseContentCollection(limit: 1, order: sys_publishedVersion_ASC) {
      items {
        ...BaseContent
        content {
          __typename
          ... on Article {
            sys {
              id
              publishedAt
            }
          }
          ... on Flipcard {
            sys {
              id
              publishedAt
            }
          }
          ... on Video {
            sys {
              id
              publishedAt
            }
          }
          ... on Slideshow {
            sys {
              id
              publishedAt
            }
          }
          ... on Quiz {
            sys {
              id
              publishedAt
            }
          }
        }
      }
    }
  }
`

export const POPULAR_CONTENT_LIST = gql`
  query GetPopularContentList($limit: Int!, $skip: Int!) {
    baseContentCollection(
      limit: $limit
      skip: $skip
      order: sys_publishedVersion_DESC
    ) {
      items {
        sys {
          id
          publishedAt
        }
        image {
          url(
            transform: {
              width: 153
              height: 102
              resizeStrategy: FILL
              resizeFocus: BOTTOM
              format: JPG
              quality: 90
            }
          )
        }
        title
        content {
          __typename
          ... on Article {
            sys {
              id
              publishedAt
            }
          }
          ... on Flipcard {
            sys {
              id
              publishedAt
            }
          }
          ... on Video {
            sys {
              id
              publishedAt
            }
          }
          ... on Slideshow {
            sys {
              id
              publishedAt
            }
          }
          ... on Quiz {
            sys {
              id
              publishedAt
            }
          }
        }
        slug
      }
    }
  }
`

export const SEARCH_BASE_CONTENT_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query SearchContentList($limit: Int!, $skip: Int!, $searchString: String!) {
    baseContentCollection(
      limit: $limit
      skip: $skip
      where: {
        OR: [
          { title_contains: $searchString }
          { shortAbstract_contains: $searchString }
          { abstract_contains: $searchString }
        ]
      }
    ) {
      total
      items {
        ...BaseContent
        content {
          __typename
          ... on Article {
            sys {
              id
              publishedAt
            }
          }
          ... on Flipcard {
            sys {
              id
              publishedAt
            }
          }
          ... on Video {
            sys {
              id
              publishedAt
            }
          }
          ... on Slideshow {
            sys {
              id
              publishedAt
            }
          }
          ... on Quiz {
            sys {
              id
              publishedAt
            }
          }
        }
      }
    }
  }
`
