import { Box, Button, List } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import ActivityListItem from 'components/atoms/ActivityListItem'
import Loading from 'components/molecules/Loading'
import { ActivityListItemType } from 'types/UserSettingsTypes'

interface Props {
  activityList: ActivityListItemType[]
  loading: boolean
  sx?: SxProps<Theme>
  onViewAllActivity: () => void
  onPrintRewards?: (rewardFulFillmentId: string) => void
}

const ActivityListWrapper = ({
  activityList = [],
  loading,
  sx = [],
  onViewAllActivity,
  onPrintRewards,
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <List
        dense={false}
        sx={[{ width: '100%', p: 0 }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {loading ? (
          <Loading />
        ) : (
          activityList.map((item, index) => {
            return (
              <ActivityListItem
                key={index}
                baseContentId={item.baseContentId}
                rewardContentId={item.rewardContentId}
                rewardFulFillmentId={item.rewardFulFillmentId}
                rewardId={item.rewardId}
                title={item.title}
                points={item.points}
                createdDate={item.createdDate}
                type={item.type}
                rewardCode={item.rewardCode}
                rewardType={item.rewardType}
                onPrintRewards={onPrintRewards}
              />
            )
          })
        )}
      </List>
      <Button
        variant="contained"
        disableElevation
        sx={{ mt: 2 }}
        onClick={onViewAllActivity}
      >
        View all activity
      </Button>
    </Box>
  )
}

export default ActivityListWrapper
