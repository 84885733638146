import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import { ChangeEvent, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { alertAtom } from 'stores'
import {
  createSupportTicket,
  getSubTypesAtom,
  selectedSubTypeAtom,
  selectedTypeAtom,
  typesAtom,
} from 'stores/support'

type FormInputs = {
  type: string
  subType: string
  comment: string
}

const Contact = (): JSX.Element => {
  const [types] = useAtom(typesAtom)
  const [subTypes] = useAtom(getSubTypesAtom)
  const [selectedType, setSelectedType] = useAtom(selectedTypeAtom)
  const [, setSelectedSubType] = useAtom(selectedSubTypeAtom)
  const [helperText, setHelperText] = useState('')
  const [, setAlert] = useAtom(alertAtom)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormInputs>()

  const setPageAlert = (ticketId: string | null = null): void => {
    setAlert({
      show: true,
      type: ticketId ? 'success' : 'error',
      message: ticketId
        ? `Thank you for contacting Member Support. Your ticket number is #${ticketId}. A representative will get back to you within 2-3 business days.`
        : 'Failed to create support ticket. Try again.',
      autoHideDuration: !ticketId ? 2000 : null,
    })
  }

  const onSubmit: SubmitHandler<FormInputs> = (formData, e) => {
    createSupportTicket({
      type: formData.type,
      subType: formData.subType,
      comment: formData.comment,
    })
      .then(response => {
        setPageAlert(response.data)
        // Clear form on success
        setSelectedType('')
        e?.target.reset()
      })
      .catch(() => {
        setPageAlert()
      })
  }

  const handleTypeSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    setHelperText('')
    setValue('type', event.target.value)
    setSelectedType(event.target.value)

    const subTypeValue = getValues('subType')
    if (subTypeValue !== '') {
      setValue('subType', '')
      setSelectedSubType('')
    }
  }

  const handleSubTypeSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    for (const [key, value] of Object.entries(subTypes)) {
      if (key === event.target.value && event.target.value) {
        setHelperText(value.helperText ? value.helperText : '')
      }
    }
    setValue('subType', event.target.value)
    setSelectedSubType(event.target.value)
  }

  return (
    <AuthenticatedPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          backgroundColor: '#F5F7FA',
          height: '8.75rem',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'end' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
          <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="start"
            sx={{ borderLeft: '5px solid #FFA000', pl: 1.2 }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, textTransform: 'uppercase', mb: 0.3 }}
            >
              Contact
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                textTransform: 'uppercase',
                lineHeight: '0.8',
              }}
            >
              Member Support
            </Typography>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth="lg"
        className="contact-container"
        sx={{ flexGrow: 1 }}
      >
        <Box sx={{ mt: 5, mb: 5, display: 'flex' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="body2">
                Hi there. Recyclebank Member Support is here to help. Submit
                your question, comment or feedback below and a representative
                will get back to you within 2-3 business days.
              </Typography>
              <Typography
                variant="xsmall"
                paragraph
                color="primary"
                sx={{ my: 2 }}
              >
                All fields are required.
              </Typography>
              <form
                onSubmit={(e): void => {
                  handleSubmit(onSubmit)(e)
                }}
              >
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={5}>
                    <TextField
                      fullWidth
                      required
                      select
                      label="What would you like to discuss?"
                      size="small"
                      {...register('type', {
                        required: 'Type is Required ',
                      })}
                      defaultValue=""
                      value={selectedType ?? ''}
                      placeholder="Type"
                      error={errors?.type ? true : false}
                      helperText={errors?.type ? errors.type.message : ' '}
                      onChange={handleTypeSelect}
                    >
                      {types.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      key={selectedType}
                      fullWidth
                      required
                      select
                      label="Can you give us a little more information?"
                      size="small"
                      {...register('subType', {
                        required: 'Subtype is Required ',
                      })}
                      placeholder="Can you give us a little more information?"
                      error={errors?.subType ? true : false}
                      defaultValue=""
                      helperText={errors?.subType ? errors.subType.message : ''}
                      onChange={handleSubTypeSelect}
                    >
                      {subTypes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {helperText ? (
                      <FormHelperText sx={{ mt: 2 }} component="div">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <InfoIcon sx={{ fontSize: '0.95rem' }} />
                          <Typography
                            sx={{ fontSize: '0.65rem', fontWeight: 500 }}
                          >
                            {helperText}
                          </Typography>
                        </Stack>
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Please tell us more information."
                      multiline
                      rows={4}
                      color="primary"
                      {...register('comment', {
                        required: 'More information is Required ',
                      })}
                      error={errors?.comment ? true : false}
                      sx={{ fontSize: '0.6rem' }}
                      helperText={
                        errors?.comment ? errors.comment.message : ' '
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  background: '#F5F7FA',
                  borderRadius: '4px',
                  mt: { xs: 2, md: 10 },
                  py: 2,
                }}
              >
                <Typography
                  paragraph
                  variant="small"
                  sx={{ px: 2, fontWeight: 600 }}
                >
                  Didn&apos;t answer your question?
                  <br />
                  Sorry about that.
                  <br />
                  For more help, contact us directly.
                </Typography>
                <Divider></Divider>
                <Typography
                  paragraph
                  variant="xsmall"
                  sx={{ pt: 1, px: 2, fontWeight: 500 }}
                >
                  Please have the email address associated with your account and
                  a brief description of the problem you&apos;re having ready so
                  we can help you.
                </Typography>
                <Stack direction="row" spacing={2} sx={{ mx: 2, mb: 1 }}>
                  <div>
                    <Typography
                      variant="xsmall"
                      sx={{
                        color: '#BDC2CA',
                        display: 'block',
                        fontWeight: 500,
                      }}
                    >
                      Email Us
                    </Typography>
                    <Typography
                      variant="xsmall"
                      sx={{ color: '#000', fontWeight: 500 }}
                    >
                      <Link
                        href="mailto:support@recyclebank.com?Subject=Contact%20from%20Member%20Support%20Page"
                        underline="none"
                      >
                        support@recyclebank.com
                      </Link>
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="xsmall"
                      sx={{
                        color: '#BDC2CA',
                        display: 'block',
                        fontWeight: 500,
                      }}
                    >
                      Call Us
                    </Typography>
                    <Typography
                      variant="xsmall"
                      sx={{ color: '#000', fontWeight: 500 }}
                    >
                      {/* 888-727-2978 */}
                      <Link href="tel:1-833-787-4636" underline="none">
                        1-833-787-4636
                      </Link>
                    </Typography>
                  </div>
                </Stack>
                <Typography
                  paragraph
                  variant="xsmall"
                  sx={{ color: '#000', fontWeight: 500, my: 0, mx: 2 }}
                >
                  Do you have a question about partnering with us ?{' '}
                  <Link
                    href="mailto:sales@recyclebank.com?Subject=Partner%20With%20Recyclebank%20from%20Member%20Support%20Page"
                    underline="none"
                  >
                    Contact us here.
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AuthenticatedPageWrapper>
  )
}

export default Contact
