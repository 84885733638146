import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { AxiosResponse } from 'axios'
import Loading from 'components/molecules/Loading'
import CenterContainer from 'components/organisms/CenterContainer'
import PageWrapper, {
  AlertProp,
  defaultAlertDefn,
} from 'components/templates/PageWrapper'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { axiosInstance } from 'services/app'
import firebase from 'services/firebase'
import { idTokenAtom, isLoggedInAtom, logout } from 'stores/auth'
import {
  getUser,
  isUserLoadingAtom,
  pointsAtom,
  trackLogin,
  userAtom,
} from 'stores/user'
import { LoginInputs, User } from 'types/AuthTypes'
import { exceptionEvent, loginEvent } from 'utils/analytics'
import { getErrorMessage } from 'utils/fireBaseErrorCodes'
import { getRegex } from 'utils/helpers'

const Login = (): JSX.Element => {
  const navigate = useNavigate()
  const [userLoading, setUserLoading] = useAtom(isUserLoadingAtom)
  const [, setisLoggedIn] = useAtom(isLoggedInAtom)
  const [, setUser] = useAtom(userAtom)
  const [, setPointBalance] = useAtom(pointsAtom)
  const [, setIdToken] = useAtom(idTokenAtom)
  const location = useLocation()

  // track message from URL
  const query = new URLSearchParams(location.search)
  const message = query.get('msg')
  useEffect(() => {
    if (message && message.length) {
      setAlert({
        show: true,
        message: String(message),
        type: 'error',
      })
    }
  }, [message])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>()

  const onSubmit: SubmitHandler<LoginInputs> = async (data: LoginInputs) => {
    localStorage.clear()
    setUserLoading(true)

    await firebase
      .signIn(data.email, data.password)
      .then(async credential => {
        // GA-Login
        loginEvent('emailAndPassword')
        // user loggedin
        setisLoggedIn(true)

        await credential.user.getIdToken().then(async idToken => {
          // Save idToken in state for reference
          setIdToken(idToken)
          setisLoggedIn(true)

          axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${idToken}`

          //Get current user details
          await getUser(credential.user.uid)
            .then(({ data }: AxiosResponse<User>) => {
              // Track user login
              trackLogin().catch(() => {
                setAlert({
                  show: true,
                  message: 'User login track API failed.',
                  type: 'error',
                })
              })

              // User dashboard
              const { from } = (location.state as {
                from: { pathname: string }
              }) || {
                from: {
                  pathname: data.userRole === 'admin' ? '/admin' : '/dashboard',
                },
              }
              // Set user current point balance
              setPointBalance(data.pointBalance)
              // Update user
              setUser(data)
              setUserLoading(false)
              navigate(from)
            })
            .catch(() => {
              setUserLoading(false)
              // If failed in getting user info then logout the user
              logout(
                '/login',
                "We're sorry. We've run into an issue while getting your information. Try again later!"
              )
            })
        })
      })
      .catch(error => {
        setUserLoading(false)
        exceptionEvent(error)
        setAlert({
          show: true,
          message: getErrorMessage(error.code),
          type: 'error',
        })
      })
  }

  const [showPassword, setShowPassword] = useState(Boolean)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const [alert, setAlert] = useState<AlertProp>(defaultAlertDefn)

  if (userLoading) {
    return userLoading ? <Loading message="LOADING..." /> : <Loading />
  }

  return (
    <PageWrapper alert={alert}>
      <CenterContainer
        back={{ text: 'back to home', path: '/' }}
        text="Sign in"
        contentCols={{ xs: 12, sm: 8, md: 5, lg: 3 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus
                label="Email Address"
                {...register('email', {
                  required: 'Email Address is Required ',
                  pattern: {
                    value: getRegex('email'),
                    message: 'Invalid Email Address',
                  },
                })}
                error={errors?.email ? true : false}
                helperText={errors?.email ? errors.email.message : ' '}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  {...register('password', {
                    required: 'Password is Required ',
                  })}
                  error={errors?.password ? true : false}
                  aria-describedby="New password"
                  label="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(): void => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        color="primary"
                        sx={{ '& svg': { fontSize: '1.25rem' } }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  id="passsword-helper-text"
                  error={errors?.password ? true : false}
                >
                  {errors?.password ? errors.password.message : ' '}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography align="right">
                <Link
                  href="/reset"
                  variant="body2"
                  underline="none"
                  fontSize="0.8em"
                >
                  Forgot password?
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3 }}
                  >
                    Sign In
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" fontSize="0.7rem" sx={{ mt: 2 }}>
                    Not a member? <br />
                  </Typography>
                  <Typography align="center">
                    <Link
                      href="/register"
                      underline="none"
                      textTransform="uppercase"
                    >
                      Register Now
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CenterContainer>
    </PageWrapper>
  )
}

export default Login
