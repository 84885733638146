import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link, Typography } from '@mui/material'

interface RichTextResponseProps {
  json: any
}

const isValidURL = (str: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

const RenderOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children): JSX.Element => {
      return (
        <Typography
          variant="h1"
          sx={{ fontSize: '1.5rem!important', fontWeight: 500 }}
          component="h1"
          gutterBottom
        >
          {children}
        </Typography>
      )
    },
    [BLOCKS.HEADING_2]: (node, children): JSX.Element => {
      return (
        <Typography
          variant="h2"
          sx={{ fontSize: '1.3rem!important', fontWeight: 500 }}
          component="h2"
          gutterBottom
        >
          {children}
        </Typography>
      )
    },
    [BLOCKS.HEADING_3]: (node, children): JSX.Element => {
      return (
        <Typography
          variant="h3"
          sx={{ fontSize: '1.2rem!important', fontWeight: 500 }}
          component="h3"
          gutterBottom
        >
          {children}
        </Typography>
      )
    },
    [BLOCKS.HEADING_4]: (node, children): JSX.Element => {
      return (
        <Typography
          variant="h4"
          sx={{ fontSize: '1rem!important' }}
          component="h4"
          gutterBottom
        >
          {children}
        </Typography>
      )
    },
    [BLOCKS.HEADING_5]: (node, children): JSX.Element => {
      return (
        <Typography variant="h5" component="h5" gutterBottom>
          {children}
        </Typography>
      )
    },
    [BLOCKS.HEADING_6]: (node, children): JSX.Element => {
      return (
        <Typography variant="h6" component="h6" gutterBottom>
          {children}
        </Typography>
      )
    },
    [BLOCKS.QUOTE]: (node, children): JSX.Element => {
      return <blockquote className="blockquote-item">{children}</blockquote>
    },
    [BLOCKS.OL_LIST]: (node, children): JSX.Element => {
      return <ol className="ol-list">{children}</ol>
    },
    [BLOCKS.UL_LIST]: (node, children): JSX.Element => {
      return <ul className="ul-list">{children}</ul>
    },
    [BLOCKS.LIST_ITEM]: (node, children): JSX.Element => {
      return <li className="list-item">{children}</li>
    },
    [BLOCKS.PARAGRAPH]: (node, children): JSX.Element => {
      return (
        <Typography
          variant="body2"
          paragraph
          sx={{
            my: 1,
            textAlign: 'justify',
            lineHeight: 1.5,
          }}
        >
          {children}
        </Typography>
      )
    },

    [INLINES.HYPERLINK]: (node, children): JSX.Element => {
      const isMailTo = node.data.uri.includes('mailto')
      if (isValidURL(node.data.uri) || isMailTo) {
        const { hostname } = new URL(node.data.uri)
        const isInternalLink = hostname.includes('recyclebank.com')
        return (
          <Link
            href={node.data.uri}
            variant="body2"
            target={isInternalLink ? '_self' : '_blank'}
            rel={isInternalLink ? 'help' : 'noreferrer'}
          >
            {children}
            {!isInternalLink && !isMailTo ? (
              <OpenInNewIcon
                sx={{
                  fontSize: '0.85rem',
                  ml: '0.2rem',
                  position: 'relative',
                  top: '1px',
                }}
              />
            ) : null}
          </Link>
        )
      } else {
        return (
          <Typography
            variant="body2"
            component="strong"
            sx={{ fontWeight: 600 }}
          >
            {children}
          </Typography>
        )
      }
    },
  },
}

export const RichText = ({ json }: RichTextResponseProps): JSX.Element => {
  return <>{documentToReactComponents(json, RenderOptions)}</>
}

// Thumbnail ({url, fileName, description, width = 70, height = 70}) {
//   if (/^\/\/images\./.test(url)) {
//     return <img
//       src={`${url}?w=${width}&h=${height}&fit=thumb`}
//       alt={fileName}
//       title={fileName}
//       width={width}
//       height={height} />
//   } else if (fileName) {
//     const style = {
//       width: width + 'px',
//       height: height + 'px'
//     }
//     return <div styleName='file-icon' title={description || fileName} style={style}>
//       {fileName}
//     </div>
//   }
//   return ''
// }
