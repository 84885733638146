import { useQuery } from '@apollo/client'
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material'
import { createSvgIcon } from '@mui/material/utils'
import earnPoint from 'assets/img/earnPoint.svg'
import getReward from 'assets/img/getReward.svg'
import learnArticle from 'assets/img/learnArticle.svg'
import JoiningGreetDialog from 'components/molecules/JoiningGreetDialog'
import LikeCard from 'components/molecules/LikeCard'
import Loading from 'components/molecules/Loading'
import ScoreBadge from 'components/molecules/ScoreBadge'
import CategoryCardWrapper from 'components/organisms/CategoryCardWrapper'
import MediaCardWrapper from 'components/organisms/MediaCardsWrapper'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { GET_GENERIC_CONTENT_BY_ID } from 'services/contentful/GenericPage'
import { pointsAtom, updateUser } from 'stores/user'
import { userAtom } from 'stores/user'
import { GenericPageItem } from 'types/BaseContentDetailTypes'

const ArrowIcon = createSvgIcon(
  <path
    d="M2.67934e-05 111H12L28.3678 55.5L12 0H1.77933e-05L16.3678 55.5L2.67934e-05 111Z"
    fill="#FFA000"
  />,
  'Arrow'
)

const categoryCards = [
  {
    bckColor: '#F5F7FA',
    color: '#0C1C2A',
    type: 'Article',
    heading: 'Article',
    description:
      'Read and Earn from our library of sustainability content.  Learn how you can lessen your impact to the environment in your everyday life.',
    path: 'article',
  },
  {
    bckColor: '#F5F7FA',
    color: '#0C1C2A',
    type: 'Flipcard',
    heading: 'Flip Card',
    description:
      'Test your recycling knowledge and Earn. Flip Cards are a fun way to learn and earn on your own or with others.',
    path: 'flipcard',
  },
  {
    bckColor: '#F5F7FA',
    color: '#0C1C2A',
    type: 'Video',
    heading: 'Video',
    description:
      'View, Learn, and Earn ways to Recycle and become more sustainable through our video library.',
    path: 'video',
  },
]

const getRandomSkip = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const Dashboard = (): JSX.Element => {
  const navigate = useNavigate()
  const [user, setUser] = useAtom(userAtom)
  const [pointBalance] = useAtom(pointsAtom)

  const {
    loading: APILoading,
    error: APIError,
    data: data,
  } = useQuery(GET_GENERIC_CONTENT_BY_ID, {
    variables: { id: 4 }, // 4 is dashboard
  })

  if (APILoading) {
    return <Loading />
  }

  if (APIError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = APIError
    return <Alert severity="error">Loading error :{error.message}</Alert>
  }

  const genericPageContent: GenericPageItem =
    data.genericPagesCollection.items[0]

  const handleCategoryCardClick = (to: string): void => {
    navigate(to)
  }

  const handleReadMoreStories = (): void => {
    navigate('/dashboard/learnEarn')
  }

  const handleBaseContentReadMoreClick = (to: string): void => {
    navigate(to)
  }

  const handleJoinGreetClose = async (): Promise<void> => {
    if (user && user.authProviderId) {
      updateUser(user.authProviderId, { firstLogin: false }).then(response => {
        setUser(response.data)
      })
    }
  }

  return (
    <AuthenticatedPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          minHeight: { xs: '50vh', md: '60vh' },
          backgroundImage: 'url(' + genericPageContent.featuredImage.url + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: { xs: 'center', md: 'center' },
          display: 'flex',
          justifyContent: { xs: 'center', md: 'end' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              color="white"
              sx={{ mb: { xs: 4, md: 8 } }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  borderLeft: {
                    xs: '4px solid #FFA000',
                    md: '8px solid #FFA000',
                  },
                  pl: { xs: 1, md: 2 },
                  mb: { xs: 4 },
                }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  sx={{
                    fontWeight: 500,
                    mb: 0.5,
                    lineHeight: { xs: 1.5, md: 1 },
                    textTransform: 'uppercase',
                  }}
                >
                  Welcome
                </Typography>
                <Typography
                  variant="h4"
                  paragraph
                  sx={{
                    fontSize: '2.1rem!important',
                    fontWeight: 600,
                    mb: 0,
                    lineHeight: 1,
                    textTransform: 'uppercase',
                  }}
                >
                  {user ? `${user.firstName} ${user.lastName}` : ''}
                </Typography>
              </Grid>
              <Grid item>
                <ScoreBadge points={pointBalance} />
              </Grid>
            </Grid>
            <CategoryCardWrapper
              cards={categoryCards}
              onCardClick={handleCategoryCardClick}
            />
          </Grid>
        </Container>
      </Container>
      <Container
        sx={{
          bgcolor: '#F5F7FA',
          pt: 8,
          pb: 8,
        }}
        maxWidth={false}
      >
        <Container maxWidth="md">
          <MediaCardWrapper
            xsArrows={false}
            showReadMore
            skip={getRandomSkip(0, 15)}
            onReadMore={handleBaseContentReadMoreClick}
          />
          <Box component="div" sx={{ mt: 4, textAlign: 'center' }}>
            <Button
              variant="contained"
              disableElevation
              sx={{ fontSize: '0.7rem' }}
              onClick={handleReadMoreStories}
            >
              Read More Stories
            </Button>
          </Box>
        </Container>
      </Container>
      <Container
        maxWidth={false}
        component="div"
        sx={{ backgroundColor: '#21409A', px: { xs: 0 } }}
      >
        <Container component="div" maxWidth={false} sx={{ px: { xs: 0 } }}>
          <Grid
            rowSpacing={{ xs: 4, md: 3 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            className="rb-category-card-wrapper"
            columns={{ xs: 1, sm: 8, md: 12 }}
          >
            <Grid
              item
              className="cardItem"
              xs={12}
              md={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={learnArticle} height="180" />
              <Typography
                variant="h6"
                align="center"
                paragraph
                color="white"
                textTransform="uppercase"
                fontWeight="600"
                fontSize="1rem"
                sx={{ mt: 4, mb: 1 }}
              >
                Learn
              </Typography>
              <Typography
                variant="small"
                align="center"
                paragraph
                color="white"
                sx={{
                  mt: 0,
                  fontWeight: 400,
                  width: '75%',
                }}
              >
                Learn about sustainability through our extensive library of
                articles, flip cards and videos. You can earn points by learning
                right here!
              </Typography>
            </Grid>
            <Grid
              item
              className="cardItem"
              xs={12}
              md={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="relative"
              sx={{
                background: 'rgb(12 28 42 / 20%)',
                webkitClipPath: {
                  md: 'polygon(0 0, 92% 0, 100% 50%, 92% 100%, 0 100%, 8% 50%)',
                },
                clipPath: {
                  xs: 'polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0, 50% 8%)',
                  md: 'polygon(0 0, 92% 0, 100% 50%, 92% 100%, 0 100%, 8% 50%)',
                },
                px: { xs: 0, md: 8 },
                py: { xs: '55px !important', md: 0 },
              }}
            >
              <ArrowIcon
                sx={{
                  width: '1.5rem',
                  height: '4.75rem',
                  position: 'absolute',
                  left: { xs: 'unset', md: '2.25rem' },
                  top: { xs: 0, md: 'calc(50% - 4.75rem/2)' },
                  transform: { xs: 'rotate(90deg)', md: 'unset' },
                }}
                viewBox="0 0 29 111"
              ></ArrowIcon>
              <img src={earnPoint} height="180" />
              <Typography
                variant="h6"
                align="center"
                paragraph
                color="white"
                textTransform="uppercase"
                fontWeight="600"
                fontSize="1rem"
                sx={{ mt: 4, mb: 1 }}
              >
                Earn Points
              </Typography>
              <Typography
                variant="small"
                align="center"
                paragraph
                color="white"
                sx={{
                  mt: 0,
                  fontWeight: 400,
                  width: 'calc(75% + 16%)', //8% of cliping on both sides
                }}
              >
                Check back every day to earn more points; we add new
                opportunities all the time. Once you’ve earned enough points,
                you can cash them in for great deals!
              </Typography>
              <ArrowIcon
                sx={{
                  width: '1.5rem',
                  height: '4.75rem',
                  position: 'absolute',
                  right: { xs: 'unset', md: 0 },
                  bottom: {
                    xs: 'calc((-4.75rem + 1.5rem) /2)',
                    md: 'calc(50% - 4.75rem/2)',
                  },
                  transform: { xs: 'rotate(90deg)', md: 'unset' },
                }}
                viewBox="0 0 29 111"
              ></ArrowIcon>
            </Grid>
            <Grid
              item
              className="cardItem"
              xs={12}
              md={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 2 }}
            >
              <img src={getReward} height="180" />
              <Typography
                variant="h6"
                align="center"
                paragraph
                color="white"
                textTransform="uppercase"
                fontWeight="600"
                fontSize="1rem"
                sx={{ mt: 4, mb: 1 }}
              >
                Get rewards
              </Typography>
              <Typography
                variant="small"
                align="center"
                paragraph
                color="white"
                sx={{
                  mt: 0,
                  fontWeight: 400,
                  width: '75%',
                }}
              >
                Members can use their Recyclebank points to get great deals at
                local businesses and exclusive discounts on sustainable goods.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        component="div"
        maxWidth="lg"
        sx={{ mt: { xs: 8, md: 12 }, mb: 4 }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight="600"
          paragraph
          textTransform="uppercase"
          sx={{ mb: 4 }}
        >
          YOU MAY ALSO LIKE
        </Typography>
        <LikeCard onReadMore={handleBaseContentReadMoreClick} />
      </Container>
      <JoiningGreetDialog
        open={user && user.firstLogin ? true : false}
        onCloseCB={handleJoinGreetClose}
      />
    </AuthenticatedPageWrapper>
  )
}

export default Dashboard
