import { Grid, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Loading from 'components/molecules//Loading'
import ActivityFilter from 'components/molecules/ActivityFilter'
import AllActivityListWrapper from 'components/molecules/AllActivityListWrapper'
import SettingsCard from 'components/molecules/SettingsCard'
import { ActivityListItemType } from 'types/UserSettingsTypes'

interface Props {
  activityList: ActivityListItemType[]
  loading: boolean
  count: number
  page: number
  rowsPerPage: number
  onFilterChange: (range: any) => void
  onTypeChange: (type: 'rewards' | 'points' | undefined) => void
  onAccountSettings: () => void
  onPrintRewards?: (rewardFulFillmentId: string) => void
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const AllActivityWrapper = ({
  activityList = [],
  loading,
  count,
  page,
  rowsPerPage,
  onFilterChange,
  onTypeChange,
  onAccountSettings,
  onPrintRewards,
  onPageChange,
  onRowsPerPageChange,
}: Props): JSX.Element => {
  const theme = useTheme()
  const isUpSM = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid
      container
      justifyContent="start"
      alignItems="center"
      display="flex"
      spacing={2}
      sx={{
        mb: 2,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        mt: { xs: 1, md: 6 },
      }}
      className="rb-all-activity-wrapper"
    >
      <Grid item xs={12} md={4}>
        <ActivityFilter
          onFilterChange={onFilterChange}
          onTypeChange={onTypeChange}
        />
        {isUpSM ? <SettingsCard onAccountSettings={onAccountSettings} /> : null}
      </Grid>
      <Grid
        item
        xs={12}
        md={6.5}
        sx={{
          mt: { xs: 0, md: 0 },
          mb: { xs: 2, md: 0 },
        }}
      >
        {loading ? (
          <Loading />
        ) : activityList.length ? (
          <>
            <Typography
              variant="body2"
              sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 2 }}
            >
              All Activities
            </Typography>
            <AllActivityListWrapper
              activityList={activityList}
              showYear
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPrintRewards={onPrintRewards}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </>
        ) : (
          <Typography
            variant="small"
            paragraph
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
              m: 0,
              width: '100%',
            }}
          >
            No Activities Found.
          </Typography>
        )}
      </Grid>
      {!isUpSM ? (
        <Grid item xs={12}>
          <SettingsCard onAccountSettings={onAccountSettings} />
        </Grid>
      ) : null}
    </Grid>
  )
}

export default AllActivityWrapper
