import { Card, CardActionArea, Icon, Typography } from '@mui/material'
import { ArticleIcon, FlipcardIcon, QuizIcon, VideoIcon } from 'utils/svgAssets'

interface Props {
  backgroundColor: string
  color?: string
  type: string
  heading: string
  description: string
  to: string
  onCardClick: (to: string) => void
}

const typeIcon = (type: string, color: string): JSX.Element | string => {
  switch (type) {
    case 'Article':
      return (
        <ArticleIcon sx={{ fontSize: '2.5rem', opacity: 0.2 }} fill={color} />
      )
    case 'Flipcard':
      return (
        <FlipcardIcon sx={{ fontSize: '2.5rem', opacity: 0.2 }} fill={color} />
      )
    case 'Video':
      return (
        <VideoIcon sx={{ fontSize: '2.5rem', opacity: 0.2 }} fill={color} />
      )
    case 'Slideshow':
      return <Icon sx={{ fontSize: '2.rem', opacity: 0.2 }}>photo_library</Icon>
    case 'Quiz':
      return <QuizIcon />
    default:
      return ''
  }
}

const CategoryCard = ({
  backgroundColor,
  color = 'white',
  type,
  heading,
  description,
  to,
  onCardClick,
}: Props): JSX.Element => {
  const handleCardClick = (): void => {
    onCardClick(to)
  }

  return (
    <Card
      className="rb-category-card"
      elevation={0}
      sx={{
        cursor: 'pointer',
        width: '100%',
        backgroundColor: backgroundColor,
        borderRadius: '0.313rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        color: color,
      }}
    >
      <CardActionArea
        sx={{
          p: 2,
        }}
        onClick={handleCardClick}
      >
        <Typography component="div" textAlign="center">
          {typeIcon(type, color)}
        </Typography>
        <Typography
          variant="h4"
          paragraph
          sx={{
            fontWeight: 600,
            marginBottom: '0',
            lineHeight: 1,
            textTransform: 'uppercase',
            textAlign: 'center',
            margin: 2,
          }}
        >
          {heading}
        </Typography>
        <Typography
          variant="body2"
          paragraph
          textAlign="center"
          sx={{
            minHeight: '3.65rem',
          }}
        >
          {description}
        </Typography>
      </CardActionArea>
    </Card>
  )
}

export default CategoryCard
