import { gql } from '@apollo/client'

export const BASE_CONTENT_FIELDS = gql`
  fragment BaseContent on BaseContent {
    contentfulMetadata {
      tags {
        id
        name
      }
    }
    sys {
      id
      publishedAt
    }
    image {
      url
      width
      height
    }
    title
    publishMonth
    publishDay
    publishYear
    shortAbstract
    abstract
    originalId
    points
    slug
  }
`
export const FLIPCARD_FIELDS = gql`
  fragment Flipcard on Flipcard {
    sys {
      id
      publishedAt
    }
    frontImage {
      size
      url
      width
      height
    }
    frontMessage {
      json
    }
    frontBackgroundColor
    frontMessageColor
    pointsButtonColor
    backMessageAlignment
    backImage {
      size
      url
      width
      height
    }
    backMessage {
      json
    }
    backMessageColor
    backBackgroundColor
    bodyText {
      json
    }
  }
`
