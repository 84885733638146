import Masonry from '@mui/lab/Masonry'
import { Box, Button, Typography, styled } from '@mui/material'
import { RewardCategoryItemType } from 'types/RewardTypes'

const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: 'fit-content',
  objectFit: 'cover',
  position: 'relative',
  zIndex: 1,
  height: '100%',
})

const srcset = (
  image: string
  // width: number
  // height: number
  // rows = 1,
  // cols = 1
): {
  src: string
  // srcSet: string
} => {
  // TODO: Need to implement contentful image API here
  return {
    src: `${image}`,
    // src: `${image}?w=${width * cols}&h=${height * rows}`,
    // srcSet: `${image}?w=${width * cols}&h=${height * rows}`,
  }
}

interface Props {
  categoriesList: RewardCategoryItemType[]
  onShopNowClick: (e: React.MouseEvent, item: RewardCategoryItemType) => void
}

const RewardsCategoriesListWrapper = ({
  categoriesList,
  onShopNowClick,
}: Props): JSX.Element => {
  return (
    <Box
      component="div"
      className="rb_rewards_categories_list_wrapper"
      sx={{
        mb: 3,
        mt: 3,
      }}
    >
      <Masonry
        columns={{ xs: 1, sm: 2, md: 2 }}
        spacing={1}
        defaultColumns={1}
        defaultSpacing={1}
      >
        {categoriesList.map((item, index) => (
          <Box
            component="div"
            key={index}
            sx={{
              // mt: {
              //   xs: 0,
              //   md: index === 1 ? '7.2rem!important' : 0,
              // },
              position: 'relative',
            }}
          >
            <Img {...srcset(item.image.url)} loading="lazy" alt={item.type} />
            <Box
              component="div"
              sx={{
                color: '#fff',
                p: 2.5,
                width: '56%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                alignItems: 'start',
                position: 'absolute',
                zIndex: 2,
                bottom: 0,
                background:
                  'linear-gradient(90deg, rgba(12,28,42,0.9) 0%, rgba(12,28,42,0.6) 56%, rgba(12,28,42,0) 100%)',
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  my: 1,
                  color: 'inherit',
                }}
              >
                {item.title}
              </Typography>
              {item.description ? (
                <Typography
                  variant="h4"
                  gutterBottom
                  paragraph
                  sx={{
                    my: 1,
                    color: 'inherit',
                  }}
                >
                  {item.description}
                </Typography>
              ) : null}

              <Button
                variant="contained"
                disableElevation={true}
                size="small"
                onClick={(e: React.MouseEvent): void =>
                  onShopNowClick(e, { ...item, checked: true })
                }
                sx={{
                  mt: 2,
                }}
              >
                Shop Now
              </Button>
            </Box>
          </Box>
        ))}
      </Masonry>
    </Box>
  )
}

export default RewardsCategoriesListWrapper
