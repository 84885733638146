import DownloadIcon from '@mui/icons-material/Download'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Container, Typography } from '@mui/material'
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import AdminPageWrapper from 'components/templates/AdminPageWrapper'
import { format, toDate } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { alertAtom } from 'stores'
import { downloadBulkFiles, getBulkFileList } from 'stores/admin'
import { BulkFileListType } from 'types/admin'

const BulkFiles = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [, setAlert] = useAtom(alertAtom)
  const [bulkfileList, setBulkfileList] = useState<BulkFileListType[] | []>([])

  useEffect(() => {
    getBulkFileListData()
  }, [])

  const getBulkFileListData = (): void => {
    getBulkFileList()
      .then(response => {
        setBulkfileList(response.data)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to get rewards list. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  const handleDownloadBulkFiles = (storageKey: string | null = null): void => {
    setLoading(true)
    downloadBulkFiles(storageKey)
      .then(response => {
        const bulkFilesZipURL = window.URL.createObjectURL(
          new Blob([response.data])
        )
        setLoading(false)
        const link = document.createElement('a')
        const filename =
          response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0] || 'bulkFile'
        const extension =
          response.headers['content-disposition'].split('.')[1].split(';')[0] ||
          'zip'
        link.href = bulkFilesZipURL
        link.setAttribute('download', `${filename}.${extension}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          const errorBlob = error.response.data
          const fileReader = new FileReader()
          fileReader.onload = (evt): void => {
            setAlert({
              show: true,
              type: 'error',
              message: evt.target?.result?.toString()
                ? evt.target.result.toString()
                : storageKey
                ? `Failed to download file ${storageKey}`
                : 'Failed to download file!',
              autoHideDuration: 2000,
            })
          }
          fileReader.readAsText(errorBlob)
        } else {
          setAlert({
            show: true,
            type: 'error',
            message: storageKey
              ? `Failed to download file ${storageKey}`
              : 'Failed to download file!',
            autoHideDuration: 2000,
          })
        }
      })
  }

  const dateFormatter = (params: ValueFormatterParams): string => {
    return params.value
      ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
      : 'N/A'
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'createdAt',
      filter: true,
      valueFormatter: dateFormatter,
      sortable: true,
      width: 180,
    },
    {
      headerName: 'Batch Number',
      field: 'batchNumber',
      sort: 'asc',
      filter: true,
      sortable: true,
    },
    {
      headerName: 'Download',
      field: '',
      filter: false,
      sortable: false,
      flex: 1,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Button
            endIcon={<DownloadIcon sx={{ fontSize: '1rem' }} color="action" />}
            aria-label="Download bulk file"
            size="small"
            color="primary"
            onClick={(): void =>
              handleDownloadBulkFiles(params.data.storageKey)
            }
          >
            {params.data.storageKey.split(/.*[/|\\]/)[1]}
          </Button>
        )
      },
    },
  ])

  const defaultColDef = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
    }),
    []
  )

  return (
    <AdminPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Typography
          paragraph
          sx={{ fontWeight: '600', mb: 2, fontSize: '1rem' }}
        >
          Bulk Files
        </Typography>
        <Typography variant="body2" paragraph>
          Click below button to generate Mailed Rewards file for Liberty
        </Typography>
        <LoadingButton
          size="small"
          endIcon={<DownloadIcon />}
          loading={loading}
          loadingPosition="end"
          variant="outlined"
          onClick={(): void => handleDownloadBulkFiles()}
          sx={{ my: 2 }}
        >
          Generate
        </LoadingButton>
        {bulkfileList && bulkfileList.length ? (
          <Box
            className="ag-theme-alpine"
            sx={{ width: '100%', height: '50vh' }}
          >
            <AgGridReact
              rowData={bulkfileList}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pagination={true}
              paginationPageSize={20}
              gridOptions={{
                suppressCellFocus: true,
              }}
            />
          </Box>
        ) : (
          <>
            <Typography
              variant="body2"
              sx={{
                mt: '1rem',
                mb: '0.2rem',
              }}
            >
              No previous downloads of bulk files found. Try downloading bulk
              files using above download button.
            </Typography>
          </>
        )}
      </Container>
    </AdminPageWrapper>
  )
}

export default BulkFiles
