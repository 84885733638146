import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Button, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Switch from 'components/atoms/Switch'
import Tag from 'components/atoms/Tag'
import { RichText } from 'components/organisms/ContentFulRenderer'
import { ContentWrapperType } from 'components/organisms/ContentWrapper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArticleType, FormatedArticleType } from 'types/BaseContentDetailTypes'
import { dateFormatNoYear } from 'utils/date'

const Img = styled('img')({
  margin: '0',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  marginBottom: '1rem',
})

interface ArticleProps
  extends Omit<ContentWrapperType, 'onBaseContentIdUpdate'> {
  baseContentId: string
  id: string
  type: string
  data: ArticleType
}

const ArticleWrapper = ({
  type,
  id,
  baseContentId,
  data,
  disabledEarnPoint,
  onContentView,
  onEarnPointsClick,
}: ArticleProps): JSX.Element => {
  const [earnPointsDisabled, setEarnPointsDisabled] =
    useState(disabledEarnPoint)

  const formatedArticle: FormatedArticleType = Object.assign(
    {},
    {
      ...{ contentId: data.sys.id },
      ...data.linkedFrom.baseContentCollection.items[0],
      ...{ richText: data.content.json },
    }
  )
  const navigate = useNavigate()

  useEffect(() => {
    onContentView(
      baseContentId,
      id,
      type,
      formatedArticle.points,
      formatedArticle.title
    )
    setEarnPointsDisabled(disabledEarnPoint)
  }, [disabledEarnPoint])

  const handleEarnPoints = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    if (e.target.checked && !disabledEarnPoint) {
      setEarnPointsDisabled(true)
      onEarnPointsClick(
        baseContentId,
        id,
        type,
        formatedArticle.points,
        formatedArticle.title
      )
    }
  }

  const switchText = `Earn ${formatedArticle.points} points`

  return (
    <Box
      component="section"
      id={`${type}_${id}`}
      className="rb_article_container"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button
          size="small"
          sx={{ fontSize: '0.7rem' }}
          startIcon={
            <ArrowBackIosIcon sx={{ fontSize: '0.75rem !important' }} />
          }
          onClick={(): void => navigate(-1)}
        >
          Back
        </Button>
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          {formatedArticle.contentfulMetadata
            ? formatedArticle.contentfulMetadata.tags.map(tag => (
                <Tag key={tag.id} label={tag.name} />
              ))
            : null}
        </Stack>
        <Switch
          label={switchText}
          labelWidth={50}
          color="warning"
          checked={disabledEarnPoint}
          onChange={handleEarnPoints}
          disabled={earnPointsDisabled}
        />
      </Box>
      <Typography
        variant="h4"
        fontWeight="600"
        gutterBottom
        sx={{
          textTransform: 'uppercase',
          my: 1,
          fontWeight: 700,
        }}
      >
        {formatedArticle.title}
      </Typography>
      <Typography variant="xsmall" component="small">
        By Recyclebank |{' '}
        {dateFormatNoYear(
          formatedArticle.publishDay,
          formatedArticle.publishMonth,
          formatedArticle.publishYear
        )}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          mb: 3,
        }}
      >
        {formatedArticle.abstract}
      </Typography>
      <Img alt="ArticleImage" src={formatedArticle.image.url} loading="lazy" />
      <Box className="rb_article_content">
        <RichText json={formatedArticle.richText} />
      </Box>
    </Box>
  )
}

export default ArticleWrapper
