import { gql } from '@apollo/client'

export const REWARD_CATEGORIES_LIST = gql`
  query GetReWardCategoriesList {
    rewardCategoriesCollection {
      items {
        sys {
          id
        }
        type
        title
        image {
          url
          width
          height
        }
        description {
          json
        }
      }
    }
  }
`
export const REWARD_LIST = gql`
  query GetRewardsList($limit: Int!, $skip: Int!) {
    rewardsCollection(limit: $limit, skip: $skip) {
      total
      items {
        sys {
          publishedAt
          id
        }
        rewardTitle
        description
        points
        image {
          url
          width
          height
        }
        partner {
          partnerName
          sys {
            id
          }
        }
      }
    }
  }
`

export const REWARD_BY_ID = gql`
  query GetReward($id: String!) {
    rewards(id: $id) {
      sys {
        id
      }
      rewardTitle
      description
      rewardTypes
      points
      expirationDate
      termsAndConditions
      sku
      redemptionUrl
      barcode {
        url
        width
        height
      }
      image {
        url
        width
        height
      }
      partner {
        sys {
          id
        }
        partnerName
        aboutPartner
        partnerUrl
      }
    }
  }
`

export const REWARD_PARTNERS_LIST = gql`
  query GetRewardPartnersList($limit: Int!, $skip: Int!) {
    rewardPartnerCollection(limit: $limit, skip: $skip) {
      total
      items {
        sys {
          id
        }
        partnerName
        aboutPartner
        image {
          url
          width
          height
        }
        partnerUrl
      }
    }
  }
`
