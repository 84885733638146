import CircleIcon from '@mui/icons-material/Circle'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import handShake from 'assets/img/joining_hands.gif'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { JoiningBCK, JoiningText } from 'utils/svgAssets'

interface Prop {
  open: boolean
  onCloseCB?: () => void
}

const JoiningGreetDialog = ({ open = true, onCloseCB }: Prop): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  const handleClose = (): void => {
    setDialogOpen(false)
    if (onCloseCB) onCloseCB()
  }

  const handleNavigation = (to: string): void => {
    navigate(to)
    setDialogOpen(false)
    if (onCloseCB) onCloseCB()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="Joining Greetings"
      className="rb-joining-greet-dialog"
      maxWidth="xs"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'start',
          flexDirection: 'column',
          p: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0,
          }}
        >
          <JoiningBCK sx={{ width: '100%', height: '100%' }} />

          <Box
            sx={{
              height: { xs: '8.25rem', md: '9.688rem' },
              width: { xs: '8.25rem', md: '9.688rem' },
              backgroundImage: 'url(' + handShake + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '50%',
              top: '1.875rem',
              position: 'absolute',
            }}
          ></Box>
          <JoiningText
            sx={{
              width: '100%',
              height: '65%',
              position: 'absolute',
              zIndex: 1,
            }}
          />

          <IconButton
            aria-label="close"
            onClick={handleClose}
            color="primary"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 2,
            }}
          >
            <HighlightOffSharpIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mx: 2,
            fontSize: '0.75rem',
            textAlign: 'center',
            position: 'relative',
            bottom: '0.625rem',
          }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{ mb: 2, fontSize: 'inherit' }}
          >
            You can earn points and get rewards for learning online.
          </Typography>
        </Box>
      </DialogContent>
      <Divider>
        <Typography
          sx={{
            background: 'black',
            fontSize: 8,
            color: '#BDC2CA',
            py: '0.188rem',
            px: '0.375rem',
            borderRadius: '0.125rem',
            textTransform: 'uppercase',
          }}
        >
          What would you like to do first?
        </Typography>
      </Divider>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          pb: 2,
          fontSize: '0.563rem',
          fontWeight: 600,
        }}
      >
        <Link
          onClick={(): void => handleNavigation('/dashboard/earnPoints')}
          underline="none"
          sx={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textTransform: 'uppercase',
            m: '0!important',
            cursor: 'pointer',
          }}
        >
          How this Works
        </Link>
        <CircleIcon sx={{ fontSize: '0.313rem', mx: 2 }} />
        <Link
          onClick={(): void => handleNavigation('/dashboard/rewards')}
          underline="none"
          sx={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textTransform: 'uppercase',
            m: '0!important',
            cursor: 'pointer',
          }}
        >
          Get rewards
        </Link>
      </DialogActions>
    </Dialog>
  )
}

export default JoiningGreetDialog
