import { useQuery } from '@apollo/client'
import { Alert, Box, Grid, Icon, Link, Paper, Typography } from '@mui/material'
import bckwhite95opa from 'assets/img/1x1-ffffff95.png'
import Loading from 'components/molecules/Loading'
import { useLocation } from 'react-router-dom'
import { GET_GENERIC_CONTENT_BY_ID } from 'services/contentful/GenericPage'
import { GenericPageItem } from 'types/BaseContentDetailTypes'

import { RichText } from './ContentFulRenderer'

interface Back {
  text: string
  path: string
}

interface ContentCols {
  xs: number
  sm: number
  md: number
  lg: number
}

interface Props {
  text: string
  children: JSX.Element
  contentCols?: ContentCols
  back: Back
}

const CenterContainer = ({
  back,
  text,
  children,
  contentCols = { xs: 12, sm: 8, md: 4, lg: 3 },
}: Props): JSX.Element => {
  const location = useLocation()
  const isLogin = location && location.pathname === '/login' ? true : false

  const {
    loading: APILoading,
    error: APIError,
    data: data,
  } = useQuery(GET_GENERIC_CONTENT_BY_ID, {
    variables: { id: 3 }, // 3 is signIn
  })

  if (APILoading) {
    return <Loading />
  }

  if (APIError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = APIError
    return <Alert severity="error">Loading error :{error.message}</Alert>
  }

  const genericPageContent: GenericPageItem =
    data.genericPagesCollection.items[0]

  return (
    <Grid
      container
      component="main"
      justifyContent="center"
      alignItems="stretch"
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(' + genericPageContent.featuredImage.url + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor: t =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid
        item
        xs={contentCols.xs}
        sm={contentCols.sm}
        md={contentCols.md}
        lg={contentCols.lg}
        component={Paper}
        elevation={0}
        square
        sx={{
          minWidth: { md: '25rem' },
          mt: { xs: 6, md: 0 },
          mx: { xs: '1rem', md: 0 },
          mb: { xs: '1rem', md: 0 },
          p: { xs: '1rem', sm: '2rem', md: '2.5rem', lg: '3rem' },
          backgroundColor: 'transparent',
          backgroundImage: 'url(' + bckwhite95opa + ')',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Link
            href={back.path}
            color="primary"
            underline="none"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              sx={{
                fontSize: '2em',
                width: '0.8em',
                position: 'relative',
                left: '-0.625rem',
              }}
            >
              navigate_before
            </Icon>
            <Typography
              variant="body2"
              sx={{
                textTransform: 'uppercase',
                fontWeight: { md: 600 },
                fontSize: '1em',
              }}
            >
              {back.text}
            </Typography>
          </Link>

          <Typography
            className="rb-center-container-title"
            component="h1"
            variant="h4"
            sx={{
              fontSize: { xs: '1.6rem', md: '2.2rem' },
              textTransform: 'uppercase',
              fontWeight: 500,
              mt: { xs: 5, md: 8 },
              mb: { xs: isLogin ? 1 : 4, md: isLogin ? 1 : 6 },
            }}
          >
            {text}
          </Typography>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 800,
              display: location.state?.redirected ? 'block' : 'none',
            }}
          >
            Please sign in to be redirected to your selected page
          </Typography>
          {location &&
          location.pathname === '/login' &&
          genericPageContent &&
          genericPageContent.content &&
          genericPageContent.content.json ? (
            <Box sx={{ mb: { xs: 3, md: 5, fontSize: '0.9rem' } }}>
              <RichText json={genericPageContent.content.json} />
            </Box>
          ) : null}
          <Box sx={{ mt: 1 }}>{children}</Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CenterContainer
