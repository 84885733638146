import { format } from 'date-fns-tz'

export const dateFormat = (
  day: number | undefined,
  month: number | null | undefined,
  year: number
): string => {
  return format(new Date(year, month ? month : 1, day), 'MMMM dd, yyyy')
}

export const dateFormatNoYear = (
  day: number | undefined,
  month: number | null | undefined,
  year: number
): string => {
  return format(new Date(year, month ? month : 1, day), 'MMMM dd')
}

export const dateDisplayFormat = (date: string | Date): string => {
  return format(new Date(date), 'MMMM dd, yyyy')
}
