import { Provider } from 'jotai'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

type RootProviderProps = {
  children: React.ReactNode
}

export const RootProvider = ({ children }: RootProviderProps): JSX.Element => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Provider>{children}</Provider>
      </BrowserRouter>
    </React.StrictMode>
  )
}
