import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from 'App'
import config from 'appConfig'
import GA4React from 'ga-4-react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'
import { RootProvider } from 'rootProviders'
import theme from 'theme'

import './styles/index.scss'

const ga4react = new GA4React(config.ga.trackingCode, {
  debug_mode: false,
  send_page_view: true,
})

export const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  uri: config.contentful.uri,
  credentials: config.contentful.credentials,
  headers: {
    Authorization: `Bearer ${config.contentful.token}`,
  },
  cache: new InMemoryCache(),
})

//
;(async (): Promise<void> => {
  const root = createRoot(document.getElementById('root') as HTMLElement)
  // Intialize Google Analytics before DOM render
  await ga4react.initialize()
  root.render(
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootProvider>
          <App />
        </RootProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
})()

reportWebVitals()
