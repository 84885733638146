import { useQuery } from '@apollo/client'
import { Alert } from '@mui/material'
import ContentCard from 'components/molecules/ContentCard'
import Loading from 'components/molecules/Loading'
import { LATEST_CONTENT } from 'services/contentful/BaseContentList'
import { dateFormat } from 'utils/date'

const LatestContentWrapper = (): JSX.Element => {
  const { loading, error, data } = useQuery(LATEST_CONTENT)
  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">Latest Content error :{error.message}</Alert>
  }

  const latestContent = data?.baseContentCollection?.items?.length
    ? data.baseContentCollection.items[0]
    : []

  return latestContent ? (
    <ContentCard
      type={latestContent.content.__typename}
      id={latestContent.sys.id}
      featuredImage={latestContent.image.url}
      heading={latestContent.title}
      shortText={latestContent.abstract}
      tags={latestContent.contentfulMetadata.tags}
      whom="Admin"
      date={dateFormat(
        latestContent.publishDay,
        latestContent.publishMonth,
        latestContent.publishYear
      )}
      more={`${latestContent.content.__typename}${latestContent.slug}?id=${latestContent.content.sys.id}`}
      points={latestContent.points}
      size="large"
    />
  ) : (
    <></>
  )
}

export default LatestContentWrapper
