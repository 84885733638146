import { Box, List, TablePagination } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import ActivityListItem from 'components/atoms/ActivityListItem'
import { ActivityListItemType } from 'types/UserSettingsTypes'

interface Props {
  activityList: ActivityListItemType[]
  showYear?: boolean
  sx?: SxProps<Theme>
  count: number
  page: number
  rowsPerPage: number
  onPrintRewards?: (rewardFulFillmentId: string) => void
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const AllActivityListWrapper = ({
  activityList = [],
  showYear,
  sx = [],
  count,
  page,
  rowsPerPage,
  onPrintRewards,
  onPageChange,
  onRowsPerPageChange,
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <List
        dense={false}
        sx={[{ width: '100%', p: 0 }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {activityList.map((item, index) => (
          <ActivityListItem
            key={index}
            rewardContentId={item.rewardContentId}
            rewardFulFillmentId={item.rewardFulFillmentId}
            baseContentId={item.baseContentId}
            rewardId={item.rewardId}
            title={item.title}
            points={item.points}
            createdDate={item.createdDate}
            showYear={showYear}
            type={item.type}
            rewardCode={item.rewardCode}
            rewardType={item.rewardType}
            onPrintRewards={onPrintRewards}
          />
        ))}
      </List>
      <TablePagination
        labelRowsPerPage="Activities per page"
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  )
}

export default AllActivityListWrapper
