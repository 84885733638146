import { gql } from '@apollo/client'

import { BASE_CONTENT_FIELDS } from './Fragments'

export const GET_ARTICLES_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query GetArticleList($limit: Int!, $skip: Int!) {
    articleCollection(limit: $limit, skip: $skip) {
      total
      items {
        sys {
          id
          publishedAt
        }
        linkedFrom {
          baseContentCollection(limit: 1) {
            items {
              ...BaseContent
            }
          }
        }
      }
    }
  }
`
export const GET_FLIPCARD_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query GetFlipcardList($limit: Int!, $skip: Int!) {
    flipcardCollection(limit: $limit, skip: $skip) {
      total
      items {
        sys {
          id
          publishedAt
        }
        linkedFrom {
          baseContentCollection(limit: 1) {
            items {
              ...BaseContent
            }
          }
        }
      }
    }
  }
`
export const GET_VIDEO_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query GetVideoList($limit: Int!, $skip: Int!) {
    videoCollection(limit: $limit, skip: $skip) {
      total
      items {
        sys {
          id
          publishedAt
        }
        linkedFrom {
          baseContentCollection(limit: 1) {
            items {
              ...BaseContent
            }
          }
        }
      }
    }
  }
`

// Search
export const SEARCH_ARTICLES_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query SearchArticleList($limit: Int!, $skip: Int!, $searchString: String!) {
    articleCollection(
      limit: $limit
      skip: $skip
      where: { content_contains: $searchString }
    ) {
      total
      items {
        sys {
          id
          publishedAt
        }
        linkedFrom {
          baseContentCollection(limit: 1) {
            items {
              ...BaseContent
            }
          }
        }
      }
    }
  }
`
export const SEARCH_FLIPCARD_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query SearchFlipcardList($limit: Int!, $skip: Int!, $searchString: String!) {
    flipcardCollection(
      limit: $limit
      skip: $skip
      where: {
        OR: [
          { frontMessage_contains: $searchString }
          { backMessage_contains: $searchString }
        ]
      }
    ) {
      total
      items {
        sys {
          id
          publishedAt
        }
        linkedFrom {
          baseContentCollection(limit: 1) {
            items {
              ...BaseContent
            }
          }
        }
      }
    }
  }
`
export const SEARCH_VIDEO_LIST = gql`
  ${BASE_CONTENT_FIELDS}
  query SearchVideoList($limit: Int!, $skip: Int!, $searchString: String!) {
    videoCollection(
      limit: $limit
      skip: $skip
      where: { youtubeVideoId_contains: $searchString }
    ) {
      total
      items {
        sys {
          id
          publishedAt
        }
        linkedFrom {
          baseContentCollection(limit: 1) {
            items {
              ...BaseContent
            }
          }
        }
      }
    }
  }
`
