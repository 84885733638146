import { useQuery } from '@apollo/client'
import { Alert, Typography } from '@mui/material'
import Loading from 'components/molecules/Loading'
import ArticleWrapper from 'components/organisms/ArticleWrapper'
import FlipCardWrapper from 'components/organisms/FlipCardWrapper'
import VideoWrapper from 'components/organisms/VideoWrapper'
import {
  GET_ARTICLE_BY_ID,
  GET_FLIPCARD_BY_ID,
  GET_VIDEO_BY_ID,
} from 'services/contentful/ContentDetailsById'
import {
  ArticleType,
  FlipCardType,
  VideoType,
} from 'types/BaseContentDetailTypes'

export interface ContentWrapperType {
  id: string | undefined
  type: string | undefined
  disabledEarnPoint: boolean
  onBaseContentIdUpdate: (baseContentId: string) => void
  onContentView: (
    baseContentId: string,
    contentId: string,
    type: string,
    points: number,
    title: string
  ) => void
  onEarnPointsClick: (
    baseContentId: string,
    contentId: string,
    type: string,
    points: number,
    title: string
  ) => void
}

const UnableToLoad = ({ type = 'Content' }: { type?: string }): JSX.Element => {
  return <Typography variant="overline">{type} Not Available</Typography>
}

const ContentWrapper = ({
  type,
  id,
  disabledEarnPoint,
  onBaseContentIdUpdate,
  onContentView,
  onEarnPointsClick,
}: ContentWrapperType): JSX.Element => {
  if (id && type) {
    const contentType = String.prototype.toLowerCase.apply(type)
    switch (contentType) {
      case 'article': {
        const {
          loading: APILoading,
          error: APIError,
          data: data,
        } = useQuery(GET_ARTICLE_BY_ID, {
          variables: { id: id },
        })

        if (APILoading) {
          return <Loading />
        }

        if (APIError) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error: any = APIError
          return (
            <Alert severity="error">
              {type} Loading error :{error.message}
            </Alert>
          )
        }
        const article: ArticleType = data.article

        const baseContentId =
          article.linkedFrom.baseContentCollection.items[0].sys.id

        // Send back base content id to validate
        onBaseContentIdUpdate(baseContentId)

        if (article) {
          return (
            <ArticleWrapper
              type={type}
              id={id}
              baseContentId={baseContentId}
              data={article}
              disabledEarnPoint={disabledEarnPoint}
              onContentView={onContentView}
              onEarnPointsClick={onEarnPointsClick}
            />
          )
        } else {
          return <UnableToLoad type={type} />
        }
      }
      case 'flipcard': {
        const {
          loading: APILoading,
          error: APIError,
          data: data,
        } = useQuery(GET_FLIPCARD_BY_ID, {
          variables: { id: id },
        })

        if (APILoading) {
          return <Loading />
        }

        if (APIError) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error: any = APIError
          return (
            <Alert severity="error">
              {type} Loading error :{error.message}
            </Alert>
          )
        }

        const flipCard: FlipCardType = data.flipcard

        const baseContentId =
          flipCard.linkedFrom.baseContentCollection.items[0].sys.id

        // Send back base content id to validate
        onBaseContentIdUpdate(baseContentId)

        if (flipCard) {
          return (
            <FlipCardWrapper
              type={type}
              id={id}
              baseContentId={baseContentId}
              data={flipCard}
              disabledEarnPoint={disabledEarnPoint}
              onContentView={onContentView}
              onEarnPointsClick={onEarnPointsClick}
            />
          )
        } else {
          return <UnableToLoad type={type} />
        }
      }
      case 'video': {
        const {
          loading: APILoading,
          error: APIError,
          data: data,
        } = useQuery(GET_VIDEO_BY_ID, {
          variables: { id: id },
        })

        if (APILoading) {
          return <Loading />
        }

        if (APIError) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error: any = APIError
          return (
            <Alert severity="error">
              {type} Loading error :{error.message}
            </Alert>
          )
        }
        const video: VideoType = data.video

        const baseContentId =
          video.linkedFrom.baseContentCollection.items[0].sys.id

        // Send back base content id to validate
        onBaseContentIdUpdate(baseContentId)

        if (video) {
          return (
            <VideoWrapper
              type={type}
              id={id}
              baseContentId={baseContentId}
              data={video}
              disabledEarnPoint={disabledEarnPoint}
              onContentView={onContentView}
              onEarnPointsClick={onEarnPointsClick}
            />
          )
        } else {
          return <UnableToLoad type={type} />
        }
      }

      default:
        return <Typography variant="overline">Work in Progress</Typography>
    }
  }
  return <UnableToLoad />
}

export default ContentWrapper
