import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Reward } from 'types/admin'

interface Props {
  reward: Reward | undefined
  onClose?: () => void
  onUpdate: (reward: Reward, quantity: number) => void
  open: boolean
}
const UpdateCouponQuantityDialog = ({
  onClose,
  onUpdate,
  reward,
  open = false,
}: Props): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<number>(0)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ quantity: number; reason: string }>()

  const onSubmit: SubmitHandler<{ quantity: number; reason: string }> = ({
    quantity,
  }) => {
    if (reward) {
      onUpdate(reward, quantity)
    }
  }

  const handleClose = (): void => {
    setDialogOpen(false)
    setQuantity(0)
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  useEffect(() => {
    if (reward) {
      const quantity = reward.inventoryQuantity ? reward.inventoryQuantity : 0
      setQuantity(quantity)
      setValue('quantity', quantity, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }, [reward])

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
    >
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
        >
          <TextField
            fullWidth
            required
            label="Quantity"
            size="small"
            type="number"
            {...register('quantity', {
              required: 'Quantity is required ',
              pattern: {
                value: /^\d*$/g,
                message: 'Not a valid quantity.',
              },
              min: {
                value: 0,
                message: `Negative quantity is not allowed.`,
              },
            })}
            inputProps={{ min: 0 }}
            placeholder="0"
            error={errors?.quantity ? true : false}
            helperText={
              errors?.quantity && errors.quantity.message
                ? String(errors.quantity.message)
                : `Available quantity ${quantity}`
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={handleSubmit(onSubmit)}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateCouponQuantityDialog
