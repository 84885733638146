import { Container, Grid } from '@mui/material'
import rewardsBanner from 'assets/img/rewards_banner.jpg'
import CongratsDialog from 'components/molecules/CongratsDialog'
import ScoreBadge from 'components/molecules/ScoreBadge'
import RewardsDetailsWrapper from 'components/organisms/RewardsDetailsWrapper'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { alertAtom } from 'stores'
import { pointsAtom, purchaseReward } from 'stores/user'
import { userAtom } from 'stores/user'
import { RewardsItemType } from 'types/RewardTypes'
import { purchaseRewardEvent } from 'utils/analytics'

const RewardDetailsContainer = (): JSX.Element => {
  const [user] = useAtom(userAtom)
  const [pointBalance, setPointBalance] = useAtom(pointsAtom)
  const [showCongratsDialog, setShowCongratsDialog] = useState(false)
  const [reward, setReward] = useState<RewardsItemType | null>(null)
  const [, setAlert] = useAtom(alertAtom)

  const { id } = useParams()

  const handleCongratsDialogClose = (): void => {
    setShowCongratsDialog(false)
  }

  const handleSetReward = (rewardItem: RewardsItemType): void => {
    setReward(rewardItem)
  }

  const handleGetReward = (): void => {
    if (user && user.userId && reward && reward.sys.id) {
      purchaseReward(user.userId, reward.sys.id)
        .then(response => {
          //GA-purchase
          purchaseRewardEvent(
            user.postalCode,
            reward.sys.id,
            reward.rewardTitle,
            reward.points,
            reward.partner.sys.id
          )

          // On transaction success update points
          setPointBalance(response.data.totalPoints)
          setShowCongratsDialog(true)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to get the reward. Try again.',
            autoHideDuration: 2000,
          })
        })
    }
  }

  return (
    <AuthenticatedPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          backgroundImage: 'url(' + rewardsBanner + ')',
          height: '8rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'end' },
          alignItems: 'baseline',
          py: 3,
        }}
      >
        <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems={{ xs: 'end', md: 'center' }}
            color="white"
          >
            <Grid
              item
              sx={{
                position: 'relative',
                top: { xs: '2rem', md: '1rem' },
                zIndex: 100,
              }}
            >
              <ScoreBadge points={pointBalance} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth="lg"
        className="rb-rewards-details-container"
        sx={{ mt: { md: '-2rem' } }}
      >
        <RewardsDetailsWrapper
          id={id === undefined ? null : id}
          setReward={handleSetReward}
          onGetReward={handleGetReward}
        />
      </Container>
      <CongratsDialog
        onClose={handleCongratsDialogClose}
        onSubmit={handleCongratsDialogClose}
        open={showCongratsDialog}
      >
        {reward ? (
          <>
            You have just ordered <em>{reward.rewardTitle}</em> for{' '}
            <strong>{reward.points}</strong> points.
          </>
        ) : (
          'Unknown reward'
        )}
      </CongratsDialog>
    </AuthenticatedPageWrapper>
  )
}

export default RewardDetailsContainer
