import { AxiosResponse } from 'axios'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {}

const saves = {
  supportTicket(params: {
    subject: string
    type: string
    subType: string
    comment: string
  }): Promise<AxiosResponse<string, any>> {
    return axiosInstance.post('/ticket', params)
  },
}

const deletes = {}

export default class SupportService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
