import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import StarIcon from '@mui/icons-material/Star'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material'
import Tag from 'components/atoms/Tag'
import LinesEllipsis from 'react-lines-ellipsis'

interface MediaCardProps {
  type?: string
  id: string
  heading: string
  featuredImage: string
  shortText?: string
  tags?: { label: string; type: string }[]
  whom?: string
  date?: string
  points: number
  more?: string
  elevation?: number
  showReadMore?: boolean
  onReadMore?: (to: string) => void
}

const MediaCard = ({
  type,
  id,
  heading,
  featuredImage,
  shortText,
  tags = [],
  whom,
  date,
  points,
  more = '#',
  elevation = 1,
  showReadMore = false,
  onReadMore,
}: MediaCardProps): JSX.Element => {
  const handleReadMore = (): void => {
    if (onReadMore) onReadMore(more)
  }
  return (
    <Card className={type} id={id} elevation={elevation}>
      <CardMedia
        component="img"
        height="140"
        image={featuredImage}
        alt={heading}
      />
      <CardContent>
        <Stack direction="row" spacing={1} sx={{ mt: 0, mb: 1 }}>
          {tags.map((tag, i) => (
            <Tag key={i} label={tag.label} />
          ))}
        </Stack>
        <Typography
          gutterBottom
          variant="subtitle1"
          sx={{ fontWeight: 600, mb: 0, minHeight: '3.5rem' }}
        >
          <LinesEllipsis
            text={heading}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Typography>
        <Typography variant="xsmall" component="small" sx={{ mb: 1.5 }}>
          {whom} | {date}
        </Typography>
        <Typography variant="small" sx={{ minHeight: '2.925rem' }}>
          <LinesEllipsis
            text={shortText}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: 'inherit',
            color: '#FFA000',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <StarIcon fontSize="small" sx={{ mr: 0.5, mt: -0.5 }} />
          {points}
        </Typography>
        {showReadMore ? (
          <Button
            size="small"
            endIcon={
              <ArrowForwardIosIcon sx={{ fontSize: '0.75rem !important' }} />
            }
            sx={{ fontSize: '0.75rem' }}
            onClick={handleReadMore}
          >
            Read More
          </Button>
        ) : null}
      </CardActions>
    </Card>
  )
}

export default MediaCard
