import { createTheme, responsiveFontSizes } from '@mui/material/styles'

// Custom Typography
declare module '@mui/material/styles' {
  interface TypographyVariants {
    small: React.CSSProperties
    xsmall: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    xsmall?: React.CSSProperties
    small?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true
    xsmall: true
  }
}

// Recyclebank theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#008f6b',
    },
    secondary: {
      main: '#0c1c2a',
    },
    warning: {
      main: '#FFA000',
    },
    text: {
      primary: '#0C1C2A',
    },
  },
  typography: {
    fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
    },
    h2: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
    },
    h3: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
    },
    h4: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
    },
    h6: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
    },
    body2: {
      fontSize: '0.85rem',
    },
    xsmall: {
      fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.65rem',
    },
    small: {
      fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.75rem',
    },
    button: {
      fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.7rem',
      lineHeight: 1.7,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
  },
})

export default responsiveFontSizes(theme)
