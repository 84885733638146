import { useQuery } from '@apollo/client'
import { Alert, Box, Link, Typography } from '@mui/material'
import ContentCard from 'components/molecules/ContentCard'
import Loading from 'components/molecules/Loading'
import { POPULAR_CONTENT_LIST } from 'services/contentful/BaseContentList'
import { PopularContentItemType } from 'types/ContentListTypes'

const PopularReadsWrapper = ({
  limit = 5,
  title = 'Featured Content',
  onCardClick,
}: {
  limit?: number
  title?: string
  onCardClick?: (to: string) => void
}): JSX.Element => {
  const getRandomSkip = (min: number, max: number): number => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const {
    loading: loading,
    error: error,
    data: data,
  } = useQuery(POPULAR_CONTENT_LIST, {
    variables: {
      limit: limit,
      skip: getRandomSkip(0, 20),
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">Featured Content :{error.message}</Alert>
  }

  const popularReadsList = data?.baseContentCollection?.items?.length
    ? data.baseContentCollection.items
    : []

  const onPopularCardClick = (to: string): void => {
    if (onCardClick) onCardClick(to)
  }

  return (
    <Box
      className="rb_most_popular_reads"
      sx={{
        backgroundColor: '#F5F7FA',
        px: 1,
        pt: 2,
        pb: 1,
        mb: 1,
      }}
    >
      <Typography
        variant="body2"
        align="left"
        fontWeight="600"
        paragraph
        textTransform="capitalize"
        sx={{ mb: 2 }}
      >
        {title}
      </Typography>
      {popularReadsList.map((item: PopularContentItemType, i: number) => (
        <Link
          onClick={(): void => {
            onPopularCardClick(
              `${item.content.__typename}${item.slug}?id=${item.content.sys.id}`
            )
          }}
          key={item.sys.id}
          underline="none"
          sx={{
            cursor: 'pointer',
            '&:hover': {
              '.rb-story-card-container': {
                boxShadow:
                  'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
              },
            },
          }}
        >
          <ContentCard
            type={item.content.__typename}
            id={item.sys.id}
            featuredImage={item.image.url}
            heading={item.title}
            isLast={i + 1 === popularReadsList.length}
            size="small"
          />
        </Link>
      ))}
    </Box>
  )
}

export default PopularReadsWrapper
