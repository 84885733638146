import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import SettingsCard from 'components/molecules/SettingsCard'

interface Props {
  address: string
  displayName: string
  email: string
  photoURL: string
  onAccountSettings: () => void
}

const User = ({
  displayName,
  email,
  address,
  photoURL,
  onAccountSettings,
}: Props): JSX.Element => {
  return (
    <Box sx={{ backgroundColor: '#fff' }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'center',
            backgroundColor: '#fff',
            height: 'calc(7rem / 2)',
          }}
        >
          <Avatar
            alt={displayName}
            src={photoURL}
            sx={{ width: '7rem', height: '7rem' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            my: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {displayName}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: '1px 0 1px 0px',
            borderColor: '#BDC2CA',
            p: 1.2,
            mx: 0.5,
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            size="sm"
            color="#BDC2CA"
            style={{ marginRight: '0.3rem', fontSize: '0.6rem' }}
          />
          <Typography variant="small">{email}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: '0 0 1px 0px',
            borderColor: '#BDC2CA',
            p: 1.2,
            mx: 0.5,
          }}
        >
          <FontAwesomeIcon
            icon={faLocationDot}
            size="sm"
            color="#BDC2CA"
            style={{ marginRight: '0.3rem', fontSize: '0.6rem' }}
          />
          <Typography variant="small">{address}</Typography>
        </Grid>
      </Grid>

      <SettingsCard onAccountSettings={onAccountSettings} />
    </Box>
  )
}

export default User
