import { Box, Tab, Tabs } from '@mui/material'
import EditProfile from 'components/molecules/EditProfile'
import UpdatePassword from 'components/molecules/UpdatePassword'
import { useState } from 'react'
import { PasswordResetFields, UpdateUser } from 'types/AuthTypes'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      component="div"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      sx={{ width: '100%' }}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  )
}

const a11yProps = (index: number): any => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

interface Props extends UpdateUser {
  photoURL: string
  onProfileUpdate: (data: UpdateUser) => void
  onPasswordUpdate: (data: PasswordResetFields) => void
}

const EditProfileWrapper = ({
  photoURL,
  firstName,
  lastName,
  loginEmail,
  houseNumber,
  street,
  city,
  stateProvince,
  postalCode,
  country,
  onProfileUpdate,
  onPasswordUpdate,
}: Props): JSX.Element => {
  const [value, setValue] = useState(0)

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValue(newValue)
  }

  return (
    <Box sx={{ my: { xs: 2, md: 2 } }}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: { xs: '100%', md: '83.5%' },
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Account Settings"
          sx={{
            borderRight: { xs: 0, md: '1px solid rgba(0, 0, 0, 0.12)' },
            borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.12)', md: 0 },
            borderColor: 'divider',
            '& .MuiTab-root': {
              alignItems: 'start',
            },
            minWidth: { xs: '100%', md: '170px' },
            minHeight: { xs: 'auto', md: '50vh' },
          }}
        >
          <Tab label="Edit Profile" {...a11yProps(0)} />
          <Tab label="Change Password" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <EditProfile
            photoURL={photoURL}
            firstName={firstName}
            lastName={lastName}
            loginEmail={loginEmail}
            houseNumber={houseNumber}
            street={street}
            city={city}
            stateProvince={stateProvince}
            postalCode={postalCode}
            country={country}
            onUpdate={onProfileUpdate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UpdatePassword onSubmit={onPasswordUpdate} />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default EditProfileWrapper
