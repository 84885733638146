import config from 'appConfig'
import { initializeApp } from 'firebase/app'
import {
  Auth,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
  updatePassword,
} from 'firebase/auth'

class Firebase {
  auth: Auth
  constructor() {
    const app = initializeApp(config.fireBase)
    this.auth = getAuth(app)
  }

  // AUTHENTICATION ------------
  createAccount = (email: string, password: string): Promise<UserCredential> =>
    createUserWithEmailAndPassword(this.auth, email, password)

  signIn = (email: string, password: string): Promise<UserCredential> =>
    signInWithEmailAndPassword(this.auth, email, password)

  signInWithGoogle = (): Promise<UserCredential> => {
    const provider = new GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    return signInWithPopup(this.auth, provider)
  }

  signInWithFacebook = (): Promise<UserCredential> => {
    const provider = new FacebookAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    return signInWithPopup(this.auth, provider)
  }

  passwordReset = (email: string): Promise<void> =>
    sendPasswordResetEmail(this.auth, email)

  reauthenticate = (currentPassword: string): Promise<UserCredential> => {
    if (this.auth && this.auth.currentUser && this.auth.currentUser.email) {
      return reauthenticateWithCredential(
        this.auth.currentUser,
        EmailAuthProvider.credential(
          this.auth.currentUser.email,
          currentPassword
        )
      )
        .then((credential: UserCredential) => {
          // User successfully reauthenticated.
          // Update user
          return Promise.resolve(credential)
        })
        .catch(error => Promise.reject(error))
    } else {
      return Promise.reject(new Error('User not available!'))
    }
  }

  updateUserPassword = (
    currentPassword: string,
    newPassword: string
  ): Promise<void> => {
    return this.reauthenticate(currentPassword)
      .then((credential: UserCredential) => {
        return updatePassword(credential.user, newPassword)
      })
      .catch(error => Promise.reject(error))
  }

  updateEmail = (currentPassword: string, newEmail: string): Promise<void> => {
    return this.reauthenticate(currentPassword)
      .then((credential: UserCredential) => {
        return updateEmail(credential.user, newEmail)
      })
      .catch(error => Promise.reject(error))
  }

  onAuthStateChanged = (): Promise<unknown> => {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged(user => {
        if (user) {
          resolve(user)
        } else {
          reject(new Error('Auth State Changed failed'))
        }
      })
    })
  }

  getIdToken = (): Promise<string | void> => {
    if (this.auth && this.auth.currentUser) {
      return this.auth.currentUser.getIdToken() // always returns latest idToken
    } else {
      return Promise.reject(new Error('User not available!'))
    }
  }

  deleteUser = (): Promise<void> => {
    if (this.auth && this.auth.currentUser) {
      return deleteUser(this.auth.currentUser)
    } else {
      return Promise.reject(new Error('User not available!'))
    }
  }

  signOut = (): Promise<void> =>
    signOut(this.auth).then(() => {
      // on complete route the user to landing page
      window.location.href = '/'
    })

  // setAuthPersistence = () =>
  //   this.auth.setPersistence(app.auth.Auth.Persistence.LOCAL)
}

const firebaseInstance = new Firebase()

export default firebaseInstance
