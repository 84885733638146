import { keyframes } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from '@mui/material'
import FooterBck from 'assets/img/404_bck.svg'
import { ReactComponent as Recycling } from 'assets/img/Recycling_symbol.svg'
import { ReactComponent as Hauler } from 'assets/img/hauler.svg'
import { useNavigate } from 'react-router-dom'

const move = keyframes`
  to {
    transform: translateX(1200px);
  }
`

const NotFound = (): JSX.Element => {
  const navigate = useNavigate()
  const handleOnBtnClick = (): void => {
    navigate('/dashboard')
  }
  return (
    <Container
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#F5F7FA',
      }}
      maxWidth={false}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '.25rem',
          width: { xs: '90%', md: '50%' },
          minHeight: '55vh',
          boxShadow: '0px 4px 30px rgba(12, 28, 42, 0.15)',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '25rem',
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{ m: 0, p: 0 }}
            fontWeight="400"
          >
            4<Recycling fill="#008f6b" height="55" width="65" />4
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ mb: 2, fontSize: '0.9rem' }}
          >
            Sorry, the page you visited does not exist
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnBtnClick}
          >
            Back to Home
          </Button>
        </CardContent>
        <CardActions
          sx={{
            backgroundImage: 'url(' + FooterBck + ')',
            backgroundRepeat: 'repeat',
            backgroundSize: { xs: 'cover', md: 'contain' },
            backgroundPosition: 'top',
            width: '100%',
            borderBottom: '1rem solid #0C1C2A',
            height: '7rem',
            p: 0,
          }}
        >
          <Box
            sx={{
              animation: `${move} 10s infinite linear`,
              animationDuration: '28s',
              animationDelay: '0s',
              mb: '-0.5rem',
            }}
          >
            <Hauler height="6.25rem" width="100%" />
          </Box>
        </CardActions>
      </Card>
    </Container>
  )
}
export default NotFound
