import { Button, Grid, Link, TextField, Typography } from '@mui/material'
import CenterContainer from 'components/organisms/CenterContainer'
import PageWrapper, {
  AlertProp,
  defaultAlertDefn,
} from 'components/templates/PageWrapper'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import firebase from 'services/firebase'
import { ForgotPasswordInput } from 'types/AuthTypes'
import { exceptionEvent } from 'utils/analytics'
import { getErrorMessage } from 'utils/fireBaseErrorCodes'
import { getRegex } from 'utils/helpers'

const Reset = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordInput>()

  const onSubmit: SubmitHandler<ForgotPasswordInput> = (
    data: ForgotPasswordInput
  ) => {
    firebase
      .passwordReset(data.email)
      .then(() => {
        setAlert({
          show: true,
          message: 'Password reset link sent!',
          type: 'success',
        })
      })
      .catch(error => {
        exceptionEvent(error)
        setAlert({
          show: true,
          message: getErrorMessage(error.code),
          type: 'error',
        })
      })
  }

  const [alert, setAlert] = useState<AlertProp>(defaultAlertDefn)

  return (
    <PageWrapper alert={alert}>
      <CenterContainer
        back={{ text: 'back to home', path: '/' }}
        text="Forgot password"
        contentCols={{ xs: 12, sm: 8, md: 5, lg: 3 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container alignItems={'flex-start'}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus
                label="Email Address"
                {...register('email', {
                  required: 'Email Address is Required ',
                  pattern: {
                    value: getRegex('email'),
                    message: 'Invalid Email Address',
                  },
                })}
                error={errors?.email ? true : false}
                helperText={errors?.email ? errors.email.message : ' '}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Send password reset email
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" fontSize="0.7rem" sx={{ mt: 2 }}>
                Not a member? <br />
              </Typography>
              <Typography align="center">
                <Link
                  href="/register"
                  underline="none"
                  textTransform="uppercase"
                >
                  Register Now
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </CenterContainer>
    </PageWrapper>
  )
}
export default Reset
