import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { Reward } from 'types/admin'

interface Props {
  reward: Reward | undefined
  onClose?: () => void
  onUpdate: (
    reward: Reward,
    value: null | 'Generated' | 'Single' | 'Multiple'
  ) => void
  open: boolean
}
const CodeTypeUpdateDialog = ({
  onClose,
  onUpdate,
  reward,
  open = false,
}: Props): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>()

  const handleRadioChange = (value: string): void => {
    setValue(value)
  }

  const handleSubmit = (): void => {
    if (reward && value) {
      const codeType: any = value === 'None' ? null : value
      onUpdate(reward, codeType)
    }
  }
  const handleClose = (): void => {
    setDialogOpen(false)
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  useEffect(() => {
    if (reward) {
      const type = reward.codeType ? reward.codeType : 'None'
      setValue(type)
    }
  }, [reward])

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      BackdropProps={{ style: { backgroundColor: '#0C1C2A', opacity: '0.8' } }}
    >
      <DialogContent>
        <RadioGroup
          aria-labelledby="code-type-radios"
          name="codeType"
          value={value}
          onChange={(
            event: ChangeEvent<HTMLInputElement>,
            value: string
          ): void => handleRadioChange(value)}
        >
          <FormControlLabel
            value="Single"
            componentsProps={{ typography: { variant: 'subtitle2' } }}
            control={<Radio size="small" />}
            label="Single"
          />
          <FormControlLabel
            value="Multiple"
            componentsProps={{ typography: { variant: 'subtitle2' } }}
            control={<Radio size="small" />}
            label="Multiple"
          />
          <FormControlLabel
            value="Generated"
            componentsProps={{ typography: { variant: 'subtitle2' } }}
            control={<Radio size="small" />}
            label="Generated"
          />
          <FormControlLabel
            value="None"
            componentsProps={{ typography: { variant: 'subtitle2' } }}
            control={<Radio size="small" />}
            label="None"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={
            value === reward?.codeType ||
            (value === 'None' && reward?.codeType === null)
          }
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CodeTypeUpdateDialog
