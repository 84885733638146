import { useQuery } from '@apollo/client'
import { Alert, Box, Container, Typography } from '@mui/material'
import Loading from 'components/molecules/Loading'
import { RichText } from 'components/organisms/ContentFulRenderer'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import PageWrapper from 'components/templates/PageWrapper'
import { format } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { GET_GENERIC_CONTENT_BY_ID } from 'services/contentful/GenericPage'
import { isLoggedInAtom } from 'stores/auth'
import { GenericPageItem } from 'types/BaseContentDetailTypes'

const TermsAndConditionsContent = (): JSX.Element => {
  const {
    loading: APILoading,
    error: APIError,
    data: data,
  } = useQuery(GET_GENERIC_CONTENT_BY_ID, {
    variables: { id: 5 }, // 5 is terms and conditions
  })

  if (APILoading) {
    return <Loading />
  }

  if (APIError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = APIError
    return <Alert severity="error">Loading error :{error.message}</Alert>
  }
  const genericPageContent: GenericPageItem =
    data.genericPagesCollection.items[0]
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'end' },
        alignItems: 'center',
        py: 3,
      }}
    >
      <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
        <Typography
          variant="h4"
          sx={{ mt: 8, mb: 2, fontWeight: 600, textTransform: 'uppercase' }}
        >
          {genericPageContent.title}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {`Last Modified: ${format(
            new Date(genericPageContent.sys.publishedAt),
            'MMMM dd, yyyy'
          )}`}
        </Typography>
        <RichText json={genericPageContent.content.json} />
      </Container>
    </Box>
  )
}

const TermsAndConditions = (): JSX.Element => {
  const [isLoggedIn] = useAtom(isLoggedInAtom)

  return isLoggedIn ? (
    <AuthenticatedPageWrapper headerElevation={0}>
      <TermsAndConditionsContent />
    </AuthenticatedPageWrapper>
  ) : (
    <PageWrapper isPrimary hasFooter>
      <TermsAndConditionsContent />
    </PageWrapper>
  )
}

export default TermsAndConditions
