import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { SxProps, Theme, alpha, styled, useTheme } from '@mui/material/styles'
import NotificationBar from 'components/atoms/NotificationBar'
import Footer from 'components/molecules/Footer'
import Header from 'components/molecules/HeaderInternal'
import { useAtom } from 'jotai'
import { ReactNode, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { alertAtom } from 'stores'
import { logout } from 'stores/auth'
import { userAtom } from 'stores/user'

const pagesMenu = [
  { name: 'Learn & Earn', path: '/dashboard/learnEarn' },
  // { name: 'Categories', path: '/dashboard/categories' },
  { name: 'How this Works', path: '/dashboard/earnPoints' },
  { name: 'Get Rewards', path: '/dashboard/rewards' },
]

const settingsMenu = [
  { name: 'Profile', path: '/dashboard/settings', icon: 'person' },
  { name: 'Logout', path: '', icon: 'logout' },
]

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

interface Link {
  name: string
  path: string
  icon?: string
}

const AuthenticatedPageWrapper = ({
  children,
  headerElevation = 1,
  sx,
}: {
  children: ReactNode
  headerElevation?: number
  sx?: SxProps<Theme>
}): JSX.Element => {
  const [user] = useAtom(userAtom)
  const navigate = useNavigate()
  const name = user ? `${user.firstName} ${user.lastName}` : ''

  const [alert, setAlert] = useAtom(alertAtom)

  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleNotificationClose = (): void => {
    setAlert(null)
  }

  const handleMenuClick = (menuItem: Link): void => {
    if (menuItem.name === 'Logout') {
      logout()
      navigate('/')
    } else if (menuItem.name === 'Profile' && menuItem.path) {
      navigate(menuItem.path)
    }
  }

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  return (
    <Box
      component="main"
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      className="rb-authenticated-page-wrapper"
    >
      <Drawer
        sx={{
          width: '15rem',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '15rem',
            boxSizing: 'border-box',
          },
        }}
        anchor="right"
        open={open}
        className="rb-side-nav"
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ p: 0 }}>
          {pagesMenu.map(item => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{ borderBottom: '1px solid #BDC2CA' }}
            >
              <ListItemButton
                sx={{
                  p: 0,
                  '& a': {
                    px: 3,
                    py: 1.2,
                    textDecoration: 'none',
                    color: theme.palette.text.primary,
                    fontSize: '0.8rem',
                    width: '100%',
                    '&.active': {
                      color: theme.palette.primary.main,
                      backgroundColor: alpha(theme.palette.primary.main, 0.2),
                    },
                  },
                }}
              >
                {item.path ? (
                  <NavLink to={item.path} className="topNavLink">
                    {item.name}
                  </NavLink>
                ) : (
                  <ListItemText
                    onClick={(): void => handleMenuClick(item)}
                    primary={item.name}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Header
        name={name}
        pagesMenu={pagesMenu}
        settingsMenu={settingsMenu}
        onMenuClickCB={handleMenuClick}
        onDrawerOpen={handleDrawerOpen}
        elevation={headerElevation}
      />
      <Box
        component="section"
        className="rb_content_container"
        sx={[{ flexGrow: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {alert !== null ? (
          <NotificationBar
            open={alert.show}
            message={alert.message}
            onCloseCB={handleNotificationClose}
            type={alert.type}
            autoHideDuration={alert.autoHideDuration || null}
          />
        ) : null}
        {children}
      </Box>
      <Footer />
    </Box>
  )
}
export default AuthenticatedPageWrapper
