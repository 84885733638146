const config: any = {
  contentful: {
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`,
    credentials: 'same-origin',
    token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  },
  fireBase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  ga: {
    trackingCode: process.env.REACT_APP_GA_TRACKING_CODE,
    options: {
      debug: false,
      titleCase: false,
      gaOptions: {
        name: 'dev',
      },
    },
  },
}
export default config
