import { Box } from '@mui/material'
import ContentCard from 'components/molecules/ContentCard'
import { BaseContentItemType } from 'types/BaseContentTypes'
import { dateFormatNoYear } from 'utils/date'

interface Props {
  data: BaseContentItemType[]
}

const BaseContentList = ({ data }: Props): JSX.Element => {
  return (
    <Box component="div" className="rb_base_content_list">
      {data.map(item => (
        <ContentCard
          type={item.content.__typename}
          key={item.sys.id}
          id={item.sys.id}
          featuredImage={item.image.url}
          heading={item.title}
          shortText={item.abstract}
          tags={item?.contentfulMetadata?.tags}
          whom="Recyclebank"
          date={dateFormatNoYear(
            item.publishDay,
            item.publishMonth,
            item.publishYear
          )}
          more={`${item.content.__typename}${item.slug}?id=${item.content.sys.id}`}
          points={item.points}
        />
      ))}
    </Box>
  )
}

export default BaseContentList
