import { AxiosResponse } from 'axios'
import { atom } from 'jotai'
import AppService from 'services/app'

export const typesAtom = atom([
  { value: '', label: 'Select an option' },
  { value: '1', label: 'My Account' },
  { value: '2', label: 'Reward' },
  { value: '3', label: 'Technical Problem' },
  { value: '4', label: 'Other' },
])

export const selectedTypeAtom = atom<string>('')
export const selectedSubTypeAtom = atom<string>('')

export const subTypesAtom = atom({
  '1': [
    {
      id: '1',
      position: '10',
      label: 'I want to check my points balance',
      help_message:
        "Your points balance can be found at the top right of any page. If you're curious about your points to date, click your name and then All Activity.",
      can_submit: '0',
    },
    {
      id: '4',
      position: '40',
      label: 'I want to change my email address',
      help_message:
        "This can be done by clicking your name, then selecting Settings. You'll see a section called Account Information. Click Edit and change your email address.",
      can_submit: '0',
    },
    {
      id: '5',
      position: '50',
      label: 'I want to change my password',
      help_message:
        "This can be done by clicking your name, then selecting Settings. You'll see a section titled Account Information, where you can click Edit and change your password.",
      can_submit: '0',
    },
    {
      id: '6',
      position: '60',
      label: 'I want to update my account information',
      help_message:
        "This can be done by clicking your name, then selecting Settings. From here, you can select the account information you'd like to update.",
      can_submit: '1',
    },
  ],
  '2': [
    {
      id: '48',
      position: '20',
      label: 'I have not received my mailed reward (magazine)',
      help_message: null,
      can_submit: '1',
    },
    {
      id: '49',
      position: '30',
      label: 'I cannot Redeem my rewards',
      help_message: null,
      can_submit: '1',
    },
    {
      id: '50',
      position: '40',
      label: 'Reward partner questions',
      help_message: null,
      can_submit: '1',
    },
  ],
  '3': [
    {
      id: '32',
      position: '10',
      label: 'Issue on the website',
      help_message:
        "We're sorry to hear this. Thanks for helping us correct the issue. Please describe the behavior you encountered in as much detail as you can, as well as the type of computer (PC or Mac), browser, and browser version.",
      can_submit: '1',
    },
  ],
  '4': [
    {
      id: '33',
      position: '10',
      label: "My issue isn't listed",
      help_message:
        "We'd like to improve your experience with Recyclebank, so please provide as much information below as possible.",
      can_submit: '1',
    },
    {
      id: '34',
      position: '20',
      label: "I'd like to leave some feedback",
      help_message:
        "We'd love to hear your feedback! Please inform us of what we're doing well or what you'd like to see improved so we can provide a better experience for you.",
      can_submit: '1',
    },
  ],
})

export const getSubTypesAtom = atom(get => {
  const selectedTypeId = get(selectedTypeAtom)
  const subTypes = get(subTypesAtom)

  if (selectedTypeId === '') {
    return [{ value: '', label: 'Select an option', helperText: null }]
  }

  for (const [key, value] of Object.entries(subTypes)) {
    if (key === selectedTypeId && value.length) {
      return [
        { value: '', label: 'Select an option', helperText: null },
        ...value.map(val => {
          return {
            value: val.id,
            label: val.label,
            helperText: val.help_message ? val.help_message : null,
          }
        }),
      ]
    }
  }

  return [{ value: '', label: 'Select an option', helperText: null }]
})

// Support API
export const createSupportTicket = async (params: {
  type: string
  subType: string
  comment: string
}): Promise<AxiosResponse<string, any>> => {
  try {
    return await AppService.Support.saveData('supportTicket', params)
  } catch (error) {
    return Promise.reject(error)
  }
}
