import { useQuery } from '@apollo/client'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, Box, Container, Tab } from '@mui/material'
import { styled } from '@mui/material/styles'
import Loading from 'components/molecules/Loading'
import ScoreBadge from 'components/molecules/ScoreBadge'
import RewardsCategoriesListWrapper from 'components/organisms/RewardsCategoriesListWrapper'
import RewardsListWrapper from 'components/organisms/RewardsListWrapper'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import * as _ from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { REWARD_CATEGORIES_LIST } from 'services/contentful/Rewards'
import { alertAtom } from 'stores'
import {
  categoriesAtom,
  filterOptionsAtom,
  getRewards,
  rewardTypeOptionsAtom,
  sortByRewardsAtom,
} from 'stores/rewards'
import { rewardsListAtom } from 'stores/rewards'
import { pointsAtom } from 'stores/user'
import { userAtom } from 'stores/user'
import {
  FilterOption,
  RewardsRequestFunctionObject,
  SortOption,
} from 'types/RewardTypes'
import { RewardCategoryItemType } from 'types/RewardTypes'

const tabs = ['All Rewards', 'Pay With Points']

interface StyledTabProps {
  label: string
  value: string
}

const RbTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(12),
  marginRight: theme.spacing(1),
  color: '#0C1C2A',
  '&.Mui-selected': {
    color: '#008F6B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}))

const Rewards = (): JSX.Element => {
  const [user] = useAtom(userAtom)
  const [pointBalance] = useAtom(pointsAtom)
  const [tab, setTab] = useState('1')
  const [sortBy, setSortBy] = useAtom(sortByRewardsAtom)
  const [searchRewards, setSearchRewards] = useState<string | undefined>(
    undefined
  )
  const [pointsFilterOptions, setPointsFilterOptions] =
    useAtom(filterOptionsAtom)
  const [typeOptions, setTypeOptions] = useAtom(rewardTypeOptionsAtom)
  const [rewardsCategoriesList, setRewardsCategoriesList] =
    useAtom(categoriesAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [sortByDirection, setSortByDirection] = useState<'asc' | 'desc'>('desc')
  const [pageSize] = useState(6)
  const [contentLoading, setContentLoading] = useState(false)
  const [rewardsList, setRewardsList] = useAtom(rewardsListAtom)
  const [, setAlert] = useAtom(alertAtom)

  useEffect(() => {
    const rewardsCategoriesListLocal = localStorage.getItem(
      'rewardsCategoriesList'
    )
    if (rewardsCategoriesListLocal !== null) {
      const rewardsCategoriesListLocalNotNull = JSON.parse(
        rewardsCategoriesListLocal
      )
      if (
        !_.isEqual(rewardsCategoriesListLocalNotNull, []) &&
        !_.isEqual(rewardsCategoriesListLocalNotNull, rewardsCategoriesList)
      ) {
        setRewardsCategoriesList(rewardsCategoriesListLocalNotNull)
      }
    }
    const sortByLocal = localStorage.getItem('sortBy')
    if (sortByLocal !== null) {
      const sortByLocalNotNull = JSON.parse(sortByLocal)
      if (!_.isEqual(sortByLocalNotNull, sortBy)) {
        setSortBy(sortByLocalNotNull)
      }
    }
    const sortByDirectionLocal = localStorage.getItem('sortByDirection')
    if (sortByDirectionLocal !== null) {
      const sortByDirectionLocalNotNull = JSON.parse(sortByDirectionLocal)
      if (sortByDirectionLocalNotNull !== sortByDirection) {
        setSortByDirection(sortByDirectionLocalNotNull)
      }
    }
    const pointsFilterOptionsLocal = localStorage.getItem('pointsFilterOptions')
    if (pointsFilterOptionsLocal !== null) {
      const pointsFilterOptionsLocalNotNull = JSON.parse(
        pointsFilterOptionsLocal
      )
      if (!_.isEqual(pointsFilterOptionsLocalNotNull, pointsFilterOptions)) {
        setPointsFilterOptions(pointsFilterOptionsLocalNotNull)
      }
    }
    const typeOptionsLocal = localStorage.getItem('typeOptions')
    if (typeOptionsLocal !== null) {
      const typeOptionsLocalNotNull = JSON.parse(typeOptionsLocal)
      if (!_.isEqual(typeOptionsLocalNotNull, typeOptions)) {
        setTypeOptions(typeOptionsLocalNotNull)
      }
    }
    const currentPageLocal = localStorage.getItem('currentPage')
    if (currentPageLocal !== null) {
      const currentPageLocalNotNull = JSON.parse(currentPageLocal)
      if (currentPageLocalNotNull !== currentPage) {
        setCurrentPage(currentPageLocalNotNull)
      }
    }
    const totalPageLocal = localStorage.getItem('totalPage')
    if (totalPageLocal !== null) {
      const totalPageLocalNotNull = JSON.parse(totalPageLocal)
      if (totalPageLocalNotNull !== totalPage) {
        setTotalPage(totalPageLocalNotNull)
      }
    }
  }, [])

  useEffect(() => {
    let changeMade = false
    const rewardsCategoriesListLocal = localStorage.getItem(
      'rewardsCategoriesList'
    )
    const currRewardsCategoriesList = JSON.stringify(rewardsCategoriesList)
    if (rewardsCategoriesListLocal !== currRewardsCategoriesList) {
      localStorage.setItem('rewardsCategoriesList', currRewardsCategoriesList)
      changeMade = true
    }
    const sortByLocal = localStorage.getItem('sortBy')
    const currSortBy = JSON.stringify(sortBy)
    if (sortByLocal !== currSortBy) {
      localStorage.setItem('sortBy', currSortBy)
      changeMade = true
    }
    const sortByDirectionLocal = localStorage.getItem('sortByDirection')
    const currSortByDirection = JSON.stringify(sortByDirection)
    if (sortByDirectionLocal !== currSortByDirection) {
      localStorage.setItem('sortByDirection', currSortByDirection)
      changeMade = true
    }
    const pointsFilterOptionsLocal = localStorage.getItem('pointsFilterOptions')
    const currPointsFilterOptions = JSON.stringify(pointsFilterOptions)
    if (pointsFilterOptionsLocal !== currPointsFilterOptions) {
      localStorage.setItem('pointsFilterOptions', currPointsFilterOptions)
      changeMade = true
    }
    const typeOptionsLocal = localStorage.getItem('typeOptions')
    const currTypeOptions = JSON.stringify(typeOptions)
    if (typeOptionsLocal !== currTypeOptions) {
      localStorage.setItem('typeOptions', currTypeOptions)
      changeMade = true
    }
    const currentPageLocal = localStorage.getItem('currentPage')
    const currCurrentPage = JSON.stringify(currentPage)
    if (currentPageLocal !== currCurrentPage) {
      localStorage.setItem('currentPage', currCurrentPage)
      changeMade = true
    }
    const totalPageLocal = localStorage.getItem('totalPage')
    const currTotalPage = JSON.stringify(totalPage)
    if (totalPageLocal !== currTotalPage) {
      localStorage.setItem('totalPage', currTotalPage)
      changeMade = true
    }
    if (changeMade) {
      getRewardsCollection({
        page: currentPage,
        pointsZero: isPointOptionChecked('pointsZero'),
        pointsFiftyOne: isPointOptionChecked('pointsFiftyOne'),
        pointsOneHundredOne: isPointOptionChecked('pointsOneHundredOne'),
        pointsOneFiftyOne: isPointOptionChecked('pointsOneFiftyOne'),
        pointsTwoHundredOne: isPointOptionChecked('pointsTwoHundredOne'),
        sortByColumn: sortBy.value,
        sortDirection: sortByDirection,
        categoryIds: getSelectedCatgories(),
        search: searchRewards,
        local: isTypeChecked('local'),
        national: isTypeChecked('national'),
      })
    }
  }, [
    rewardsCategoriesList,
    sortBy,
    sortByDirection,
    pointsFilterOptions,
    typeOptions,
    currentPage,
    totalPage,
  ])

  const isPointOptionChecked = (optionValue: string): 0 | 1 => {
    const foundOption = pointsFilterOptions.find(
      option => option.value === optionValue
    )

    return foundOption && foundOption.checked ? 1 : 0
  }

  const getSelectedCatgories = (
    categoriesList = rewardsCategoriesList
  ): string => {
    const rewardsCategoriesSelected =
      categoriesList.filter(category => category.checked) || []
    if (rewardsCategoriesSelected.length) {
      return rewardsCategoriesSelected
        .map(category => category.sys.id)
        .toString()
    }
    return ''
  }

  const isTypeChecked = (
    value = '' || 'local' || 'national',
    options: FilterOption[] = typeOptions
  ): 0 | 1 => {
    const selectedOption = options.find(type => type.checked)
    if (selectedOption) {
      if (selectedOption.value === 'all') {
        return 1
      } else if (selectedOption.value === 'local' && value === 'local') {
        return 1
      } else if (selectedOption.value === 'national' && value === 'national') {
        return 1
      } else {
        return 0
      }
    }
    return 0
  }

  const getRewardsCollection = ({
    page = currentPage,
    pointsZero = isPointOptionChecked('pointsZero'),
    pointsFiftyOne = isPointOptionChecked('pointsFiftyOne'),
    pointsOneHundredOne = isPointOptionChecked('pointsOneHundredOne'),
    pointsOneFiftyOne = isPointOptionChecked('pointsOneFiftyOne'),
    pointsTwoHundredOne = isPointOptionChecked('pointsTwoHundredOne'),
    sortByColumn = sortBy.value,
    sortDirection = sortByDirection,
    categoryIds = getSelectedCatgories(),
    search = searchRewards,
    local = isTypeChecked('local'),
    national = isTypeChecked('national'),
  }: RewardsRequestFunctionObject): void => {
    if (user && user.userId) {
      setContentLoading(true)
      const offset = (page - 1) * pageSize

      getRewards({
        userId: user.userId,
        pointsZero: pointsZero,
        pointsFiftyOne: pointsFiftyOne,
        pointsOneHundredOne: pointsOneHundredOne,
        pointsOneFiftyOne: pointsOneFiftyOne,
        pointsTwoHundredOne: pointsTwoHundredOne,
        sortBy: sortByColumn,
        sortDirection: sortDirection,
        limit: 6,
        offset: offset,
        categoryIds: categoryIds.length ? categoryIds : undefined,
        search: search && search.length ? search : undefined,
        local,
        national,
      })
        .then(response => {
          // TODO: change API response to have totals moved away and above rewards list.
          if (response.data && response.data.length) {
            const total = response.data[0].total
            const totalPages = total ? Math.ceil(total / pageSize) : 0
            setTotalPage(totalPages)
          }

          setRewardsList(response.data)
          setContentLoading(false)
        })
        .catch(() => {
          setContentLoading(false)
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to get rewards. Try again.',
            autoHideDuration: 2000,
          })
        })
    }
  }

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setTab(newValue)
  }

  const handleSortByChange = (option: SortOption): void => {
    setSortBy(option)
    setCurrentPage(1)
  }

  const handleRewardsFilterSearch = (searchString: string): void => {
    const searchTxt = searchString && searchString.length ? searchString : ''
    setSearchRewards(searchTxt)
    setCurrentPage(1)
  }

  const handlePointsFilterChange = (
    pointsFilterOptions: FilterOption[]
  ): void => {
    setPointsFilterOptions(pointsFilterOptions)
    setCurrentPage(1)
  }

  const handleCategoryFilterSelect = (option: RewardCategoryItemType): void => {
    const updatedRewardsCategoriesList = rewardsCategoriesList.map(category =>
      category.sys.id === option.sys.id ? option : category
    )
    setRewardsCategoriesList(updatedRewardsCategoriesList)
    setCurrentPage(1)
  }

  const handleTypeSelect = (option: FilterOption): void => {
    const updatedTypeOptions = typeOptions.map(type =>
      type.value === option.value ? option : { ...type, checked: false }
    )
    setTypeOptions(updatedTypeOptions)
    setCurrentPage(1)
  }

  const handleSortByDirectionChange = (direction: 'asc' | 'desc'): void => {
    setSortByDirection(direction)
    setCurrentPage(1)
  }

  const handleShopNowClick = (
    e: React.MouseEvent,
    selectedCategory: RewardCategoryItemType
  ): void => {
    const updatedCategoriesList = rewardsCategoriesList.map(category =>
      category.sys.id === selectedCategory.sys.id ? selectedCategory : category
    )

    setRewardsCategoriesList(updatedCategoriesList)
    handleTabChange(e, '1')
  }

  const handlePaginationChange = (
    event: ChangeEvent<unknown>,
    value: number
  ): void => {
    setCurrentPage(value)
  }

  const { data, loading, error } = useQuery(REWARD_CATEGORIES_LIST)

  useEffect(() => {
    if (!loading && data?.rewardCategoriesCollection?.items?.length) {
      const categoryItems = data.rewardCategoriesCollection.items.map(
        (item: RewardCategoryItemType) => {
          return { ...item, ...{ checked: false, value: item.sys.id } }
        }
      )
      if (
        _.isEqual(rewardsCategoriesList, []) ||
        rewardsCategoriesList === null ||
        rewardsCategoriesList === undefined
      ) {
        setRewardsCategoriesList(categoryItems)
      } else {
        for (let i = 0; i < rewardsCategoriesList.length; i++) {
          let found = false
          for (let j = 0; j < categoryItems.length; j++) {
            if (categoryItems[j].sys.id == rewardsCategoriesList[i].sys.id) {
              found = true
            }
          }
          if (!found) {
            rewardsCategoriesList.splice(i, 1)
          }
        }
      }
    }
  }, [loading, data])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return (
    <AuthenticatedPageWrapper>
      <TabContext value={tab}>
        <Container
          className="rb-tabs-wrapper"
          maxWidth={false}
          component="div"
          sx={{
            backgroundColor: '#F5F7FA',
            height: '3rem',
            borderBottom: 1,
            borderColor: '#D2D9E3',
            p: 0,
            mt: { xs: '9rem', md: 0 },
          }}
        >
          <Container
            maxWidth="lg"
            className="rb-tabs-container"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
              px: { sm: '0!important', md: '1.5rem' },
            }}
          >
            <TabList
              onChange={handleTabChange}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              {tabs.map((tab, i) => (
                <RbTab label={tab} key={i} value={`${i}`} />
              ))}
            </TabList>
            <Box
              sx={{
                mt: 1,
                position: { xs: 'absolute', md: 'unset' },
                top: { xs: '3.5rem', sm: '4rem' },
                left: '0.5rem',
              }}
            >
              <ScoreBadge points={pointBalance} />
            </Box>
          </Container>
        </Container>
        <Container
          maxWidth="lg"
          className="rb-rewards-categories-container"
          sx={{ minHeight: '66vh', p: '0!important' }}
        >
          <TabPanel value="0" sx={{ p: '0!important' }}>
            <RewardsCategoriesListWrapper
              categoriesList={rewardsCategoriesList}
              onShopNowClick={handleShopNowClick}
            />
          </TabPanel>
          <TabPanel value="1" sx={{ p: '0!important' }}>
            <RewardsListWrapper
              sortBy={sortBy}
              rewardsList={rewardsList}
              typeOptions={typeOptions}
              categoriesList={rewardsCategoriesList}
              rewardsListLoading={contentLoading}
              currentPage={currentPage}
              totalPage={totalPage}
              sortByDirection={sortByDirection}
              onSortByChange={handleSortByChange}
              onRewardsFilterSearch={handleRewardsFilterSearch}
              onPointsFilterChange={handlePointsFilterChange}
              onCategoryFilterSelect={handleCategoryFilterSelect}
              onTypeSelect={handleTypeSelect}
              onSortByDirectionChange={handleSortByDirectionChange}
              onPaginationChange={handlePaginationChange}
            />
          </TabPanel>
        </Container>
      </TabContext>
    </AuthenticatedPageWrapper>
  )
}

export default Rewards
