import GA4React from 'ga-4-react'
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels'

export const affiliation = 'Recycle Bank 2.0'

export declare type gtagAction = 'page_view' | string
export declare type gtagCategory = 'content_views' | 'engagement' | string
export declare type gtagLabel =
  | 'method'
  | 'search_term'
  | 'content_type'
  | string

// --------------------------------------------------------------------------
// GA4 Recommended Events https://support.google.com/analytics/answer/9267735
// --------------------------------------------------------------------------
export const isGAInit = (): GA4ReactResolveInterface | undefined => {
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React()
    if (ga4) return ga4
    else {
      // eslint-disable-next-line no-console
      console.error(new Error('Unable to get GA.'))
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(new Error('Analytics is not initialized'))
  }
}

export const pageView = (pathname: string): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.pageview(pathname)
  }
}

export const signUpEvent = (
  stateProvince: string,
  postalCode: string,
  country: string,
  clientId: string,
  serviceAreaId: string,
  signUpMethod: string
): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.gtag('event', 'sign_up', {
      stateProvince: stateProvince,
      postalCode: postalCode,
      country: country,
      clientId: clientId,
      serviceAreaId: serviceAreaId,
      method: signUpMethod,
    })
  }
}

export const loginEvent = (signUpMethod: string): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.gtag('event', 'login', {
      method: signUpMethod,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/ga4/exceptions
export const exceptionEvent = (error: any): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.gtag('event', 'exception', {
      description: error,
      fatal: false, // set to true if the error is fatal
    })
  }
}

export const earnPointsEvent = (
  baseContentId: string,
  userPostalCode: string,
  contentId: string,
  type: string,
  points: number,
  title: string
): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.gtag('event', 'earn_points', {
      virtual_currency_name: 'Points',
      value: points,
      items: [
        {
          item_parent_id: baseContentId,
          item_id: contentId,
          item_name: title,
          affiliation: affiliation,
          item_category: 'content',
          location_id: userPostalCode,
          points: points,
        },
      ],
    })
  }
}

export const contentViewEvent = (
  baseContentId: string,
  userPostalCode: string,
  contentId: string,
  type: string,
  points: number,
  title: string
): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.gtag('event', 'content_view', {
      type,
      points,
      items: [
        {
          item_parent_id: baseContentId,
          item_id: contentId,
          item_name: title,
          affiliation: affiliation,
          item_category: 'content',
          location_id: userPostalCode,
          points: points,
        },
      ],
    })
  }
}

export const purchaseRewardEvent = (
  postalCode: string,
  rewardId: string,
  rewardTitle: string,
  spentPoints: number,
  rewardPartnerId: string
): void => {
  //TODO:Need fullfilmentId after purchase ? // fullfilmentId // item_category (reward category)
  const ga4 = isGAInit()
  if (ga4) {
    ga4.gtag('event', 'purchase', {
      transaction_id: 'unknown',
      affiliation: affiliation,
      value: spentPoints,
      items: [
        {
          item_id: rewardId,
          item_name: rewardTitle,
          affiliation: affiliation,
          item_category: 'unknown',
          item_list_id: rewardPartnerId,
          location_id: postalCode,
        },
      ],
    })
  }
}

export const addCustomEvent = (
  action: gtagAction,
  label: gtagLabel,
  data: gtagCategory,
  nonInteraction?: boolean | undefined
): void => {
  const ga4 = isGAInit()
  if (ga4) {
    ga4.event(action, label, data, nonInteraction)
  }
}
