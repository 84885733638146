import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AxiosResponse } from 'axios'
import Tab from 'components/atoms/Tab'
import AllActivityWrapper from 'components/molecules/AllActivityWrapper'
import EditProfileWrapper from 'components/molecules/EditProfileWrapper'
import ScoreBadge from 'components/molecules/ScoreBadge'
import ViewProfileWrapper from 'components/molecules/ViewProfileWrapper'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { isValid } from 'date-fns'
import { format } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import firebase from 'services/firebase'
import { alertAtom } from 'stores'
import { activityAtom, getActivity, recentActivityAtom } from 'stores/activity'
import { printReward } from 'stores/rewards'
import { getUser, pointsAtom, updateUser, userAtom } from 'stores/user'
import { PasswordResetFields, UpdateUser, User } from 'types/AuthTypes'
import { exceptionEvent } from 'utils/analytics'

const tabs = ['Profile', 'All Activity', 'Settings']

const Settings = (): JSX.Element => {
  const [user, setUserDetails] = useAtom(userAtom)
  const [pointBalance] = useAtom(pointsAtom)
  const [tab, setTab] = useState('0')
  const [activityList, setActivityList] = useAtom(activityAtom)
  const [filterDateRange, setFilterDateRange] = useState<{
    startDate: string | undefined
    endDate: string | undefined
  }>({
    startDate: undefined,
    endDate: undefined,
  })

  const [type, setType] = useState<'rewards' | 'points' | undefined>(undefined)
  const [recentActivityList, setRecentActivityList] =
    useAtom(recentActivityAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [, setAlert] = useAtom(alertAtom)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [open, setOpen] = useState(false)
  const [rewardURL, setRewardURL] = useState<string | null>(null)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const rewardIFrame = useRef<HTMLIFrameElement>(null)

  const handleRewardDialogClose = (): void => {
    setOpen(false)
    setRewardURL(null)
  }

  const handleRewardPrint = (): void => {
    const { current } = rewardIFrame
    if (current) {
      const doc = current.contentWindow
      if (doc) {
        doc.focus()
        doc.print()
      }
    }
  }

  const setPageAlert = (success = true): void => {
    setAlert({
      show: true,
      type: success ? 'success' : 'error',
      message: success
        ? 'Profile successfully updated.'
        : 'Failed to update profile. Try again.',
      autoHideDuration: 2000,
    })
  }

  const handleUserProfileUpdate = async (data: UpdateUser): Promise<void> => {
    if (user) {
      await updateUser(user.authProviderId, {
        loginEmail: data.loginEmail,
        firstName: data.firstName,
        lastName: data.lastName,
        houseNumber: data.houseNumber,
        street: data.street,
        city: data.city,
        stateProvince: data.stateProvince,
        country: data.country,
      })
        .then(() => {
          getUser(user.authProviderId)
            .then((response: AxiosResponse<User>) => {
              // Update user
              setUserDetails(response.data)
            })
            .catch(() => {
              setPageAlert(false)
            })
          setPageAlert()
        })
        .catch(() => setPageAlert(false))
    }
  }

  const handleUserPasswordUpdate = (data: PasswordResetFields): void => {
    firebase
      .updateUserPassword(data.currentPassword, data.confirmPassword)
      .then(() => setPageAlert())
      .catch(error => {
        exceptionEvent(error)
        setPageAlert(false)
      })
  }

  const handleTabChange = (
    event: React.SyntheticEvent | null,
    newValue: string
  ): void => {
    setTab(newValue)

    if (newValue === '1' && user?.userId) {
      getUserActivity({ page: page, rowsPerPage: rowsPerPage })
    }

    if (newValue === '0' && user?.userId) {
      getRecentUserActivity()
    }
  }

  const handleViewAllActivity = (): void => {
    // Switch tab to all activity Tab
    handleTabChange(null, '1')
  }

  const handleAccountSettings = (): void => {
    // Switch tab to settings Tab
    setTab('2')
  }

  useEffect(() => {
    // Intially get first page of activity
    if (user?.userId) {
      getRecentUserActivity()
    }
  }, [user])

  const handleFilterChange = (range: any): void => {
    if (isValid(range[0]) && isValid(range[1])) {
      const startDate = format(range[0], 'yyyy-MM-dd')
      const endDate = format(range[1], 'yyyy-MM-dd')
      setFilterDateRange({ startDate: startDate, endDate: endDate })
      setPage(0)
      getUserActivity({
        page: 0,
        rowsPerPage: rowsPerPage,
        activityType: type,
        startDate: startDate,
        endDate: endDate,
      })
    }
  }

  const handleTypeChange = (type: 'rewards' | 'points' | undefined): void => {
    const activityType =
      type === 'rewards' || type === 'points' ? type : undefined

    setType(activityType)
    getUserActivity({
      page: 0,
      rowsPerPage: rowsPerPage,
      activityType: activityType,
      startDate: filterDateRange.startDate,
      endDate: filterDateRange.endDate,
    })
  }

  const getRecentUserActivity = (): void => {
    if (user?.userId) {
      setLoading(true)
      getActivity(user.userId, undefined, undefined, undefined, 1, 6, true)
        .then(response => {
          setRecentActivityList(response.data.pointHistoryResponses)
          setLoading(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to get recent user activity. Try again.',
            autoHideDuration: 2000,
          })
          setLoading(false)
        })
    }
  }

  const getUserActivity = ({
    page: page,
    rowsPerPage: rowsPerPage,
    startDate = undefined,
    endDate = undefined,
    activityType = undefined,
  }: {
    page: number
    rowsPerPage: number
    startDate?: string | undefined
    endDate?: string | undefined
    activityType?: 'rewards' | 'points' | undefined
  }): void => {
    if (user?.userId) {
      setLoading(true)
      getActivity(
        user.userId,
        startDate,
        endDate,
        activityType,
        page + 1,
        rowsPerPage,
        true
      )
        .then(response => {
          // On transaction success update points
          setCount(response.data.noOfRows)
          setActivityList(response.data.pointHistoryResponses)
          setLoading(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to get user activity. Try again.',
            autoHideDuration: 2000,
          })
          setLoading(false)
        })
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    // page is a zero based index
    setPage(page)
    getUserActivity({ page: page, rowsPerPage: rowsPerPage })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    getUserActivity({ page: 0, rowsPerPage: rowsPerPage })
  }

  const address = (): string => {
    let address = ''
    if (user.houseNumber) {
      address += user.houseNumber
    }
    if (user.street) {
      address += ' ' + user.street
    }
    if (user.city) {
      address += ', ' + user.city
    }
    if (user.stateProvince) {
      address += ', ' + user.stateProvince
    }
    if (user.postalCode) {
      address += ' ' + user.postalCode
    }
    return address
  }

  const handlePrintRewards = (rewardFulFillmentId: string): void => {
    printReward(rewardFulFillmentId)
      .then(response => {
        const rewardURL = URL.createObjectURL(response.data)
        setRewardURL(rewardURL)
        setOpen(true)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to get reward. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  return (
    <AuthenticatedPageWrapper
      sx={{
        backgroundColor: '#F5F7FA',
      }}
    >
      <TabContext value={tab}>
        <Container
          className="rb-tabs-container"
          maxWidth={false}
          component="div"
          sx={{
            backgroundColor: '#F5F7FA',
            height: '3rem',
            borderBottom: 1,
            borderColor: '#D2D9E3',
            p: 0,
            mt: { xs: '9rem', md: 0 },
          }}
        >
          <Container
            maxWidth="lg"
            className="rb-settings-container"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
              px: { sm: '0!important', md: '1.5rem' },
            }}
          >
            <TabList
              onChange={handleTabChange}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              {tabs.map((tab, i) => (
                <Tab label={tab} key={i} value={`${i}`} />
              ))}
            </TabList>
            <Box
              sx={{
                mt: 1,
                position: { xs: 'absolute', md: 'unset' },
                top: { xs: '3.5rem', sm: '4rem' },
                left: '0.5rem',
              }}
            >
              <ScoreBadge points={pointBalance} />
            </Box>
          </Container>
        </Container>
        <Container
          maxWidth="lg"
          className="rb-settings-panel-container"
          sx={{
            minHeight: { xs: '66vh', md: 'calc(66vh - 4.65rem)' },
            backgroundColor: '#F5F7FA',
            px: { xs: 2 },
            mt: { xs: 0, md: '7rem' },
          }}
        >
          <TabPanel value="0" sx={{ p: 0 }}>
            <ViewProfileWrapper
              photoURL={user && user.photoURL ? user.photoURL : ''}
              address={address()}
              displayName={user ? `${user.firstName} ${user.lastName}` : ''}
              email={user ? String(user.loginEmail) : ''}
              activityList={recentActivityList}
              activityListLoading={loading}
              onViewAllActivity={handleViewAllActivity}
              onAccountSettings={handleAccountSettings}
              onPrintRewards={handlePrintRewards}
            />
          </TabPanel>
          <TabPanel value="1" sx={{ p: 0 }}>
            <AllActivityWrapper
              activityList={activityList}
              loading={loading}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onFilterChange={handleFilterChange}
              onTypeChange={handleTypeChange}
              onAccountSettings={handleAccountSettings}
              onPrintRewards={handlePrintRewards}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0 }}>
            {user ? (
              <EditProfileWrapper
                photoURL={user && user.photoURL ? user.photoURL : ''}
                firstName={user.firstName}
                lastName={user.lastName}
                houseNumber={user.houseNumber}
                street={user.street}
                city={user.city}
                stateProvince={user.stateProvince}
                postalCode={user.postalCode}
                country={user.country}
                loginEmail={user ? String(user.loginEmail) : ''}
                onProfileUpdate={handleUserProfileUpdate}
                onPasswordUpdate={handleUserPasswordUpdate}
              />
            ) : null}
          </TabPanel>
        </Container>
      </TabContext>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleRewardDialogClose}
        aria-labelledby="reward-pdf-dialog"
      >
        <DialogContent sx={{ pb: 0 }}>
          {rewardURL ? (
            <iframe
              ref={rewardIFrame}
              src={rewardURL}
              frameBorder="0"
              scrolling="no"
              width="500px"
              height="222px"
            ></iframe>
          ) : (
            ''
          )}
        </DialogContent>
        {rewardURL ? (
          <DialogActions disableSpacing>
            <Link
              href={rewardURL}
              underline="none"
              download="proposed_file_name"
              target="_blank"
              rel="noreferrer"
              variant="button"
              sx={{ padding: '6px 8px' }}
            >
              Download
            </Link>
            <Button onClick={handleRewardPrint}>Print</Button>
            <Button onClick={handleRewardDialogClose}>Close</Button>
          </DialogActions>
        ) : (
          ''
        )}
      </Dialog>
    </AuthenticatedPageWrapper>
  )
}

export default Settings
