import DateRangeIcon from '@mui/icons-material/DateRange'
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/datepicker.scss'

interface Props {
  onFilterChange: (range: [Date | null, Date | null]) => void
  onTypeChange: (type: 'rewards' | 'points' | undefined) => void
}

type CustomInputProps = {
  onClick?: () => void
  value?: string
}

type RefType = any

const ActivityFilter = ({
  onFilterChange,
  onTypeChange,
}: Props): JSX.Element => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [type, seType] = useState(' ')
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleFilterChange = (dates: [Date | null, Date | null]): void => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    onFilterChange(dates)
  }

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const value =
      event.target.value === 'rewards' || event.target.value === 'points'
        ? event.target.value
        : undefined
    seType(event.target.value)
    onTypeChange(value)
  }

  const DatePickerInput = forwardRef<RefType, CustomInputProps>(
    ({ value, onClick }, ref) => (
      <FormControl size="small" fullWidth sx={{ backgroundColor: '#fff' }}>
        <OutlinedInput
          placeholder="MM/DD/YYYY - MM/DD/YYYY"
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="Toggle Datepicker" edge="end">
                {<DateRangeIcon sx={{ fontSize: '0.85rem' }} color="primary" />}
              </IconButton>
            </InputAdornment>
          }
          onClick={onClick}
          ref={ref}
          sx={{ fontSize: '0.85rem' }}
        />
      </FormControl>
    )
  )
  DatePickerInput.displayName = 'DatePickerInput'

  return (
    <Box component="div" sx={{ mb: 2 }}>
      <Typography
        variant="body2"
        sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 2 }}
      >
        Filter by date
      </Typography>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        onChange={handleFilterChange}
        customInput={<DatePickerInput />}
        showPopperArrow={false}
        dateFormat="MM/dd/yyyy"
        selectsRange
      />
      <Select
        value={type}
        onChange={handleTypeChange}
        size="small"
        fullWidth
        inputProps={{ 'aria-label': 'Type' }}
        sx={{ mt: 2, backgroundColor: 'white' }}
        defaultValue=" "
      >
        <MenuItem value=" ">All</MenuItem>
        <MenuItem value="points">Points</MenuItem>
        <MenuItem value="rewards">Rewards</MenuItem>
      </Select>
    </Box>
  )
}

export default ActivityFilter
