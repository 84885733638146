import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Button, Stack, Typography } from '@mui/material'
import Tag from 'components/atoms/Tag'
import { BackSide, FlipCard, FrontSide } from 'components/molecules/FlipCard'
import { RichText } from 'components/organisms/ContentFulRenderer'
import { ContentWrapperType } from 'components/organisms/ContentWrapper'
import React, { Key, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FlipCardType,
  FormatedFlipCardType,
} from 'types/BaseContentDetailTypes'
import { dateFormatNoYear } from 'utils/date'

interface FlipCardProps
  extends Omit<ContentWrapperType, 'onBaseContentIdUpdate'> {
  baseContentId: string
  id: string
  type: string
  data: FlipCardType
}

const CenterButton = ({
  isQuestion = true,
}: {
  isQuestion?: boolean
}): JSX.Element => {
  return (
    <Button
      variant="outlined"
      size="large"
      endIcon={
        <FontAwesomeIcon
          icon={faArrowsRotate}
          color="white"
          size="sm"
          style={{ fontSize: '0.9rem' }}
        />
      }
      sx={{
        width: '13.75rem',
        alignSelf: 'center',
        position: 'absolute',
        top: 'calc(50% - 27px)', // 55px button and half of it
        border: '1px solid rgb(255 255 255 / 50%)',
        backgroundColor: 'rgb(0 0 0 / 50%)',
        color: 'white',
        fontSize: '0.75rem',
        px: 1,
        py: 2,
        '&:hover': {
          border: '1px solid rgb(255 255 255 / 70%)',
          backgroundColor: 'rgb(0 0 0 / 50%)',
        },
      }}
    >
      CLICK FOR {isQuestion ? 'ANSWER' : 'QUESTION'}
    </Button>
  )
}

const getBackContentAlignment = (alignment: string | null): unknown => {
  switch (alignment) {
    case 'Top':
      return {
        justifyContent: 'start',
      }
      break
    case 'Left':
      return {
        alignItems: 'start',
        justifyContent: 'center',
        '& > div': {
          ml: 4,
          width: '50%',
        },
      }
      break
    case 'Right':
      return {
        alignItems: 'end',
        justifyContent: 'center',
        '& > div': {
          mr: 4,
          width: '50%',
        },
      }
      break
    case 'Center':
      return {
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
          width: '60%',
        },
      }
      break
    case 'Bottom':
    default:
      return {
        justifyContent: 'end',
      }
      break
  }
}

const FlipCardWrapper = ({
  type,
  id,
  baseContentId,
  data,
  disabledEarnPoint,
  onContentView,
  onEarnPointsClick,
}: FlipCardProps): JSX.Element => {
  const [isFlipped, setFlipped] = useState(false)
  const [firstFlip, setfirstFlip] = useState(true)
  const navigate = useNavigate()

  const formatedFlipcardData: FormatedFlipCardType = Object.assign(
    {},
    {
      ...{ contentId: data.sys.id },
      ...data.linkedFrom.baseContentCollection.items[0],
      ...{
        front: {
          image: data.frontImage,
          backgroundColor: data.frontBackgroundColor || 'rgb(0 0 0 / 70%)',
          text: data.frontMessage.json,
          color: data.frontMessageColor,
        },
        back: {
          image: data.backImage,
          backgroundColor: data.backBackgroundColor || 'rgb(0 0 0 / 70%)',
          text: data.backMessage.json,
          color: data.backMessageColor,
          pointsButtonColor: data.pointsButtonColor || '#008f6b',
          textAlignment: getBackContentAlignment(data.backMessageAlignment),
        },
        bodyText: data?.bodyText?.json || '',
      },
    }
  )

  const handleEarnPointsClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    onEarnPointsClick(
      baseContentId,
      id,
      type,
      formatedFlipcardData.points,
      formatedFlipcardData.title
    )
  }

  const handleFlipCardClick = (
    e: React.MouseEvent,
    isFlipped: boolean
  ): void => {
    if (e.type === 'click') {
      setFlipped(isFlipped)

      if (firstFlip) {
        // Track actual content readers once card is flipped
        onContentView(
          baseContentId,
          id,
          type,
          formatedFlipcardData.points,
          formatedFlipcardData.title
        )
      }
      setfirstFlip(false)
    }
  }

  return (
    <Box
      component="section"
      id={`${type}_${id}`}
      className="rb_flipcard_container"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mt: { xs: '2.625rem', md: 0 },
        }}
      >
        <Button
          size="small"
          sx={{ fontSize: '0.7rem' }}
          startIcon={
            <ArrowBackIosIcon sx={{ fontSize: '0.75rem !important' }} />
          }
          onClick={(): void => navigate(-1)}
        >
          Back
        </Button>
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          {formatedFlipcardData.contentfulMetadata
            ? formatedFlipcardData.contentfulMetadata.tags.map(
                (tag: { id: Key | null | undefined; name: string }) => (
                  <Tag key={tag.id} label={tag.name} />
                )
              )
            : null}
        </Stack>
      </Box>
      <Typography
        variant="h4"
        fontWeight="600"
        gutterBottom
        component="div"
        sx={{
          textTransform: 'uppercase',
          my: 1,
          fontWeight: 700,
        }}
      >
        {formatedFlipcardData.title}
      </Typography>
      <Typography variant="xsmall" component="small">
        By Recyclebank |{' '}
        {dateFormatNoYear(
          formatedFlipcardData.publishDay,
          formatedFlipcardData.publishMonth,
          formatedFlipcardData.publishYear
        )}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          mb: 3,
          fontSize: '0.75rem',
        }}
      >
        {formatedFlipcardData.abstract}
      </Typography>
      <Box className="rb_flipCard_content">
        <FlipCard
          sx={{ height: formatedFlipcardData.front.image.height }}
          onClick={handleFlipCardClick}
        >
          <FrontSide
            sx={{
              backgroundImage:
                'url(' + formatedFlipcardData.front.image.url + ')',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
            }}
          >
            <CenterButton isQuestion />
            <Box
              sx={{
                width: '100%',
                p: 1,
                backgroundColor: formatedFlipcardData.front.backgroundColor,
                color: formatedFlipcardData.front.color,
                '& > p': {
                  textAlign: 'center',
                },
              }}
            >
              <RichText json={formatedFlipcardData.front.text} />
            </Box>
          </FrontSide>
          <BackSide
            sx={{
              ...{
                backgroundImage:
                  'url(' + formatedFlipcardData.back.image.url + ')',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                flexDirection: 'column',
              },
              ...formatedFlipcardData.back.textAlignment,
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: formatedFlipcardData.back.backgroundColor,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  color: formatedFlipcardData.back.color,
                }}
              >
                <RichText json={formatedFlipcardData.back.text} />
              </Box>
              {!disabledEarnPoint ? (
                <Button
                  variant="contained"
                  disableElevation={true}
                  size="small"
                  sx={{
                    backgroundColor:
                      formatedFlipcardData.back.pointsButtonColor,
                    color: 'white',
                    mt: 1,
                    mb: 2,
                    '&:hover': {
                      backgroundColor:
                        formatedFlipcardData.back.pointsButtonColor,
                    },
                  }}
                  disabled={disabledEarnPoint}
                  onClick={(e: React.MouseEvent): void =>
                    handleEarnPointsClick(e)
                  }
                >
                  {`Earn ${formatedFlipcardData.points} points`}
                </Button>
              ) : null}
            </Box>
          </BackSide>
        </FlipCard>
        {isFlipped ? (
          <Box
            sx={{
              mt: 2,
              backgroundColor: '#F5F7FA',
              p: 1,
            }}
          >
            <RichText json={formatedFlipcardData.bodyText} />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default FlipCardWrapper
