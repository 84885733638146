import { AxiosResponse } from 'axios'
import { RewardsRequest, RewardsResponse } from 'types/RewardTypes'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  rewards(
    params: RewardsRequest
  ): Promise<AxiosResponse<RewardsResponse, any>> {
    return axiosInstance.get(`/rewards`, {
      params: params,
    })
  },

  rewardsByPostalCode(
    postalCode: string
  ): Promise<AxiosResponse<RewardsResponse[], any>> {
    return axiosInstance.get(`/rewardsByPostalCode/${postalCode}`)
  },

  printReward(rewardFulFillmentId: string): Promise<AxiosResponse<Blob, any>> {
    return axiosInstance.get(`/redeem/${rewardFulFillmentId}`, {
      responseType: 'blob',
    })
  },
}

const saves = {}

const deletes = {}

export default class RewardsService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
