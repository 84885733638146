import { Button, Card, CardContent, Typography } from '@mui/material'

interface Props {
  onAccountSettings: () => void
}

const SettingsCard = ({ onAccountSettings }: Props): JSX.Element => {
  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: '#EBEDEF',
        borderRadius: '.25rem',
        py: 3,
        px: 2,
        border: 'none',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          gutterBottom
          sx={{ fontWeight: 600, textTransform: 'uppercase' }}
        >
          Account Settings
        </Typography>
        <Typography variant="small" sx={{ textAlign: 'center' }}>
          Head over to the Account Settings section to view and edit your
          account information as well as the types of emails and notifications
          you receive.
        </Typography>
        <Button
          variant="contained"
          disableElevation
          sx={{ mt: 2 }}
          onClick={onAccountSettings}
        >
          Go to Account Settings
        </Button>
      </CardContent>
    </Card>
  )
}

export default SettingsCard
