import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Button, Stack, Typography } from '@mui/material'
import Switch from 'components/atoms/Switch'
import Tag from 'components/atoms/Tag'
import VideoPlayer from 'components/molecules/VideoPlayer'
import { ContentWrapperType } from 'components/organisms/ContentWrapper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormatedVideoType, VideoType } from 'types/BaseContentDetailTypes'
import { dateFormatNoYear } from 'utils/date'

interface VideoProps extends Omit<ContentWrapperType, 'onBaseContentIdUpdate'> {
  baseContentId: string
  id: string
  type: string
  data: VideoType
}

const VideoWrapper = ({
  type,
  id,
  baseContentId,
  data,
  disabledEarnPoint,
  onContentView,
  onEarnPointsClick,
}: VideoProps): JSX.Element => {
  const [earnPointsDisabled, setEarnPointsDisabled] =
    useState(disabledEarnPoint)
  const navigate = useNavigate()

  const formatedVideo: FormatedVideoType = Object.assign(
    {},
    {
      ...{ contentId: data.sys.id },
      ...data.linkedFrom.baseContentCollection.items[0],
      ...{ videoId: data.youtubeVideoId },
    }
  )

  useEffect(() => {
    setEarnPointsDisabled(disabledEarnPoint)
  }, [disabledEarnPoint])

  const handleVideoEnd = (): void => {
    // Track actual content readers on Video end
    onContentView(
      baseContentId,
      id,
      type,
      formatedVideo.points,
      formatedVideo.title
    )
  }

  const handleEarnPoints = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    if (e.target.checked && !disabledEarnPoint) {
      setEarnPointsDisabled(true)
      onEarnPointsClick(
        baseContentId,
        id,
        type,
        formatedVideo.points,
        formatedVideo.title
      )
    }
  }

  const earnPointsText = `Earn ${formatedVideo.points} points`
  return (
    <Box
      component="section"
      id={`${type}_${id}`}
      className="rb_video_container"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button
          size="small"
          sx={{ fontSize: '0.7rem' }}
          startIcon={
            <ArrowBackIosIcon sx={{ fontSize: '0.75rem !important' }} />
          }
          onClick={(): void => navigate(-1)}
        >
          Back
        </Button>
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          {formatedVideo.contentfulMetadata
            ? formatedVideo.contentfulMetadata.tags.map(tag => (
                <Tag key={tag.id} label={tag.name} />
              ))
            : null}
        </Stack>
        <Switch
          label={earnPointsText}
          labelWidth={50}
          color="warning"
          checked={disabledEarnPoint}
          onChange={handleEarnPoints}
          disabled={earnPointsDisabled}
        />
      </Box>
      <Typography
        variant="h4"
        fontWeight="600"
        gutterBottom
        sx={{
          textTransform: 'uppercase',
          my: 1,
          fontWeight: 700,
        }}
      >
        {formatedVideo.title}
      </Typography>
      <Typography variant="xsmall" component="small">
        By Recyclebank |{' '}
        {dateFormatNoYear(
          formatedVideo.publishDay,
          formatedVideo.publishMonth,
          formatedVideo.publishYear
        )}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          mb: 3,
          fontSize: '0.75rem',
        }}
      >
        {formatedVideo.abstract}
      </Typography>
      <Box
        className="rb_video_content"
        sx={{
          position: 'relative',
          pt: ' 56.25%' /* Player ratio: 100 / (1280 / 720) */,
        }}
      >
        <VideoPlayer videoId={formatedVideo.videoId} onEnded={handleVideoEnd} />
      </Box>
    </Box>
  )
}

export default VideoWrapper
