import { NetworkStatus, useQuery } from '@apollo/client'
import {
  Alert,
  Box,
  Pagination,
  PaginationItem,
  Typography,
} from '@mui/material'
// import SearchBox from 'components/atoms/SearchBox'
import ContentTypeList from 'components/molecules/ContentTypeList'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import {
  GET_VIDEO_LIST,
  SEARCH_VIDEO_LIST,
} from 'services/contentful/ContentLists'
import { alertAtom } from 'stores'
import { GenericContentItemType } from 'types/ContentListTypes'

interface Props {
  title: string
}

const VideoListWrapper = ({ title }: Props): JSX.Element => {
  // track page from URL
  const [searchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '1', 10)
  const [searchString] = useState<string>('')
  // scroll top
  const pageHeadingRef = useRef<null | HTMLElement>(null)
  const executeScroll = (): void => {
    if (pageHeadingRef && pageHeadingRef.current)
      pageHeadingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }
  const [currentPage, setCurrentPage] = useState(page)
  const [totalPage, setTotalPage] = useState(1)
  const [pageSize] = useState(5)
  const [videoList, setVideoList] = useState<GenericContentItemType[]>([])
  const [contentLoading, setContentLoading] = useState(false)
  const [, setAlert] = useAtom(alertAtom)
  const {
    data,
    loading,
    error,
    networkStatus,
    fetchMore, //refetch
  } = searchString
    ? useQuery(SEARCH_VIDEO_LIST, {
        variables: {
          limit: pageSize,
          skip: (page - 1) * pageSize,
          searchString: searchString,
        },
        notifyOnNetworkStatusChange: true,
      })
    : useQuery(GET_VIDEO_LIST, {
        variables: { limit: pageSize, skip: (page - 1) * pageSize },
        notifyOnNetworkStatusChange: true,
      })

  // const onSearchInput = (searchString: string): any => {
  //   if (searchParams.get('page')) {
  //     searchParams.delete('page')
  //     page = parseInt('1', 10)
  //     setCurrentPage(page)
  //     setSearchParams(searchParams)
  //   }
  //   setSearchString(searchString)
  //   refetch({
  //     limit: pageSize,
  //     skip: (page - 1) * pageSize,
  //     searchString: searchString,
  //   })
  // }

  useEffect(() => {
    if (!loading && data?.videoCollection?.items?.length) {
      setVideoList(data.videoCollection.items)
      const totalPages = data?.videoCollection?.total
        ? Math.ceil(data?.videoCollection?.total / pageSize)
        : 0
      setTotalPage(totalPages)
    }
  }, [loading, data])

  const loadingMoreContent = networkStatus === NetworkStatus.fetchMore

  if (loading && !loadingMoreContent) {
    return <Loading />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  const handleChange = (event: ChangeEvent<unknown>, value: number): void => {
    setContentLoading(true)
    const skip = (value - 1) * pageSize
    fetchMore({
      variables: {
        skip: skip,
      },
    })
      .then(response => {
        if (
          !response.loading &&
          response?.data?.videoCollection?.items.length
        ) {
          setCurrentPage(value)
          setVideoList(response.data.videoCollection.items)
        }
        executeScroll()
        setContentLoading(false)
      })
      .catch(() => {
        setContentLoading(false)
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to get content. Try again.',
          autoHideDuration: 2000,
        })
      })
  }

  return (
    <Box className="rb_video_list_wrapper">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="h4"
          align="left"
          fontWeight="600"
          paragraph
          textTransform="uppercase"
          sx={{ mb: 2 }}
          ref={pageHeadingRef}
        >
          {title}
        </Typography>
        {/* <SearchBox
          placeHolder="Search Videos..."
          onChange={onSearchInput}
          defaultValue={searchString}
        /> */}
      </Box>
      <ContentTypeList data={videoList} type="Video" />
      <Box
        component="div"
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Pagination
          color="primary"
          page={currentPage}
          count={totalPage}
          onChange={handleChange}
          disabled={contentLoading}
          renderItem={(item): JSX.Element => (
            <PaginationItem
              component={Link}
              to={`/dashboard/video${
                item.page === 1 ? '' : `?page=${item.page}`
              }`}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  )
}

export default VideoListWrapper
