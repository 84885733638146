import { AxiosResponse } from 'axios'
import AppService from 'services/app'
import { BulkFileListType, Reward, User } from 'types/admin'

// Bulk file

export const getBulkFileList = async (): Promise<
  AxiosResponse<BulkFileListType[], any>
> => {
  try {
    return await AppService.Admin.getData('bulkFileList', null, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const downloadBulkFiles = async (
  storageKey: string | null = null
): Promise<AxiosResponse<Blob, any>> => {
  if (storageKey) {
    try {
      return await AppService.Admin.getData(
        'downloadBulkFileByKey',
        { storageKey },
        true
      )
    } catch (error) {
      return Promise.reject(error)
    }
  } else {
    try {
      return await AppService.Admin.getData('downloadBulkFiles', null, true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Users
export const getUsers = async (
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined
): Promise<AxiosResponse<User[], any>> => {
  try {
    return await AppService.Admin.getData(
      'allUsers',
      { firstName, lastName, email },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updatePoints = async (
  userId: string,
  points: number,
  reason: string
): Promise<
  AxiosResponse<
    {
      totalPoints: number
    },
    any
  >
> => {
  try {
    return await AppService.Admin.saveData('points', {
      userId,
      points,
      reason,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

//Rewards
export const getAllRewards = async (
  postalcode: number | null = null
): Promise<AxiosResponse<Reward[], any>> => {
  try {
    return await AppService.Admin.getData('allRewards', { postalcode }, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateReward = async (
  reward: Reward
): Promise<AxiosResponse<Reward, any>> => {
  try {
    return await AppService.Admin.saveData('updateReward', reward)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addSingleCouponCode = async (
  rewardId: string,
  couponCode: string
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Admin.saveData('singleCouponCode', {
      rewardId,
      couponCode,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addMultipleCouponCodes = async (
  rewardId: string,
  formData: FormData
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Admin.saveData('multipleCouponCodes', {
      rewardId,
      formData,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
