import MenuIcon from '@mui/icons-material/Menu'
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Icon from '@mui/material/Icon'
import { styled } from '@mui/material/styles'
import logo from 'assets/img/recyclebank_logo_green.png'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const TopNavLinks = styled(NavLink)(({ theme }) => ({
  padding: '0 .75rem',
  color: '#0C1C2A',
  display: 'block',
  fontWeight: 600,
  position: 'relative',
  fontSize: '0.875rem',
  lineHeight: 1.43,
  textDecoration: 'none',
  outline: 'none',
  '&::after': {
    position: 'absolute',
    content: '""',
    display: 'block',
    width: 0,
    height: '0.313rem',
    background: theme.palette.primary.main,
    left: '50%',
    bottom: '-30%',
    transition: 'all ease-in-out .2s',
  },
  '&:hover,&.active,&:focus': {
    color: theme.palette.primary.main,
    '&::after': {
      width: '100%',
      left: 0,
    },
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 1.875rem',
    '&::after': {
      bottom: '-108%',
    },
  },
}))

interface Link {
  name: string
  path: string
  icon?: string
}

interface Headerprops {
  name: string
  pagesMenu: Array<Link>
  settingsMenu: Array<Link>
  notificationCount?: number
  loading?: boolean
  drawerOpen?: boolean
  elevation?: number
  onMenuClickCB: (item: Link) => void
  onDrawerOpen: () => void
}

const stringToColor = (string: string): string => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const stringAvatar = (name: string): object => {
  if (name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  } else {
    return {}
  }
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const Header = ({
  name,
  pagesMenu = [],
  settingsMenu = [],
  loading = false,
  drawerOpen = false,
  elevation = 1,
  onMenuClickCB,
  onDrawerOpen,
}: Headerprops): JSX.Element => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }
  const handleOnSettingsMenuClick = (): void => {
    setAnchorElUser(null)
  }

  const handleSettingsMenuItemClick = (
    e: React.MouseEvent<HTMLElement>,
    menuItem: Link
  ): void => {
    e.preventDefault()
    if (menuItem) onMenuClickCB(menuItem)
    setAnchorElUser(null)
  }

  const Logo = styled('img')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
      height: '2.1875rem',
      width: '12rem',
      objectFit: 'contain',
      objectPosition: 'left top',
    },
    [theme.breakpoints.up('lg')]: {
      height: '2.625rem',
      width: '14rem',
      objectFit: 'contain',
      objectPosition: 'left top',
    },
  }))

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: 'white' }}
      elevation={elevation}
      open={drawerOpen}
      className="rb-authenticated-header"
    >
      {loading ? <LinearProgress color="warning" sx={{ height: 2 }} /> : ''}
      <Toolbar>
        <Typography
          noWrap
          component="div"
          sx={{
            mr: 2,
            pt: 1,
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
          }}
        >
          <NavLink to="/dashboard" style={{ outline: 'none' }}>
            <Logo src={logo} alt={'RecycleBank'} />
          </NavLink>
        </Typography>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
          }}
        >
          <NavLink to="/dashboard" style={{ outline: 'none' }}>
            <Logo src={logo} alt={'RecycleBank'} />
          </NavLink>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="small">
              <Avatar
                {...stringAvatar(name)}
                sx={{
                  width: 30,
                  height: 30,
                  fontSize: '0.8rem',
                  textTransform: 'uppercase',
                  border: '2px solid #008F6B',
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} />
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="end"
          onClick={onDrawerOpen}
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          className="rb-menu-link"
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {pagesMenu.map(page => (
            <TopNavLinks key={page.name} to={page.path} className="topNavLink">
              {page.name}
            </TopNavLinks>
          ))}
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="small">
              <Avatar
                {...stringAvatar(name)}
                sx={{
                  width: { md: 30, lg: 35 },
                  height: { md: 30, lg: 35 },
                  fontSize: { md: '0.8rem', lg: '0.95rem' },
                  textTransform: 'uppercase',
                  border: '2px solid #008F6B',
                }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '1.875rem' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleOnSettingsMenuClick}
          >
            {settingsMenu.map(item => (
              <MenuItem
                key={item.name}
                onClick={(e): void => handleSettingsMenuItemClick(e, item)}
              >
                <ListItemIcon>
                  <Icon fontSize="small">{item.icon}</Icon>
                </ListItemIcon>
                <Typography textAlign="center">{item.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
