import { AxiosResponse } from 'axios'
import axios from 'axios'
import { AdminClientPointsInputs } from 'types/ClientTypes'

import { axiosInstance } from '.'
import AppService from './appService'

const noAuthAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

const values = {}

const getters = {
  serviceAreaByPostalCode(
    postalCode: string
  ): Promise<AxiosResponse<any, any>> {
    return noAuthAxiosInstance.get(`/service-areas/postalcode/${postalCode}`)
  },
  clients(): Promise<AxiosResponse<any, any>> {
    return axiosInstance.get('clients')
  },
}

const saves = {
  addPointsToUsers(
    params: AdminClientPointsInputs
  ): Promise<AxiosResponse<any, any>> {
    return axiosInstance.post(
      `/clients/${params.clientId}/point-history/points/${params.points}`
    )
  },
}

const deletes = {}

export default class ClientService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
