import { AxiosResponse } from 'axios'
import { atom } from 'jotai'
import AppService from 'services/app'
import { AdminClientPointsInputs, ClientResponse } from 'types/ClientTypes'

export const clientsListAtom = atom<ClientResponse[]>([])

// API
export const getClients = async (): Promise<
  AxiosResponse<ClientResponse[], any>
> => {
  try {
    return await AppService.Client.getData('clients', null, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addPointsToAllUsersOfClient = async (
  data: AdminClientPointsInputs
): Promise<AxiosResponse<string, any>> => {
  try {
    return await AppService.Client.saveData('addPointsToUsers', data)
  } catch (error) {
    return Promise.reject(error)
  }
}
