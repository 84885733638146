import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import logoGreen from 'assets/img/recyclebank_logo_green.png'

const Img = styled('img')({
  maxHeight: '100%',
  objectFit: 'contain',
  width: '9.375rem',
  position: 'relative',
  top: '.6rem',
})

const AdminFooter = ({ drawerOpen }: { drawerOpen: boolean }): JSX.Element => {
  const theme = useTheme()
  return (
    <Grid
      sx={{
        backgroundColor: '#0c1c2a',
        color: '#bdc2ca',
        alignItems: 'center',
        p: '0.5rem',
        marginLeft: '4rem',
        width: `calc(100% - 4rem)`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawerOpen && {
          width: `calc(100% - 15rem)`,
          marginLeft: '15rem',
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
      container
      component="footer"
    >
      <Grid item xs={12} md={2} sx={{ px: '2rem' }}>
        <Img src={logoGreen} alt="Recycle Bank" />
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography variant="xsmall" align="center" paragraph sx={{ mb: 0 }}>
          © 2004-{new Date().getFullYear()} RTS Recyclebank, LLC, d/b/a
          Recyclebank, is a subsidiary of RTS Holding, Inc. Recyclebank is not a
          financial institution and is not engaged in the banking business.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AdminFooter
