import { Container, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import logo from 'assets/img/recyclebank_logo_white.png'
import { useAtom } from 'jotai'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { isLoggedInAtom } from 'stores/auth'

const FooterList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyle: 'none',
  fontSize: '0.75rem',
  lineHeight: '1.8',
  '& li a': {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover,&:focus': { color: theme.palette.primary.main, outline: 'none' },
  },
}))

const Img = styled('img')({
  maxHeight: '100%',
  objectFit: 'contain',
  width: '9.375rem',
})

const Footer = (): JSX.Element => {
  const [isLoggedIn] = useAtom(isLoggedInAtom)
  const location = useLocation()

  const isHome = location && location.pathname === '/' ? true : false

  return (
    <Grid
      sx={{
        backgroundColor: '#005E85',
        color: '#F5F7FA',
      }}
      component="footer"
    >
      {isHome ? (
        <Container
          component="div"
          maxWidth="lg"
          sx={{
            background: '#fff',
            height: '50px',
            borderRadius: '0 0 5px 0.5rem',
          }}
        ></Container>
      ) : null}
      <Grid item xs={12} sx={{ p: 4 }}>
        <Container component="div" maxWidth="lg">
          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12} md={2}>
              <Img src={logo} alt="Recycle Bank" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="xsmall" paragraph>
                RTS Recyclebank partners with cities and brands to reward
                residents for helping to make their communities cleaner, greener
                places.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              display="flex"
              justifyContent={{ md: 'end' }}
            >
              {isLoggedIn ? (
                <FooterList className="list TabsListUnstyled">
                  <li>
                    <NavLink to="/dashboard/learnEarn">
                      Learn &amp; Earn
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/earnPoints">How this Works</NavLink>
                  </li>
                  {/* <li>
                  <NavLink to="/dashboard/categories">Categories</NavLink>
                </li> */}
                  <li>
                    <NavLink to="/dashboard/rewards">Get Rewards</NavLink>
                  </li>
                </FooterList>
              ) : null}
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              display="flex"
              justifyContent={{ md: 'end' }}
            >
              <FooterList>
                {isLoggedIn ? (
                  <li>
                    <NavLink to="/dashboard/settings">My Account</NavLink>
                  </li>
                ) : null}
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.rts.com/privacy/"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <NavLink to="/terms">Terms &amp; Conditions</NavLink>
                </li>
                {isLoggedIn ? (
                  <li>
                    <NavLink to="/dashboard/contact">Contact Us</NavLink>
                  </li>
                ) : null}
              </FooterList>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: '#0C1C2A', py: 3, px: 2 }}>
        <Container component="div" maxWidth="lg">
          <Typography variant="xsmall" align="center" paragraph sx={{ mb: 0 }}>
            © 2004-{new Date().getFullYear()} RTS Recyclebank, LLC, d/b/a
            Recyclebank, is a subsidiary of RTS Holding, Inc. Recyclebank is not
            a financial institution and is not engaged in the banking business.
          </Typography>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Footer
