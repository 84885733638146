import { Container, Typography } from '@mui/material'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import AdminClientPoints from 'components/molecules/AdminClientPoints'
import AdminPageWrapper from 'components/templates/AdminPageWrapper'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { alertAtom } from 'stores'
import {
  addPointsToAllUsersOfClient,
  clientsListAtom,
  getClients,
} from 'stores/clients'
import { AdminClientPointsInputs } from 'types/ClientTypes'

const Points = (): JSX.Element => {
  const [clientsList, setClientsList] = useAtom(clientsListAtom)
  const [, setAlert] = useAtom(alertAtom)

  // Load clients from sever
  useEffect(() => {
    getClients()
      .then(clients => {
        setClientsList(clients.data)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to fetch clients. Try again.',
          autoHideDuration: 5000,
        })
      })
  }, [])

  const handleSubmit = async (data: AdminClientPointsInputs): Promise<void> => {
    if (data && data.clientId && data.points > 0) {
      addPointsToAllUsersOfClient(data)
        .then(response => {
          if (response.data) {
            setAlert({
              show: true,
              message: response.data,
              type: 'success',
              autoHideDuration: 5000,
            })
          } else {
            setAlert({
              show: true,
              message: `Error occurred adding points. Empty response.`,
              type: 'error',
              autoHideDuration: 5000,
            })
          }
        })
        .catch(result => {
          setAlert({
            show: true,
            message: `Error occurred adding points: ${result.response.status} - ${result.response.data}`,
            type: 'error',
            autoHideDuration: 5000,
          })
        })
    }
  }

  return (
    <AdminPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          py: 3,
        }}
      >
        <Typography
          paragraph
          sx={{ fontWeight: '600', mb: 2, fontSize: '1rem' }}
        >
          Add points by client
        </Typography>
        <Typography variant="body2" paragraph>
          Select a client below and specify a number of points to give all users
          belonging to that client the specified number of points.
        </Typography>
        <AdminClientPoints clientsList={clientsList} onUpdate={handleSubmit} />
      </Container>
    </AdminPageWrapper>
  )
}

export default Points
