import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import {
  CSSObject,
  SxProps,
  Theme,
  styled,
  useTheme,
} from '@mui/material/styles'
import NotificationBar from 'components/atoms/NotificationBar'
import AdminFooter from 'components/molecules/AdminFooter'
import Header from 'components/molecules/HeaderAdmin'
import { useAtom } from 'jotai'
import { ReactNode, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { AlertProp } from 'stores'
import { alertAtom } from 'stores'
import { logout } from 'stores/auth'
import { userAtom } from 'stores/user'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const pagesMenu = [
  { name: 'Users', icon: 'people', path: '/admin' },
  { name: 'Points', icon: 'stars', path: '/admin/points' },
  { name: 'Rewards', icon: 'local_activity', path: '/admin/rewards' },
  {
    name: 'Bulk Download',
    icon: 'download_for_offline_sharp',
    path: '/admin/bulkfiles',
  },
]

const settingsMenu = [{ name: 'Logout', path: '', icon: 'logout' }]

interface Link {
  name: string
  path: string
  icon?: string
}

const AdminPageWrapper = ({
  children,
  sx,
}: {
  alert?: AlertProp | undefined
  children: ReactNode
  sx?: SxProps<Theme>
}): JSX.Element => {
  const [user] = useAtom(userAtom)
  const navigate = useNavigate()
  const name = user ? `${user.firstName} ${user.lastName}` : ''

  const [alert, setAlert] = useAtom(alertAtom)

  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleNotificationClose = (): void => {
    setAlert(null)
  }

  const handleMenuClick = (menuItem: Link): void => {
    if (menuItem.name === 'Logout') {
      logout()
      navigate('/')
    } else if (menuItem.name === 'Profile' && menuItem.path) {
      navigate(menuItem.path)
    }
  }

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  return (
    <Box
      component="main"
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      className="rb-admin-page-wrapper"
    >
      <Header
        name={name}
        settingsMenu={settingsMenu}
        onMenuClickCB={handleMenuClick}
        onDrawerOpen={handleDrawerOpen}
        drawerOpen={open}
      />
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {},
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pagesMenu.map(page => (
            <ListItem key={page.name} disablePadding sx={{ display: 'block' }}>
              <NavLink
                to={page.path}
                end
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {({ isActive }): JSX.Element => (
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    selected={isActive ? true : false}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip
                        title={!open ? page.name : ''}
                        arrow
                        placement="right"
                      >
                        <Icon color={isActive ? 'primary' : 'inherit'}>
                          {page.icon}
                        </Icon>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary={page.name}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        fontSize: '0.9rem',
                        color: isActive ? 'primary' : 'inherit',
                      }}
                    />
                  </ListItemButton>
                )}
              </NavLink>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box
        component="section"
        sx={[
          {
            flexGrow: 1,
            marginLeft: '4rem',
            width: `calc(100% - 4rem)`,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
              width: `calc(100% - 15rem)`,
              marginLeft: '15rem',
              transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <DrawerHeader />
        {alert !== null ? (
          <NotificationBar
            open={alert.show}
            message={alert.message}
            type={alert.type}
            autoHideDuration={alert.autoHideDuration || null}
            sx={{ mb: '35px' }}
            onCloseCB={handleNotificationClose}
          />
        ) : null}
        {children}
      </Box>
      <AdminFooter drawerOpen={open} />
    </Box>
  )
}
export default AdminPageWrapper
