import { gql } from '@apollo/client'

import { BASE_CONTENT_FIELDS, FLIPCARD_FIELDS } from './Fragments'

export const GET_ARTICLE_BY_ID = gql`
  ${BASE_CONTENT_FIELDS}
  query GetArticleById($id: String!) {
    article(id: $id) {
      sys {
        id
        publishedAt
      }
      linkedFrom {
        baseContentCollection {
          items {
            ...BaseContent
          }
        }
      }
      content {
        json
      }
    }
  }
`
export const GET_FLIPCARD_BY_ID = gql`
  ${BASE_CONTENT_FIELDS}
  ${FLIPCARD_FIELDS}
  query GetFlipCardById($id: String!) {
    flipcard(id: $id) {
      ...Flipcard
      linkedFrom {
        baseContentCollection {
          items {
            ...BaseContent
          }
        }
      }
    }
  }
`

export const GET_VIDEO_BY_ID = gql`
  ${BASE_CONTENT_FIELDS}
  query GetVideoById($id: String!) {
    video(id: $id) {
      sys {
        id
        publishedAt
      }
      linkedFrom {
        baseContentCollection {
          items {
            ...BaseContent
          }
        }
      }
      youtubeVideoId
    }
  }
`
