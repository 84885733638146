import SearchIcon from '@mui/icons-material/Search'
import { Box, FormHelperText, InputBase } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import debounce from 'lodash/debounce'
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'

interface Props {
  placeHolder?: string
  defaultValue?: any
  mode?: 'dark' | 'light'
  width?: number
  onChange: (data: string) => void
}

const Search = styled('div')<{
  mode: 'dark' | 'light'
}>(({ theme, mode }) => ({
  position: 'relative',
  borderRadius: '0.2rem',
  backgroundColor:
    mode === 'dark'
      ? alpha(theme.palette.common.black, 0.05)
      : alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor:
      mode === 'dark'
        ? alpha(theme.palette.common.black, 0.09)
        : alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    height: '2.3rem',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)<{ width: number }>(
  ({ theme, width }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(0.5, 0.5, 0.5, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2.5)})`,
      transition: theme.transitions.create('width'),
      fontSize: '0.9rem',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: `${width}ch`,
        '&:focus': {
          width: `${width + 3}ch`,
        },
      },
    },
  })
)

const SearchBox = ({
  placeHolder = 'Search...',
  defaultValue = undefined,
  mode = 'dark',
  width = 17,
  onChange,
}: Props): JSX.Element => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm<{
    search: string
  }>()

  const handleOnChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> => {
    await trigger()
    onChange(e.target.value)
  }

  return (
    <Box>
      <Search mode={mode}>
        <SearchIconWrapper>
          <SearchIcon fontSize="small" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={placeHolder}
          inputProps={{ 'aria-label': placeHolder, name: 'search' }}
          width={width}
          size="small"
          {...register('search', {
            required: true,
            minLength: {
              value: 3,
              message: 'Minimum 3 characters are required',
            },
          })}
          defaultValue={defaultValue}
          onChange={debounce(handleOnChange, 800)}
        />
      </Search>
      {errors?.search && errors?.search?.message ? (
        <FormHelperText
          id="search-helper-text"
          error={errors?.search ? true : false}
          sx={{ ml: 1, mt: 0, mb: 1 }}
        >
          {errors.search.message ? String(errors.search.message) : ''}
        </FormHelperText>
      ) : (
        ''
      )}
    </Box>
  )
}

export default SearchBox
