import { Container, Grid } from '@mui/material'
import dashboardBanner from 'assets/img/dashboard_content_banner.jpg'
import learnEarnBanner from 'assets/img/live_green_banner.jpg'
import ScoreBadge from 'components/molecules/ScoreBadge'
import ArticlesListWrapper from 'components/organisms/ArticlesListWrapper'
import BaseContentListWrapper from 'components/organisms/BaseContentListWrapper'
import FlipcardListWrapper from 'components/organisms/FlipcardListWrapper'
import LatestContentWrapper from 'components/organisms/LatestContentWrapper'
import PopularReadsWrapper from 'components/organisms/PopularReadsWrapper'
import VideoListWrapper from 'components/organisms/VideoListWrapper'
import AuthenticatedPageWrapper from 'components/templates/AuthenticatedPageWrapper'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { pointsAtom } from 'stores/user'

interface ListTypeProp {
  type?: 'baseContent' | 'article' | 'flipcard' | 'video'
}

const listType = ({ type }: ListTypeProp): JSX.Element | string => {
  switch (type) {
    case 'article':
      return <ArticlesListWrapper title="Articles" />
    case 'flipcard':
      return <FlipcardListWrapper title="Flipcards" />
    case 'video':
      return <VideoListWrapper title="Videos" />
    default:
      return <BaseContentListWrapper title="LEARN & EARN" />
  }
}

const ContentList = ({ type = 'baseContent' }: ListTypeProp): JSX.Element => {
  const navigate = useNavigate()
  const topBanner = type === 'baseContent' ? learnEarnBanner : dashboardBanner
  const [pointBalance] = useAtom(pointsAtom)
  const handlePopularCardClick = (to: string): void => {
    navigate('/dashboard/' + to)
  }

  return (
    <AuthenticatedPageWrapper>
      <Container
        maxWidth={false}
        component="div"
        sx={{
          backgroundImage: 'url(' + topBanner + ')',
          height: '13.75rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'end' },
          alignItems: 'baseline',
          py: 3,
        }}
      >
        <Container maxWidth="lg" component="div" sx={{ px: { xs: 0 } }}>
          <Grid
            container
            direction="row"
            justifyContent={{ xs: 'start', md: 'end' }}
            alignItems={{ xs: 'end', md: 'center' }}
            color="white"
          >
            <Grid
              item
              sx={{
                position: 'relative',
                top: { xs: '7.75rem', md: '5rem' },
                zIndex: 100,
              }}
            >
              <ScoreBadge points={pointBalance} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        component="div"
        maxWidth="lg"
        sx={{ mt: { xs: 10, md: 6 }, mb: 4 }}
      >
        {type === 'baseContent' ? <LatestContentWrapper /> : null}
        <Grid container direction="row" sx={{ mt: 8 }}>
          <Grid item sx={{ pr: { md: 2 } }} xs={12} md={8}>
            {listType({ type })}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <PopularReadsWrapper
              limit={4}
              onCardClick={handlePopularCardClick}
            />
            {/* <SwitchCard
              featuredImage={switchCardImg}
              imgText="DAILY PLEDGE"
              text="Instead of using individual flowers as a symbol of your love, gift potted flowers, which will last much longer and won't have to be trashed. "
              switchText="EARN 5 POINTS"
            /> */}
          </Grid>
        </Grid>
      </Container>
    </AuthenticatedPageWrapper>
  )
}

export default ContentList
