import { AxiosResponse } from 'axios'
import { atom } from 'jotai'
import {
  atomWithDefault,
  atomWithStorage,
  createJSONStorage,
} from 'jotai/utils'
import AppService from 'services/app'
import { AddUser, UpdateUser, User } from 'types/AuthTypes'

export const isUserLoadingAtom = atom<boolean>(false)

const storage = createJSONStorage(() => sessionStorage)
export const userAtom = atomWithStorage<User | any>('user', null, storage)
export const pointsAtom = atomWithDefault<number>(get => {
  const user = get(userAtom)
  return user ? user.pointBalance : 0
})

// User
export const trackLogin = async (): Promise<AxiosResponse<User, any>> => {
  return await AppService.User.saveData('trackLogin', null)
}

export const getUser = async (
  authId: string
): Promise<AxiosResponse<User, any>> => {
  return await AppService.User.getData('user', authId, true)
}

export const addUser = async (
  authId: string,
  user: AddUser
): Promise<AxiosResponse<User, any>> => {
  try {
    return await AppService.User.saveData('user', { authId, user })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateUser = async (
  authId: string,
  user: UpdateUser
): Promise<AxiosResponse<User, any>> => {
  try {
    return await AppService.User.saveData('updateUser', { authId, user })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const purchaseReward = async (
  userId: string,
  rewardId: string
): Promise<
  AxiosResponse<
    {
      totalPoints: number
    },
    any
  >
> => {
  try {
    return await AppService.User.saveData('purchaseReward', {
      userId,
      rewardId,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

// Content
export const validContentView = async (
  userId: string,
  baseContentId: string
): Promise<
  AxiosResponse<
    {
      valid: boolean
    },
    any
  >
> => {
  return await AppService.User.getData(
    'validateContent',
    {
      baseContentId,
      userId,
    },
    true
  )
}

// Points
export const setPoints = async (
  userId: string,
  baseContentId: string,
  contentType: string
): Promise<
  AxiosResponse<
    {
      totalPoints: number
    },
    any
  >
> => {
  try {
    return await AppService.User.saveData('point', {
      userId,
      baseContentId,
      contentType,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
